import React, { useState, useEffect } from "react";
import TeamColumns from "./TeamColumns";
import userService from "../Services/user.service";
import { Button, Modal } from "react-bootstrap";
import dashboardService from "../Services/dashboard.service";
import { USER_DETAIL } from "../../Constants/constant" 
import coeService from "../Services/coe.service";
import { apiCall } from "../Services/Interceptor";
import toaster from "../Toast/toaster";
import NodataFound from "../Commons/NoDataFound";

const Team = ()=>{
  let userRole =JSON.parse(localStorage.getItem(USER_DETAIL)).userRole
  const [data, setData] = useState("")
  const [roles, setRoles] = useState([])
  const [coes, setCoes] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selectedData, setSelectedData] = useState({})
  const [isExportToExcel, setIsExportToExcel] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)

  const teamData = (e)=>{
    setData(e)
    console.log(e)
  }

  const queryString = window.location.search;
  const storedData = JSON.parse(localStorage.getItem("AGENT"))
  const urlParams = new URLSearchParams(queryString);
  let callRange = urlParams.get('callScore')
  let durationRange = urlParams.get('callDuration')

  useEffect(() => {
    document.title = "Team  - Odio"
      userService.getRoleOptions().then(res=>{
        if(res){
          console.log(res)
          setRoles(res.data.data)
        }
      })

      if(userRole=='AGENT'){
        dashboardService.getUserCOEList().then(res => {
          console.log('getuserCoeList--', res);
          setCoes(res?res.data.data?res.data.data:'':'')
      })
      }else{
      let id = JSON.parse(localStorage.getItem('MOMENT_ID'))
      dashboardService.getMomentCOEList(id).then(res=>{
        if(res){
          setCoes(res.data.data)
        }
      })
    }
  }, [])

  const teamExcelData = (e)=>{
    console.log(e)
    setIsDisabled(e&&e.length>0?false:true)
    setIsExportToExcel(e)
  }

  const excelData = {
    "callType": JSON.parse(localStorage.getItem("USER_DETAIL"))?.accountType,
    "coeExternalIds": storedData?.coe,
    "filters": {
      "fromD": storedData?.fromD,
      "toD": storedData?.toD,
      "coeExternalIds": storedData?.coe,
      "coeName": storedData?.selectedCOE,
      "employee":storedData?.empName?.value,
      "employeeId":storedData?.empId,
      "callsFrom":storedData?.callsFrom,
      "callsTo":storedData?.callsTo,
      "aht":storedData?.aht,
      "callScoreRange":storedData?.callScore,
      "pitchRange":storedData?.pitchScore,
    },
    "fromD": storedData?.fromD,
    "reportType": "Agent Detail Report",
    "toD": storedData?.toD,
  }

  const exportExcelHandler = async()=>{
    if(isExportToExcel&&isExportToExcel.length>0){
      await apiCall.post('/odio/api/user/conversationCallDetailReport', excelData).then(res=>{
        if(res.data.status==0){
          console.log(res)
          // setLoading(false)
          setShowModal(true)
        }
        else{
          console.log(res)
          toaster.error(res.data.message)
        }
      })
    }
    else{
      <NodataFound/>
    }
  }

    return (
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
            <div className="col-12 mb-2">
              <div className="row">
                <div className="col-md-12">
                  {/* <h2 class="dashboard-headings">Sales Team</h2> */}
                  <div className="d-inline">
                    <h2 className="dashboard-headings d-inline">Team </h2>
                    {data?data.employeeId!=''?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`EMPLOYEE ID: ${data?.employeeId}`}<a href="javascript:;"></a></span>:"":""}
                    {data&&data.teamUser?data.teamUser!=''?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`EMPLOYEE: ${data?.teamUser?.label}`}<a href="javascript:;"></a></span>:"":""}
                    {data?data.role!=''?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`ROLE: ${data?.role}`}<a href="javascript:;"></a></span>:"":""}
                    {data?data.designation!=''?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`DESIGNATION: ${data?.designation}`}<a href="javascript:;"></a></span>:"":""}
                    {data?data.caller!=''?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`EMPLOYEE TYPE: ${data?.caller}`}<a href="javascript:;"></a></span>:"":""}
                    {data?data.callsFrom&&data.callsTo!=''?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`NUMBER OF CALLS: (${data?.callsFrom}-${data?.callsTo})`}<a href="javascript:;"></a></span>:"":""}
                    {storedData?typeof(storedData.selectedCOE)!='string'?storedData?.selectedCOE?.map(item=>{return (<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`COE: ${item}`}<a href="javascript:;"></a></span>)}):<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`COE: ${storedData?.selectedCOE}`}<a href="javascript:;"></a></span>:""}
                    {storedData?storedData.fromD&&storedData.toD?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`DATE: FROM(${storedData?.fromD}) TO(${storedData?.toD})`}<a href="javascript:;"></a></span>:"":""}
                    {data?data.aht!=''&&(data.aht[0]!=0&&data.aht[1]!=300)||(data.aht[0]==0&&data.aht[1]!=300)||(data.aht[0]!=0&&data.aht[1]==120)?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`AHT: (${data.aht[0]}-${data.aht[1]})`}<a href="javascript:;"></a></span>:"":""}
                    {data?data.call!=''&&(data.call[0]!=0&&data.call[1]!=100)||(data.call[0]==0&&data.call[1]!=100)||(data.call[0]!=0&&data.call[1]==100)?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`CALL SCORE: (${data.call[0]}-${data.call[1]})`}<a href="javascript:;"></a></span>:"":""}
                    {data?data.pitch!=''&&(data.pitch[0]!=0&&data.pitch[1]!=100)||(data.pitch[0]==0&&data.pitch[1]!=100)||(data.pitch[0]!=0&&data.pitch[1]==100)?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`PITCH SCORE: (${data.pitch[0]}-${data.pitch[1]})`}<a href="javascript:;"></a></span>:"":""}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-4">
              <div className="card">
                <div className="card-body team">
                  <button disabled={isDisabled} className="exportExcel" style={{marginLeft:'52em', position:'absolute',backgroundColor:isDisabled?"gray":"#0077b6"}} onClick={exportExcelHandler}><i class='bx bx-download'></i></button>
                  <TeamColumns teamData={teamData} roles={roles} coes={coes} selectedData={(data)=>setSelectedData(data)} teamExcelData={teamExcelData}/>
                </div>
              </div>
            </div>
          </div>
          {/*end row*/}
          {<Modal style={{height:"225px",marginTop:"36px"}} show={showModal} onHide={()=>setShowModal(false)}>
          <Modal.Header closeButton style={{borderBottom:"none"}}/>
          <Modal.Body>
            <h5 style={{textAlign:"center"}}>Request Sucessfully Submitted!</h5>
            <p style={{textAlign:"center"}}>Report will be generated in 5-10 mins. You can view in REPORTS Section.</p>
          </Modal.Body>
          </Modal>}
        </div>
      </div>
    );
  }
export default Team;
