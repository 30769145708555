import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import odio_logo_blue from "../../assets/images/odio_logo_blue.png";
import logoIcon from "../../assets/images/logoIcon.png";
import "./commons.css";
import "./sideBar.css";
import { USER_PERMISSIONS, USER_DETAIL } from "../../Constants/constant";
import { NavLink,useLocation } from "react-router-dom";

function SideBar({ hideToShow, sideBarHovered, sideBarUnhovered }) {
  //const [show, setShow] = useState(false);
  let details = localStorage.getItem(USER_DETAIL);
  const user = JSON.parse(localStorage.getItem("USER_DETAIL"))
  const path = useLocation();
  const [activeKey, setActiveKey] = useState(path.pathname == "/voice" || path.pathname == "/chat" ? 'Conversations' : 'Dashboard')
  const [tabList, setTabList] = useState({
    Dashboard: [
      {
        name: "Sales",
        icon: "lni lni-money-protection",
        code: "SALES_DASHBOARD",
        type: "SALES",
        api: "/sales_dashboard",
        groupName: "Dashboard",
        groupIcone: "bx bx-grid-alt",
        mainSequence: 1,
        groupSequence: 1,
      },
    ],
    Conversations: [
      {
        api: "/sales_conversations",
        code: "SALES_DASHBOARD",
        groupIcone: "bx bx-user-voice",
        groupName: "Conversations",
        groupSequence: 1,
        icon: "lni lni-money-protection",
        mainSequence: 2,
        name: "Sales",
        type: "SALES",
      },
      {
        api: "/support_conversations",
        code: "SUPPORT_DASHBOARD",
        groupIcone: "bx bx-user-voice",
        groupName: "Conversations",
        groupSequence: 2,
        icon: "lni lni-laptop-phone",
        mainSequence: 2,
        name: "Support",
        type: "SUPPORT",
      },
      {
        api: "/sales_dashboard",
        code: "VOICE",
        groupIcone: "bx bx-user-voice",
        groupName: "Conversations",
        groupSequence: 3,
        icon: "lni lni-mic",
        mainSequence: 2,
        name: "Voice",
        type: "BOTH",
      },
      {
        api: "/sales_dashboard",
        code: "CHAT",
        groupIcone: "bx bx-user-voice",
        groupName: "Conversations",
        groupSequence: 4,
        icon: "lni lni-wechat",
        mainSequence: 2,
        name: "Chat",
        type: "BOTH",
      },
      {
        api: "/sales_dashboard",
        code: "EMAIL",
        groupIcone: "bx bx-user-voice",
        groupName: "Conversations",
        groupSequence: 5,
        icon: "lni lni-envelope",
        mainSequence: 2,
        name: "Email",
        type: "BOTH",
      },
    ],
    Insights: [
      {
        api: "/sales_dashboard",
        code: "ACTIVITY_LOG",
        groupIcone: "lni lni-bulb",
        groupName: "Insights",
        groupSequence: 1,
        icon: "lni lni-clipboard",
        mainSequence: 3,
        name: "Activity Log",
        type: "ACTIVITY_LOG",
      },
      {
        api: "/sales_dashboard",
        code: "PRODUCTIVITY",
        groupIcone: "lni lni-bulb",
        groupName: "Insights",
        groupSequence: 2,
        icon: "lni lni-graph",
        mainSequence: 3,
        name: "Productivity",
        type: "PRODUCTIVITY",
      },
      // {
      //   api: "/sales_dashboard",
      //   code: "FOLLOW-UPS",
      //   groupIcone: "lni lni-bulb",
      //   groupName: "Insights",
      //   groupSequence: 3,
      //   icon: "lni lni-spinner-arrow",
      //   mainSequence: 3,
      //   name: "Follow-ups",
      //   type: "FOLLOW_UPS",
      // },
      // {
      //   api: "sales_dashboard",
      //   code: "OBJECTIONS",
      //   groupIcone: "lni lni-bulb",
      //   groupName: "Insights",
      //   groupSequence: 4,
      //   icon: "lni lni-unlink",
      //   mainSequence: 3,
      //   name: "Objections",
      //   type: "OBJECTIONS",
      // },
      {
        api: "/sales_dashboard",
        code: "COMPARATIVE-ANALYSIS",
        groupIcone: "lni lni-bulb",
        groupName: "Insights",
        groupSequence: 5,
        icon: "bx bx-git-compare",
        mainSequence: 3,
        name: "Comparative analysis",
        type: "COMPARATIVE-ANALYSIS",
      },
      {
        api: "/duration_metrics",
        code: "DURATION-METRICS",
        groupIcone: "lni lni-bulb",
        groupName: "Insights",
        groupSequence: 6,
        icon: "bx bx-time",
        mainSequence: 3,
        name: "Duration metrics",
        type: "DURATION-METRICS",
      },
      {
        api: "/conversation_metrics",
        code: "CONVERSATION_METRICS",
        groupIcone: "lni lni-bulb",
        groupName: "Insights",
        groupSequence: 7,
        icon: "bx bx-conversation",
        mainSequence: 3,
        name: "Conversation metrics",
        type: "CONVERSATION_METRICS",
      },
    ],
    Teams: [
      {
        api: "/team",
        groupIcone: "lni lni-users",
      },
    ],
    Notifications: [
      {
        api: "/notifications",
        groupIcone: "bx bx-bell",
      },
    ],
    Reports: [
      {
        api: "/reports",
        groupIcone: "lni lni-database",
      },
    ],
    KnowledgeAI: [],
    LiveAssist:[],
    // Support: [
    //   {
    //     api: "/faq",
    //     code: "FAQ",
    //     groupIcone: "bx bx-support",
    //     groupName: "Support",
    //     groupSequence: 1,
    //     icon: "bx bx-help-circle",
    //     mainSequence: 7,
    //     name: "FAQ",
    //     type: "FAQ",
    //   },
    //   {
    //     api: "/sales_dashboard",
    //     code: "USER_MANUAL",
    //     groupIcone: "bx bx-support",
    //     groupName: "Insights",
    //     groupSequence: 2,
    //     icon: "bx bx-file",
    //     mainSequence: 7,
    //     name: "User Manual",
    //     type: "USER_MANUAL",
    //   },
    //   {
    //     api: "/contact_support",
    //     code: "CONTACT_SUPPORT",
    //     groupIcone: "bx bx-support",
    //     groupName: "Support",
    //     groupSequence: 3,
    //     icon: "contact-support-link",
    //     mainSequence: 7,
    //     name: "Contact Support",
    //     type: "CONTACT_SUPPORT",
    //   },
    // ],
  });
  
  useEffect(() => {
    var p = localStorage.getItem(USER_PERMISSIONS);
    let data = JSON.parse(p);
    //console.log(data);

    let dashboard = data.filter((a) => a["groupName"] === "Dashboard");
    let conversations = data.filter((a) => a["groupName"] === "Conversations");
    let insights = data.filter((a) => a["groupName"] === "Insights");
    let team = data.filter((a) => a["groupName"] === "Team");
    //let notification = data.filter( a => a['groupName'] === 'Notification');
    let reports = data.filter((a) => a["groupName"] === "Reports");
    // let support = data.filter( a => a['groupName'] === 'Support');
    let knowledge = data.filter((a) => a["groupName"] === "KnowledgeAI");
    let liveassist = data.filter((a) => a["groupName"] === "LiveAssist");
    setTabList((tabList) => ({
      ...tabList,
      Dashboard: dashboard,
      Conversations: conversations,
      Insights: insights,
      Teams: team,
      Reports: reports,
      KnowledgeAI: knowledge,
      LiveAssist: liveassist,
    }));
  }, []);

  //console.log(tabList);
  //const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  /* const handleTabChange = (e)=>{
    console.log(e);
    document.getElementsByClassName("")
  } */


  const displayTabs = (val,tab) => {
    val.sort((a, b) => a.groupSequence - b.groupSequence);
    return val.map((v) => (
      <div key={v.name}>
        <NavLink to={v.api} key={v.name} activeClassName="mm-active">
          <i className={v.icon}></i>
          {v.name}
        </NavLink>
      </div>
    ));
  };

  var i = 0;
  var p;

  const [show, setShow] = useState(true)
  const clickHandler = ()=>{
    setShow(!show)
    hideToShow()
  }
  
  const checkRoute = (location,tab) => {
    switch (tab){
      case "Conversations":
        return (location.pathname.startsWith(tabList[tab][0]["api"]) || location.pathname.includes('sales_call_detail'))
      
      case "Teams":
        return (location.pathname.startsWith(tabList[tab][0]["api"]) || location.pathname.includes('team_member_detail'))

      default:
        return location.pathname === tabList[tab][0]["api"]
    }
  }

  console.log(tabList)
  return (
    <>
    <div
      className="sidebar-wrapper"
      data-simplebar="true"
      onMouseEnter={() => sideBarHovered()}
      onMouseLeave={() => sideBarUnhovered()}
    >
      <div className="sidebar-header">
        <div>
          <img src={logoIcon} className="logo-icon" alt="logo icon" />
        </div>
        <div>
          <h4 className="logo-text">
            <img src={odio_logo_blue} className="logo-img" alt="logo icon" />
          </h4>
        </div>
        <div className="toggle-icon ms-auto">
          <i className={`bx bx-lock-${show?'open-':''}alt`} onClick={clickHandler}></i>
        </div>
      </div>

      <ul className="metismenu side_nav_scroll" id="menu">
        <Accordion defaultActiveKey={activeKey}>
          {Object.keys(tabList).map((tab) =>
            tabList[tab].length > 1 ? (
              <Accordion.Item eventKey={tab} className="sidebar-custom-collapse">
                <Accordion.Header className="parent-icon" onClick={()=>setActiveKey(tab)}>
                  <i className={tabList[tab][0]["groupIcone"]}></i>
                  <div className="menu-title">{tab}</div>
                </Accordion.Header>
                <Accordion.Body>{displayTabs(tabList[tab],tab)}</Accordion.Body>
              </Accordion.Item>
            ) : tabList[tab].length != 0 ? (
              <Accordion.Item>
                <Accordion.Header className="parent-icon hide_icon">
                  <NavLink
                    to={tabList[tab][0]["api"]}
                    className={`d-flex align-center px-0 ${tab}`}
                    // activeClassName="mm-active"
                    isActive={(match,location)=> checkRoute(location,tab)}
                  >
                    {" "}
                    <i className={`${tabList[tab][0]["groupIcone"]}`}></i>
                    <span className="menu-title">{tab}</span>
                  </NavLink>
                </Accordion.Header>
              </Accordion.Item>
            ) : (
              ""
            )
          )}
        </Accordion>
      </ul>
    </div>
    </>
  );
}

export default SideBar;
