import * as React from 'react';
import { Form } from "react-bootstrap" 
import { useState, useEffect } from "react"
import coeService from "../Services/coe.service"
import dashboardService from "../Services/dashboard.service"
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const ReportFilter = (props)=>{

    let role = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole
    let localData = JSON.parse(localStorage.getItem("AGENT"))
    let dateFormat = JSON.parse(localStorage.getItem("TIME_FORMAT"))
    const [coeList, setCoeList] = useState([])
    const [dateList, setDateList] = useState([])
    const [selectedCOE, setSelectedCOE] = useState(localData&&localData.selectedCoeRange?localData.selectedCoeRange.length==1? localData.selectedCoeRange[0]:"Select All":"")
    const [filteredData, setFilteredData] = useState([])
    const[customDate, setCustomDate] = useState(localData&&localData.selectedRange==9?true:localData&&localData.selectedRange==''?true:false)
    const[dateRange, setDateRange] = useState(localData&&localData.selectedRange?localData.selectedRange:localData&&localData.selectedRange==''?9:5)
    const[startDate, setStartDate] = useState(localData?.fromD?new Date(`${localData.fromD.split("-")[1]}-${localData.fromD.split("-")[0]}-${localData.fromD.split("-")[2]}`):new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    const[endDate, setEndDate] = useState(localData?.toD?new Date(`${localData?.toD.split("-")[1]}-${localData?.toD.split("-")[0]}-${localData?.toD.split("-")[2]}`):new Date())
    const [opendp, setOpendp] = useState(false);
    const [opendp1, setOpendp1] = useState(false);
    const [selectedReportType, setReportType] = useState(localData?.reportType?localData?.reportType:"COE Detail Report")
    const [selectedFrequency, setSelectedFrequency] = useState(localData?.frequency?localData?.frequency:"MONTHLY")

    let reportTypes = [
        {label: "COE Detail Report", value:"COE Detail Report", id:1},
        {label: "Call Detail Report", value:"Call Detail Report", id:2},
        {label: "Agent Detail Report", value:"Agent Detail Report", id:3},
    ]

    let frequency = [
        {label: "Daily", value:"DAILY", id:1},
        {label: "Weekly", value:"WEEKLY", id:2},
        {label: "Monthly", value:"MONTHLY", id:3},
        {label: "Custom", value:"CUSTOM", id:4},
    ]


    const getCoes = () => {
        coeService.getCOEList().then(res =>{
            setCoeList(res.data.data)
            let userCoe = res?res.data.data?res.data.data.map((item)=>{
                return item.coeName
              }):'':''
            let coeExternalId = res?res.data.data?res.data.data.map((item)=>{
            return item.externalId
            }):'':''
            let coeId = res?res.data.data?res.data.data.map(item=>{
            return item.coeId
            }):"":""
            dashboardService.getDateAcronym().then(res => {
                let date = res?res.data?res.data.data.filter((e)=>{
                    return e.type =="CURRENT" || e.type =="GLOBAL"
                }):'':''
                console.log(date)
                setDateList(date);
                let dd = date[4]?.fromDate.split("-")[2]
                let mm = date[4]?.fromDate.split("-")[1]
                let yyyy = date[4]?.fromDate.split("-")[0]
                let dateF = `${dd}-${mm}-${yyyy}`
                let dd_1 = date[4]?.toDate.split("-")[2]
                let mm_1= date[4]?.toDate.split("-")[1]
                let yyyy_1= date?.[4]?.toDate.split("-")[0]
                let dateT = `${dd_1}-${mm_1}-${yyyy_1}`
                setFilteredData(filteredData=>({...filteredData,
                    fromDate:localData?.fromD?localData?.fromD:dateT,
                    toDate:localData?.toD?localData?.toD:dateF,
                    selectedCOE:localData?.selectedCOE?localData?.selectedCOE:userCoe,
                    externalId:localData?.coe?localData?.coe:coeExternalId,
                    selectedCOERange:localData?.selectedCoeRange?localData?.selectedCoeRange:coeId,
                    frequencySelected:localData?.frequency?localData?.frequency:"MONTHLY",
                    reportType:localData?.reportType?localData?.reportType:"COE Detail Report",
                    selectedDate:localData?.selectedDate?localData.selectedDate:date[4].name,
                    selectedRange:localData?.selectedRange?localData.selectedRange:5,
                    reset:props?.reset
                }))
            })


            // setFilteredData(filteredData=>({...filteredData,selectedCOE:localData?.selectedCOE?localData?.selectedCOE:userCoe,externalId:localData?.coe?localData?.coe:coeExternalId,selectedCOERange:localData?.selectedCOERange?localData?.selectedCOERange:coeId,frequencySelected:localData?.frequency?localData?.frequency:"MONTHLY",reportType:localData?.reportType?localData?.reportType:"COE Detail Report"}))
            // localStorage.setItem("AGENT",JSON.stringify(
            //     {
            //       "pageSelected":localData?.pageSelected,
            //       "analysis":localData?.analysis,
            //       "productivity":localData?.productivity,
            //       "fromD":localData?.fromDate,
            //       "toD":localData?.toDate,
            //       "agentDetailCOE":localData?.agentDetailCOE,
            //       "coe":localData?.coe?localData?.coe:coeExternalId,
            //       "selectedCOE":localData?.selectedCOE?localData?.selectedCOE:userCoe,
            //       "selectedCoeRange":localData?.selectedCoeRange?localData?.selectedCoeRange:coeId,
            //       "userIds":localData?.userIds,
            //       "userNames":localData?.userNames,
            //       "empId":localData?.employeeId,
            //       "empName":localData?.user,
            //       "role":localData?.role,
            //       "designation":localData?.designation,
            //       "type":localData?.type,
            //       "teamCOE":localData?.teamCOE,
            //       "callsFrom":localData?.callsFrom,
            //       "callsTo":localData?.callsTo,
            //       "aht":localData?.aht,
            //       "callScore":localData?.callScore,
            //       "pitchScore":localData?.pitchScore,
            //       "customerName":localData?.customerName,
            //       "customerMobile":localData?.customerMobile,
            //       "fileName":localData?.fileName,
            //       "sellerROS":localData?.sellerROS,
            //       "sellerName":localData?.sellerName,
            //       "selectedSeller":localData?.selectedSeller,
            //       "cScore":localData?.cScore,
            //       "callHealth":localData?.callHealth,
            //       "callDuration":localData?.callDuration,
            //       "words":localData?.words,
            //       "moment":localData?.moment,
            //       "moment":localData?.momentId,
            //       "pitch":localData?.pitch,
            //       "prob":localData?.prob,
            //       "opportunity":localData?.opp,
            //       "urgency":localData?.urgency,
            //       "selectedDate":localData?.selectedDate,
            //       "selectedRange":localData?.selectedRange,
            //       "momentBucket":localData?.momentBucket,
            //       "momentBucketId":localData?.momentBucketId,
            //       "frequency":localData?.frequency?localData?.frequency:"MONTHLY",
            //       "reportType":localData?.reportType?localData?.reportType:"COE Detail Report"
            //     }
            // ))
        })
    }

    const getCoesAdmin = () => {
        dashboardService.getUserCOEList().then(res => {
            setCoeList(res.data.data)
            let userCoe = res?res.data.data?res.data.data.map((item)=>{
                return item.coeName
              }):'':''
            let coeExternalId = res?res.data.data?res.data.data.map((item)=>{
            return item.externalId
            }):'':''
            let coeId = res?res.data.data?res.data.data.map(item=>{
            return item.coeId
            }):"":""
            // setFilteredData(filteredData=>({...filteredData,selectedCOE:userCoe,externalId:coeExternalId,selectedCOERange:coeId,frequency:localData?.frequency?localData?.frequency:selectedFrequency,reportType:localData?.reportType?localData?.reportType:selectedReportType}))
            dashboardService.getDateAcronym().then(res => {
                let date = res?res.data?res.data.data.filter((e)=>{
                    return e.type =="CURRENT" || e.type =="GLOBAL"
                }):'':''
                console.log(date)
                setDateList(date);
                let dd = date[4]?.fromDate.split("-")[2]
                let mm = date[4]?.fromDate.split("-")[1]
                let yyyy = date[4]?.fromDate.split("-")[0]
                let dateF = `${dd}-${mm}-${yyyy}`
                let dd_1 = date[4]?.toDate.split("-")[2]
                let mm_1= date[4]?.toDate.split("-")[1]
                let yyyy_1= date?.[4]?.toDate.split("-")[0]
                let dateT = `${dd_1}-${mm_1}-${yyyy_1}`
                setFilteredData(filteredData=>({...filteredData,
                    fromDate:localData?.fromD?localData?.fromD:dateT,
                    toDate:localData?.toD?localData?.toD:dateF,
                    selectedCOE:localData?.selectedCOE?localData?.selectedCOE:userCoe,
                    externalId:localData?.coe?localData?.coe:coeExternalId,
                    selectedCOERange:localData?.selectedCoeRange?localData?.selectedCoeRange:coeId,
                    frequencySelected:localData?.frequency?localData?.frequency:"MONTHLY",
                    reportType:localData?.reportType?localData?.reportType:"COE Detail Report",
                    selectedDate:localData?.selectedDate?localData.selectedDate:date[4].name,
                    selectedRange:localData?.selectedRange?localData.selectedRange:5,
                    reset:props?.reset
                }))
            })
        })
    }

    const getDateList = () => {
        dashboardService.getDateAcronym().then(res => {
            let date = res?res.data?res.data.data.filter((e)=>{
                return e.type =="CURRENT" || e.type =="GLOBAL"
            }):'':''
            console.log(date)
            setDateList(date);
            let dd = dateList[4]?.fromDate.split("-")[2]
            let mm = dateList[4]?.fromDate.split("-")[1]
            let yyyy = dateList[4]?.fromDate.split("-")[0]
            let dateF = `${dd}-${mm}-${yyyy}`
            let dd_1 = dateList[4]?.toDate.split("-")[2]
            let mm_1= dateList[4]?.toDate.split("-")[1]
            let yyyy_1= dateList?.[4]?.toDate.split("-")[0]
            let dateT = `${dd_1}-${mm_1}-${yyyy_1}`
            setFilteredData(filteredData=>({...filteredData,fromD:dateT,toD:dateF}))
        })
    }

    const handleSelectCoe = (e) =>{
        setSelectedCOE(e.target.value)
        let coes = e.target.value=='Select All'?coeList:coeList?.filter(function (el) {
          return el.coeId==e.target.value
        })
        console.log(coes) 
        setFilteredData({...filteredData,externalId: e.target.value=='Select All'?coes.map(item=>{return item.externalId}):coes[0].externalId,selectedCOE:e.target.value=='Select All'?coes.map(item=>{return item.coeName}):[coes[0].coeName],selectedCOERange:e.target.value=='Select All'?coes.map(item=>{return item.coeId}):[e.target.value]})
    }

    const handleSelectReportType = (e) =>{
        setReportType(e.target.value)
        setFilteredData({...filteredData,reportType:e.target.value})
    }

    const handleFrequency = (e) => {
        setSelectedFrequency(e.target.value)
        setFilteredData({...filteredData,frequencySelected:e.target.value})
    }

    const handleSelectDate =(e)=> {
        console.log(e.target.value)
        console.log(dateList)
        setDateRange(e.target.value)
        if (e.target.value == 9) {
          setCustomDate(true); 
          let startDateObj = new Date(startDate); // Create a Date object from the startDate string
          let endDateObj = new Date(endDate); // Create a Date object from the endDate string

          let dd = String(startDateObj.getDate()).padStart(2, '0'); // Get the day for startDate
          let mm = String(startDateObj.getMonth() + 1).padStart(2, '0'); // Get the month for startDate (January is 0)
          let yyyy = startDateObj.getFullYear(); // Get the year for startDate

          let dd_1 = String(endDateObj.getDate()).padStart(2, '0'); // Get the day for endDate
          let mm_1 = String(endDateObj.getMonth() + 1).padStart(2, '0'); // Get the month for endDate (January is 0)
          let yyyy_1 = endDateObj.getFullYear(); // Get the year for endDate
  
          let dateF = `${dd<10&&!dd.includes('0')?'0'+dd:dd}-${mm<10&&!mm.includes('0')?'0'+mm:mm}-${yyyy}`
          let dateT = `${dd_1<10&&!dd_1.includes('0')?'0'+dd_1:dd_1}-${mm_1<10&&!mm_1.includes('0')?'0'+mm_1:mm_1}-${yyyy_1}`
          setFilteredData({...filteredData,fromDate: dateF,toDate:dateT,selectedDate:"Custom Date",selectedRange:9})
        }
        else{
          setCustomDate(false);
          let dates = dateList.filter(function (el) {
            return el.id == e.target.value;
          })
          let dd = dates[0].fromDate.split("-")[2]
          let mm = dates[0].fromDate.split("-")[1]
          let yyyy = dates[0].fromDate.split("-")[0]
  
          let dd_1 = dates[0].toDate.split("-")[2]
          let mm_1= dates[0].toDate.split("-")[1]
          let yyyy_1= dates[0].toDate.split("-")[0]
  
          let dateF = `${dd<10&&!dd.includes('0')?'0'+dd:dd}-${mm<10&&!mm.includes('0')?'0'+mm:mm}-${yyyy}`
          let dateT = `${dd_1<10&&!dd_1.includes('0')?'0'+dd_1:dd_1}-${mm_1<10&&!mm_1.includes('0')?'0'+mm_1:mm_1}-${yyyy_1}`
          setFilteredData({...filteredData,fromDate: dateT,toDate:dateF,selectedDate:dateList[e.target.value-1].name,selectedRange:e.target.value })
        }
    }

    const handleStartDate = (e)=>{
        console.log(e)
        setStartDate(e)
        let date = `${((new Date(e)).getDate())<10?'0'+(new Date(e)).getDate():(new Date(e)).getDate()}-${((new Date(e)).getMonth()+1)<10?'0'+((new Date(e)).getMonth()+1):((new Date(e)).getMonth()+1)}-${(new Date(e)).getFullYear()}`;
        console.log(date)
        setFilteredData(filteredData=>({...filteredData,fromDate: date}))
    }
  
    const handleEndDate = (e)=>{
        console.log(e)
        setEndDate(e)
        let date = `${((new Date(e)).getDate())<10?'0'+(new Date(e)).getDate():(new Date(e)).getDate()}-${((new Date(e)).getMonth()+1)<10?'0'+((new Date(e)).getMonth()+1):((new Date(e)).getMonth()+1)}-${(new Date(e)).getFullYear()}`;
        console.log(date)
        setFilteredData(filteredData=>({...filteredData,toDate: date}))
    }

    useEffect(()=>{
        // getDateList()
        if(role!='AGENT')
            getCoes()
        else
            getCoesAdmin()
    },[])

    useEffect(()=>{
        props.reportFilter(filteredData)
    },[filteredData])

    useEffect(()=>{
        if(props?.reset){
            let dd = dateList[4]?.fromDate.split("-")[2]
            let mm = dateList[4]?.fromDate.split("-")[1]
            let yyyy = dateList[4]?.fromDate.split("-")[0]
            let dateF = `${dd}-${mm}-${yyyy}`
            let dd_1 = dateList[4]?.toDate.split("-")[2]
            let mm_1= dateList[4]?.toDate.split("-")[1]
            let yyyy_1= dateList?.[4]?.toDate.split("-")[0]
            let dateT = `${dd_1}-${mm_1}-${yyyy_1}`
            let userCoe = coeList?.map((item)=>{
                return item.coeName
              })
            let coeExternalId = coeList?.map((item)=>{
            return item.externalId
            })
            let coeId = coeList?.map(item=>{
            return item.coeId
            })
            setSelectedCOE("Select All")
            setSelectedFrequency("MONTHLY")
            setReportType("COE Detail Report")
            setCustomDate(false)
            let firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
            setStartDate(firstDay)
            setEndDate(new Date())
            setDateRange(5)
            setFilteredData(filteredData=>({...filteredData,
                fromDate:dateT,
                toDate:dateF,
                selectedCOE:userCoe,
                externalId:coeExternalId,
                selectedCOERange:coeId,
                frequencySelected:"MONTHLY",
                reportType:"COE Detail Report",
                selectedDate:"This Month",
                selectedRange:5,
                reset:props?.reset
            }))
            // getCoes()
        }
        props.resetFormState()
    },[props?.reset])

    console.log(dateList)

    return(
        <div className="dashboard-filter-group">
            <div className="lh-1 mb-3">
                <p className="mb-0 call-fillter-heading">COE</p>
                <hr className="filter-Hr"></hr>
                <Form.Select as="select" custom
                    style={{textTransform:"capitalize"}}
                    name="coe"
                    aria-label="Default select example"
                    onChange={handleSelectCoe}
                    className="form-control-sm py-1"
                    placeholder="Select COE"
                    value={selectedCOE}
                >
                    <option value={'Select All'}>Select All</option>
                    {coeList?coeList.map((coe) => {
                        return <option key={coe.coeId} value={coe.coeId} >{coe.coeName}</option>;
                    }):''}
                </Form.Select>
            </div>
            <div className="lh-1 mb-3">
                <p className="mb-0 call-fillter-heading">Report Type</p>
                <hr className="filter-Hr"></hr>
                <Form.Select as="select" custom
                    style={{textTransform:"capitalize"}}
                    name="reportType" 
                    aria-label="Default select example"
                    onChange={handleSelectReportType}
                    className="form-control-sm py-1"
                    placeholder="Select Report Type"
                    value={selectedReportType}
                >
                    {reportTypes.map((d) => {
                        return <option key={d.id} value={d.value} >{d.label}</option>;
                    })}
                </Form.Select>
            </div>
            <div className="lh-1 mb-3">
                <p className="mb-0 call-fillter-heading">Frequency</p>
                <hr className="filter-Hr"></hr>
                <Form.Select as="select" custom
                    style={{textTransform:"capitalize"}}
                    name="frequency" 
                    aria-label="Default select example"
                    onChange={handleFrequency}
                    className="form-control-sm py-1"
                    placeholder="Select Report Type"
                    value={selectedFrequency}
                >
                    {frequency.map((d) => {
                        return <option key={d.id} value={d.value} >{d.label}</option>;
                    })}
                </Form.Select>
            </div>
            <div className="lh-1 mb-3">
                <div className="filter-Date-div">
                    <div className="filter-Date-selected">
                        <p className="mb-0 call-fillter-heading">Select Date</p>
                        <hr className="filter-Hr"></hr>
                        <Form.Select
                            as="select"
                            custom
                            name="selectDate"
                            aria-label="Default select example"
                            className="form-control-sm py-1"
                            // defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleSelectDate}
                            >
                            {dateList? dateList.map((d) => { return(<option value={d.id} key={d.id}> {d.name} </option>)  }):''}   
                        </Form.Select>
                    </div>
                    {customDate?
                    <div className="call-fillter-date dasbard-comp-setting mt-2 mx-1 align-items-center">
                        <p className="mb-0 call-fillter-heading">From:</p>
                        <hr className="filter-Hr"></hr>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                            openTo="day"
                            views={['year', 'month', 'day']}
                            inputFormat={dateFormat}
                            value={startDate}
                            name="startDate"
                            onChange={handleStartDate}
                            disableOpenPicker
                            open={opendp}
                            onOpen={() => setOpendp(true)}
                            onClose={() => setOpendp(false)}
                            renderInput={(params) => (
                                <TextField {...params} onClick={()=> setOpendp(true)} />
                            )}
                            />
                        </LocalizationProvider>
                
                        <p className="mb-0 call-fillter-heading">To:</p>
                        <hr className="filter-Hr"></hr>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                            openTo="day"
                            views={['year', 'month', 'day']}
                            inputFormat={dateFormat}
                            value={endDate}
                            name="endDate"
                            onChange={handleEndDate}
                            disableOpenPicker
                            open={opendp1}
                            onOpen={() => setOpendp1(true)}
                            onClose={() => setOpendp1(false)}
                            renderInput={(params) => (
                                <TextField {...params} onClick={()=> setOpendp1(true)} />
                            )}
                            />
                        </LocalizationProvider>
                    </div>:''}
                </div>
            </div>
        </div>
    )
}

export default ReportFilter