import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import EmailQuestions from "./Modals/EmailTranscript";
import { Button } from "react-bootstrap";
import Loading from "../../Commons/Loading";
import * as React from 'react'
import EmailTranscript from "./Modals/EmailTranscript";
import CommentOnModal from "./Modals/CommentOnModal";
import callsService from "../../Services/calls.service";
import notificationsService from "../../Services/notifications.service";
import toaster from "../../Toast/toaster";
import { replace } from "connected-react-router";
import { Select, MenuItem, FormControl } from "@mui/material";
import { apiCall } from "../../Services/Interceptor";
import Accordion from "react-bootstrap/Accordion";
import play from "../../../assets/images/play-icon.png"
import './/moments.css'
import { USER_DETAIL } from "../../../Constants/constant";
import { useDispatch } from "react-redux";
import { setCallScore } from "../../../redux-container/callList/actions";

export default function Moments(props) {
  const [show, setShow] = useState(false)
  const [likeButton, setLikeButton] = useState(false)
  const [dislikeButton, setDislikeButton] = useState(false)
  const [commentButton, setCommentButton] = useState(false)
  const [mailButton, setMailButton] = useState(false)
  const [emailShow, setEmailShow] = useState(false)
  const [commentShow, setCommentShow] = useState(false)
  const [momentsId, setMomentsId] = useState("")
  const [moment, setMoment] = useState("")
  const [input, setInput] = useState("")
  const [likeId, setLikeId] = useState("")
  const [dislikeId, setDislikeId] = useState("")
  const [commentData, setCommentData] = useState([])
  const [likeDislike, setLikeDislike] = useState([])
  const history = useHistory()
  const dispatch = useDispatch() 
  console.log("MOMENTHIS", history)
  console.log(props.typeOfCall, "type")
  const permissions = JSON.parse(localStorage.getItem(USER_DETAIL ))?.viewPermissions
  const manualAuditPermission = permissions.some(item => item.viewName === "ManualAudit")

  const closeButton = () => {
    setShow(false)
    setCommentShow(false)
  }
  const handleClick = () => {
    setShow(true)
    setMailButton(true)
    setEmailShow(true)
  }
  const setEmailFalse = () => {
    setShow(false)
  }

  const likeButtonHandler = (id, label) => {
    setLikeButton(true)
    setLikeId(id)
    let value = {
      "callId": props?.callId,
      "commentType": "MOMENTS",
      "dislike": null,
      "like": 1,
      "postId": id
    }
    callsService.addLikeDislike(value).then(res => {
      if (res) {
        callsService.getLikeDislike(props?.callId, "MOMENTS").then(res => {
          if (res) {
            setLikeDislike(res.data.data)
            setLikeButton(false)
          }
        })
      }
    })
    let notificationBody = [{
      "callId": props?.typeOfCall == "SINGLE_CALL" ? props?.callId : null,
      "userExternalId": props?.agentExternalId,
      "groupId": props?.typeOfCall == "GROUP" ? props?.callId : null,
      "notificationEvent": {
        "eventType": "LIKE",
        "tabName": "callMoments",
        "tabValue": label,
        "postId": id,
      }
    }]
    notificationsService.createNotifications(notificationBody).then(res => {
      if (res) {
        console.log(res)
        toaster.success(res.data.message)
      }
    })
  }
  const dislikeButtonHandler = (id, label) => {
    setDislikeButton(true)
    setDislikeId(id)
    let value = {
      "callId": props?.callId,
      "commentType": "MOMENTS",
      "dislike": 0,
      "like": null,
      "postId": id
    }
    callsService.addLikeDislike(value).then(res => {
      if (res) {
        callsService.getLikeDislike(props?.callId, "MOMENTS").then(res => {
          if (res) {
            setLikeDislike(res.data.data)
            setDislikeButton(false)
          }
        })
      }
    })
    let notificationBody = [{
      "callId": props?.typeOfCall == "SINGLE_CALL" ? props?.callId : null,
      "userExternalId": props?.agentExternalId,
      "groupId": props?.typeOfCall == "GROUP" ? props?.callId : null,
      "notificationEvent": {
        "eventType": "DISLIKE",
        "tabName": "callMoments",
        "tabValue": label,
        "postId": id,
      }
    }]
    notificationsService.createNotifications(notificationBody).then(res => {
      if (res) {
        console.log(res)
        toaster.success(res.data.message)
      }
    })
  }
  const commentButtonHandler = (id, label) => {
    // setCommentButton(true)
    setCommentShow(true)
    setMomentsId(id)
    setMoment(label)
  }

  const updateCommentState = () => {
    setCommentButton(true)
    setCommentShow(true)
    // setId(id)
    // setStats(label)
  }
  const mailHandler = () => {
    setMailButton(true)
    setEmailShow(true)
  }

  const changeHandler = (e) => {
    setInput(e.target.value.toLowerCase())
  }

  const callback = (e) => {
    setCommentData(e)
  }
  let chatVal = ['No Dead Air', 'Associate rate of speech was appropriate', 'Agent did not interrupt the customer']

  let filtered = Object.entries(Object.keys(commentData)?.length > 0 ? commentData : props.callMoments).map(ele => {
    let ele1 = Array.isArray(ele[1]) ? ele[1].filter(item => {
      return props.type == 'chat' ? !chatVal.includes(item.momentName) : true
    }) : ele[1]
    return props.type == 'chat' ? [ele[0].replace('call', 'chat').replace('CALL', 'CHAT').replace('Call', 'Chat'), ele1] : ele
  })

  const filteredData = filtered.filter(el => {
    console.log("fffffff", el)
    if (input === '') {
      return el
    }
    else {
      return el[0].toLowerCase().includes(input)
    }
  })
  console.log("AAAAAAAAAAAAAAAAAAAAA", filteredData)

  let bucketName = filteredData.length > 0 ? filteredData.map((group, id) => {
    console.log("asssssssaaa", filteredData, Object.keys(filteredData[0][1]), Object.values(filteredData[0][1]))
    return (
      // Object.keys(group[1]) != "postTypeComments" &&
      Object.values(filteredData[0][1]).map(item => {
        console.log(item)
        return item.momentbucketName
      }))
  }) : ""

  const iconsFunc = (group, id) => {
    let transNew = props?.callMoments?.postTypeComments || Object.keys(commentData).length > 0 ? Object.entries(Object.keys(commentData).length > 0 ? commentData?.postTypeComments : props?.callMoments?.postTypeComments).filter(val => {
      return (val[1][0].postId == id)
    }) : ""
    let ids = transNew.length > 0 ? transNew[0][1] : ''
    let likeIcon = likeDislike.length > 0 ? likeDislike.filter(item => {
      return (item.postId == id)
    }) :
      props?.likeDislikeData.filter(item => {
        return (item.postId == id)
      })
    console.log("_______>>>>>>", bucketName)
    return (
      <div className="d-flex table-group-header justify-content-between">
        <div className="table-group-heading">
          <p className="mb-0">{setBucketName(group[0])}</p>
          <div className="list-after-click-group">
            {likeIcon.length > 0 && likeIcon[0].like !== null && likeIcon[0].postId == id || likeButton && likeId == id ? <button className="btn list-like-after" ><span className=" text-primary-blue"> <i className="bx bx-like"></i></span></button> :
              likeIcon.length > 0 && likeIcon[0].dislike != null && likeIcon[0].postId == id || dislikeButton && dislikeId == id ? <button className="btn list-dislike-after" ><span className=" text-primary-blue"> <i className="bx bx-dislike"></i></span></button> : ''}
            {ids && ids[0].postId == id || commentButton && momentsId == id ? <button className="btn list-comment-after switcher-btn-1" onClick={() => commentButtonHandler(id, group[0])} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats"><span class="text-primary-blue"><i class="lni lni-comments-alt"></i></span></button> : ''}
          </div>
        </div>
        <div className="list-hover-input-group">
          {likeIcon.length > 0 && likeIcon[0].like !== null && likeIcon[0].postId == id || likeButton && likeId == id || dislikeButton && dislikeId == id ? '' : <button className="btn list-like-before" onClick={() => likeButtonHandler(id, group[0])}>
            <span className="text-primary-blue">
              <i className="bx bx-like"></i>
            </span>
          </button>
          }
          {likeIcon.length > 0 && likeIcon[0].dislike != null && likeIcon[0].postId == id || dislikeButton && dislikeId == id || likeButton && likeId == id ? ""
            :
            <button className="btn list-dislike-before" onClick={() => dislikeButtonHandler(id, group[0])}>
              <span className="text-primary-blue">
                <i className="bx bx-dislike"></i>
              </span>
            </button>
          }
          {/* {mailButton?'':
          <button className="btn compose-mail-btn" onClick={handleClick} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email Stats">
            <span className="text-primary-blue">
              <i className="bx bx-mail-send"></i>
            </span>
          </button>
          } */}
          {ids && ids[0].postId == id || commentButton && momentsId == id ? '' :
            <button className="btn list-comment-before switcher-btn-1" onClick={() => commentButtonHandler(id, group[0])} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Comment on Stats">
              <span className="text-primary-blue">
                <i className="lni lni-comments-alt"></i>
              </span>
            </button>
          }
        </div>
      </div>
    )
  }

  console.log("________>>>", props)
  useEffect(() => {
    if (props?.eventType == 'COMMENT' && props?.tabRedirected == 'callMoments') {
      setCommentShow(true)
      setMomentsId(props?.postId)
      setMoment(history.location.state.tabValue)
    }
  }, [])


  const setBucketName = (val) => {
    // console.log("camelcase",val)
    // let camelCaseStr = val[0].toUpperCase() + val.slice(1).toLowerCase();
    // return camelCaseStr;
    const words = val.split(' ');
    const camelCaseWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return camelCaseWords.join(' ');
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = urlParams.get("callId");
  const groupName = urlParams.get("name");
  const handleChange = (e, data, key) => {
    let val = e.target.value === "null" ? null : (e.target.value);
    let values = {
      callId: +params,
      clientExternalId: JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId,
      manualAudit: val,
      momentsId: data.momentId
    }

    const fetchData = async () => {
      try {
        const response = await apiCall.post("/odio/api/call/manual/audit", values);
        console.log(response);
        if (response) {
          console.log("Success");
          props?.handleMomentData(false);
          if(response.data.status!=1){
            toaster.success(response.data.message)
            callsService.getCallDetailStats(props?.callId, props?.typeOfCall).then((res) => {
              dispatch(setCallScore(res?.data?.data?.callScore?.value))
            });
          }
          else{
            toaster.error(response.data.message)
          }

        }
      } catch (error) {
        console.log("error===>", error);
      }
    };
    fetchData();
    // props.handleDemoPurpose(key, data.momentId, val);
  }

  const handleAudioPlay = (stamp) => {
    console.log(moment, "momentaudio")
    if (props.typeOfCall == "SINGLE_CALL") {
      const seconds = stamp
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;

      props.audioTime(formattedTime)
    }
  }

  const ObservedOrRating = (g) => {
    if (g.hasOwnProperty('observed')) {
      if (g.observed === 1) {
        return <i className="lni lni-checkmark"></i>;
      } else if (g.observed === 0) {
        return <i className="lni lni-close"></i>;
      } else if (g.observed === null) {
        return 'N/A';
      }
    } else if (g.hasOwnProperty('rating')) {
      if (g.rating === null) {
        return 'N/A';
      }
      return g.rating;
    }

    return null
  };


  return (
    <>
      {props.loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>) :
        <>
          <div className="tab-content py-3">
            <div className="tab-pane fade active show" role="tabpanel" id="primarymoments">
              <div className="d-flex">
                <div className="moment-filter-tab-box">
                  <div className="moments-heading">
                    <h5 className="m-0">Moments</h5>
                    <p className="font-12">{`${bucketName != '' && bucketName[0][0]} (Moment Bucket)`}</p>
                  </div>
                </div>
                <div className="moments-email-btn mb-2">
                  <a className="btn compose-mail-btn" onClick={handleClick}>Email Moments</a>
                </div>
              </div>
            </div>
          </div>
          <div className="input-group moment-search mb-3">
            <span className="input-group-text">
              <i className="bx bx-search"></i>
            </span>
            <input
              className="form-control"
              type="search"
              placeholder="Search By Moment"
              onChange={changeHandler}
            ></input>
          </div>
          <div>
            <div className="table-responsive moment-list-group-scroll moment-group-table">
              <table className="table mb-0">
                <thead className="fixed-header-table">
                  <tr>
                    <th>Name</th>
                    <th className="text-center">Observed</th>
                    {groupName === 'GROUP' || groupName === 'GROUP_CALL' ? '' : manualAuditPermission ?
                      <th className="text-center">Manual Audits</th> : ''}
                    <th className="text-center">Weightage</th>
                    <th className="text-center">Global Avg.</th>
                  </tr>
                </thead>
                <tbody className="fixed-header-table">
                  {filteredData ? filteredData.map((group, id) => (
                    <>
                      {group[0] == "postTypeComments" ?

                        ""
                        :
                        <tr className={`${id == props?.postId && props?.tabRedirected == 'callMoments' && props?.eventType == 'COMMENT' ? 'shadow-call-moments' : ''}`}>
                          <th
                            colspan={groupName === 'GROUP' || groupName === 'GROUP_CALL' ? "4" : "5"}
                            className="moment-table-hover-icon bg-light-blue"
                          >
                            {iconsFunc(group, id)}
                          </th>
                        </tr>
                      }
                      {group[0] != "postTypeComments" && group[1].map(g => (
                        //  g.observed != null &&
                        <tr>
                          <td>
                            <a
                              className={`font-weight-bold mb-0 ${g?.isActive === false ? 'inactive-moment' : ''}`}
                              data-bs-toggle="modal"
                              data-bs-target="#momentTabonName"
                              type="button"
                              style={{ color: g?.isActive === false ? '#757778' : '' }}
                            ><div style={{ display: 'flex', gap: '10px' }}>
                                {props.type == 'chat' ? g.momentName.replace('call', 'chat').replace('Call', 'Chat') : setBucketName(g.momentName)}
                                {props?.typeOfCall == "SINGLE_CALL" && g?.time?.length > 0 ? <Accordion flush>
                                  <Accordion.Item className="accod" eventKey="0">
                                    <Accordion.Header className="accod-header"></Accordion.Header>
                                    <Accordion.Body className="accod-body">
                                      {g?.time?.map((stamp) => <div className="time-stamps badge rounded-pill bg-light-blue text-dark f-normal" onClick={() => handleAudioPlay(stamp)}><span>{stamp}</span><img src={play} /></div>)}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion> : ''}</div>
                            </a>
                          </td>
                          {console.log(g,"ksdbcksnc")}
                          <td className="text-center" style={{ color: g?.isActive === false ? '#757778' : '' }}>
                            {ObservedOrRating(g)}
                          </td>
                          {groupName === 'GROUP' || groupName === 'GROUP_CALL' ? "" : manualAuditPermission ?

                            <td className="text-center">

                              <Select
                                sx={{
                                  minWidth: 80, bgcolor: g?.isActive === false ? '#f5f5f5' : 'white',
                                  ":hover": {
                                    bgcolor: 'none',

                                  }
                                }}
                                variant="filled" value={g.manualV + ''}
                                onChange={(e) => handleChange(e, g, group[0])}
                                disabled={g?.isActive === false ? true : false}
                              >
                                <MenuItem disabled hidden value={'-1'}>Edit</MenuItem>
                                <MenuItem value={'1'}><i className="lni lni-checkmark"></i></MenuItem>
                                <MenuItem value={'0'}><i className="lni lni-close"></i></MenuItem>
                                <MenuItem value={'null'}>N/A</MenuItem>
                              </Select>
                            </td> : '' }
                          <td className="text-center" style={{ color: g?.isActive === false ? '#757778' : '' }}>{g.weightage}</td>
                          <td className="text-center" >
                            <span className={g?.isActive === false ? "badge rounded-pill btn btn-outline-dark font-normal " : "badge rounded-pill btn btn-outline-danger font-normal"} style={{ backgroundColor: g?.isActive === false ? '#c0c0c0' : '' }}>
                              {g.globalAverage}%
                            </span>
                          </td>
                        </tr>
                      ))
                      }
                    </>
                  )) : ""}
                </tbody>
              </table>
            </div>
          </div>
          {commentShow && momentsId !== '' && <CommentOnModal updateState={updateCommentState} commentShow={commentShow} closeButton={closeButton} tabSelected={props?.tabRedirected == 'callMoments' ? props?.tabRedirected : props?.tabSelected} callId={props?.callId} momentId={momentsId} moment={moment} callback={callback} phone={props?.phone} agentExternalId={props.agentExternalId} typeOfCall={props?.typeOfCall} />}
          <EmailTranscript show={show} closeButton={closeButton} tabSelected={props?.tabSelected} callMoments={props.callMoments} callId={props?.callId} setEmailFalse={setEmailFalse} fileName={props?.fileName} typeOfCall={props?.typeOfCall} agentId={props?.agentId} />
        </>
      }</>
  );

}
