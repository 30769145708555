import ReactApexChart from "react-apexcharts"
import React,{useEffect, useState} from 'react'
import Loading from "../../Commons/Loading"
import Pagination from "../../Commons/Table/Pagination"
import '../Insight.css'
import NodataFound from "../../Commons/NoDataFound"
import { useHistory } from "react-router-dom";

export default function DurationMetricsGraph(props){
  let PageSize = 10;
  const pageCount = props.metricsData?(props.metricsData.length/PageSize):0
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPages, setCurrentPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [data, setData] = useState([])
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  let rdata = JSON.parse(localStorage.getItem("ROUTING_PERMISSIONS"))
  const history = useHistory()

  useEffect(()=>{
      setData(props.metricsData?props.metricsData.slice(0,10): [])
      setPageIndex(0)
      setCurrentPages(pageCount)
  },[props.metricsData])

  const sendTocalls = (data, name) => {
    let storedData = JSON.parse(localStorage.getItem("AGENT"))
    //let graphdata = categoriesVal?categoriesVal[value] :''
    let key = data == "Total Call" ? "0-6000": data == "(0-3) Min" ? "0-180" : data == "(3-6) Min" ? "181-360" : data == "(6-10) Min" ? "361-600" : "601-6000"
    let value = props?.metricsData.find((user)=>user.agentName == name)?.userId
    console.log(data,name,value,key,"sendtocall");
    localStorage.setItem("AGENT", JSON.stringify(
      {
        "pageSelected": 1,
        "fromD": storedData?.fromD,
        "toD": storedData?.toD,
        "selectedDate": storedData?.selectedDate,
        // "selectedRange": storedData?.selectedDate === "This Year" ? "7" : '5',
        "selectedRange": storedData?.selectedRange,
        "selectedCoeRange": storedData?.selectedCoeRange,
        "selectedCOE":storedData?.selectedCOE,
        "coe":storedData?.coe
      }))
    history.push(rdata && rdata.Conversations && Array.isArray(rdata.Conversations) && rdata.Conversations.length > 1 ? `/voice?callDuration=${key}&fromD=${storedData?.fromD}&toD=${storedData?.toD}` : `/sales_conversations?callDuration=${key}&fromD=${storedData?.fromD}&toD=${storedData?.toD}`,{"employeeId":value})
  }

  const nextPage = () =>{
       if(pageIndex < pageCount-1){
        let a = (pageIndex+1)*PageSize;
        setData(props.metricsData.slice(a,a+10))
        setCanPreviousPage(true)
       }
       else
         setCanNextPage(false);
  }

  const previousPage = ()=>{
    if(pageIndex > 0){
      let a = (pageIndex)*PageSize;
      setData(props.metricsData.slice(a-10,a))
     }
     else
       setCanNextPage(false);
  }

  const gotoPage = (val) =>{
    let a = val*PageSize;
    setPageIndex(val)
    setData(props.metricsData.slice(a,a+10))
  }

  const totalCall = data?.map(item=>{
    return item.totalCalls
  })
  const agentName = data?.map(item=>{
    return item.agentName
  })
  const callsInterval1 = data?.map(item=>{
    return item.calls3Min
  })
  const callsInterval2 = data?.map(item=>{
    return item.calls6Min
  })

  const callsInterval3 = data?.map(item=>{
    return item.calls10Min
  })

  const callsInterval4 = data?.map(item=>{
    return item.moreThan10
  })

  const series = [{
    name: 'Total Call',
    data: totalCall
  },
  {
    name: '(0-3) Min',
    data: callsInterval1
  },
  {
    name: '(3-6) Min',
    data: callsInterval2
  },
  {
    name: '(6-10) Min',
    data: callsInterval3
  },
  {
    name: '10+ Min',
    data: callsInterval4
  },
  ]

  const options = {
    chart: {
      height: 200,
      type: 'heatmap',
      toolbar: {
        show: false
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          console.log(chartContext, config);
          console.log(config.w.config.series[config.seriesIndex])
          console.log(config.w.config.series[config.seriesIndex].name)
          console.log(config.w.config.series[config.seriesIndex].data[config.dataPointIndex])
          console.log(config.dataPointIndex);
          console.log(config.w.config.series[config.seriesIndex].name,config.w.config.labels[config.dataPointIndex])
          sendTocalls(config.w.config.series[config.seriesIndex].name,config.w.config.labels[config.dataPointIndex])
        }
      }
    },
    plotOptions: {
        heatmap: {
          radius: 0,
          enableShades: true,
        }
      },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#000'],
        font: '12px'
      }
    },
    labels:agentName,   
    colors: ["#ffc107"],
    xaxis: {
        type: 'categories',
        tickPlacement: 'between',
      },
    title: {
      text: ''
    },
  }

  const changePageIndex = (val) =>{
    let a = val*PageSize;
    setPageIndex(val)
    setData(props.metricsData.slice(a,a+10))
  }

  console.log(series)

  return(
    <>
    {props?.loader?
      <div className="loader-container">
        <Loading className="variant"/>
      </div>:
      series[0].data.length>0||series[1].data.length>0||series[2].data.length>0||series[3].data.length>0||series[4].data.length>0?
      (<div className="p-4">
        <ReactApexChart type={'heatmap'} options={options} series={series} height="500" width="100%"/>
         <Pagination
            className="pagination-bar mb-1"
            currentPage={currentPage}
            totalCount={props?.metricsData.length}
            pageSize={PageSize}
            pageIndex={pageIndex}
            currentPages={currentPages}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            handlePageIndex={changePageIndex}
          />
      </div>
      ):<NodataFound/>
    }
    </>
  )}