import React, { useState } from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import "./pagination.css";
import ReactPaginate from "react-paginate";

const Pagination = (props) => {
  const {
    gotoPage,
    totalCount,
    pageIndex,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    handlePageIndex,
    resetPagination,
  } = props;
  const [copyArray, setCopyArray] = useState(false);
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  // console.log("MMMMMMMMMMMMMMM", { props, paginationRange })
  // If there are less than 2 times in pagination range we shall not render the component
  // if (currentPage === 0 || paginationRange.length < 1) {
  //   return null;
  // }

  const onNext = (e) => {
    // console.log("MMMMMMMMMMMMMMMONNEXT", e)
    nextPage();
    handlePageIndex(pageIndex + 1);
    let n = paginationRange.length;
    console.log(n);
    if (
      paginationRange[n - 2] === "..." &&
      pageIndex >= 4 &&
      pageIndex < paginationRange[n - 1] - 1
    ) {
      let i = 0;
      for (i = 0; i < 4; i++) {
        paginationRange[i] = paginationRange[i + 1];
      }
      paginationRange[i] = paginationRange[i - 1] + 1;
      if (paginationRange[i] == paginationRange[n - 1] - 2) {
        paginationRange[5] = paginationRange[n - 1] - 1;
      }
      setCopyArray(true);
    }
  };

  const onPrevious = (e) => {
    // console.log("MMMMMMMMMMMMMMMPrev", e)
    previousPage();
    handlePageIndex(pageIndex - 1);
    if (copyArray == true && pageIndex == paginationRange[6] - 2) {
      paginationRange[5] = "...";
    }
    if (pageIndex <= paginationRange[6] - 3 && paginationRange[0] != 1) {
      let i;
      for (i = 4; i > 0; i--) {
        paginationRange[i] = paginationRange[i - 1];
      }
      paginationRange[i] = paginationRange[i + 1] - 1;
    }
  };

  // function onPageChange(e) {
  //   gotoPage(e-1);
  //   handlePageIndex(e-1);
  //   if(copyArray == true && pageIndex == 6)
  //   {let i;
  //     for(i =6; i> 0 ;i--)
  //     {
  //       paginationRange[i] = paginationRange[i-1]
  //     }
  //     paginationRange[i] = paginationRange[i+1]-1
  //     paginationRange[5] = '...'
  //     paginationRange[6] = paginationRange[paginationRange.length-1]+1;
  //   }
  // }

  //  function onPageChange(pageNumber) {
  //   if (pageNumber === pageIndex + 1) {
  //     return;
  //   }
  //   gotoPage(pageNumber - 1);
  //   handlePageIndex(pageNumber - 1);
  //   if (copyArray && pageIndex === 6) {
  //     const newPaginationRange = [...paginationRange];
  //     for (let i = 6; i > 0; i--) {
  //       newPaginationRange[i] = newPaginationRange[i - 1];
  //     }
  //     newPaginationRange[0] = newPaginationRange[1] - 1;
  //     newPaginationRange[5] = '...';
  //     newPaginationRange[6] = paginationRange[paginationRange.length - 1];// + 1;
  //     setCopyArray(true);
  //   }
  // }

  function onPageChange(pageNumber) {
    // console.log("onPAGEINDEX", pageIndex)
    let check = true
    if (pageNumber === pageIndex + 1) {
      return;
    }
    gotoPage(pageNumber - 1);
    handlePageIndex(pageNumber - 1);
    if (totalCount === pageNumber) {
      for (let i = 6; i < 0; i--) {
        paginationRange[i] = pageNumber - 1;

      }
      check = false
    }
    if (copyArray && pageIndex >= 6 && check) {

      // const newPaginationRange = [...paginationRange];
      for (let i = 6; i > 0; i--) {
        // if(paginationRange[i-1] <= 1){
        //   return;
        // }
        paginationRange[i] = paginationRange[i - 1];
      }
      paginationRange[0] = paginationRange[1] - 1;
      paginationRange[5] = '...';
      paginationRange[6] = paginationRange[paginationRange.length - 1]; //+ 1;
      setCopyArray(true);
    }
  }
  const totalPageCount = Math.ceil(totalCount / pageSize);
  let storedData = JSON.parse(localStorage.getItem("AGENT"))
  return (
    // <ul
    //   className={classnames("pagination-container pagination", {
    //     [className]: className,
    //   })}
    // >
    //   {/* Left navigation arrow */}
    //   <li
    //     className={`pagination-item paginate_button page-item  ${canPreviousPage ? "" : "disabled"
    //       }`}
    //     onClick={onPrevious}
    //     key="100"
    //   >
    //     <div className="left page-link">Prev</div>
    //   </li>
    //   {paginationRange !== undefined &&
    //     paginationRange.map((pageNumber) => {
    //       if (pageNumber === DOTS) {
    //         return (
    //           <li className="pagination-item dots" key={pageNumber}>
    //             &#8230;
    //           </li>
    //         );
    //       }

    //       // Render our Page Pills
    //       return (
    //         <li
    //           className={`pagination-item paginate_button page-item ${pageIndex + 1 === pageNumber ? "active" : ""
    //             }`}
    //           onClick={() => onPageChange(pageNumber)}
    //         >
    //           <div className="page-link">{pageNumber}</div>
    //         </li>
    //       );
    //     })}
    //   {/*  Right Navigation arrow */}
    //   <li
    //     className={`pagination-item paginate_button page-item ${canNextPage ? "" : "disabled"
    //       }`}
    //     onClick={onNext}
    //   >
    //     <div className="right page-link">Next</div>
    //   </li>
    // </ul>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 20,
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
      }}
    >
      <ReactPaginate className={classnames("pagination-container pagination", {
        [className]: className,
      })}
        previousLabel={"Prev"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPageCount}
        selected={0}
        // forcePage={storedData?.pageSelected ? storedData?.pageSelected - 1 : 0}
        forcePage={resetPagination ? pageIndex : undefined}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3} //on clicking dotspagepagepage
        onPageChange={(e) => handlePageIndex(e.selected)}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={'page-item'}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      >
      </ReactPaginate>
      </div>
  );
};

export default Pagination;
