import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import background from "../../assets/images/login-images/forgot-password.png";
import background from '../../assets/images/login-images/sign-in.png';
import odio_logo_blue from "../../assets/images/odio_logo_blue.png";
import userService from "../Services/user.service";
import toaster from "../Toast/toaster";
import Loading from "../Commons/Loading";
import ForgotPasswordEnterOTP from './ForgotPasswordEnterOTP';

export default function ForgotPassword() {
  const [inputs, setInputs] = useState({
    email: "",
  });
  const [formError, setFormErrors] = useState({});
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(true);

  let btnRef = useRef();
  const errors = {};

  const { email } = inputs;

  useEffect(() => {
    document.title = "Forgot Password  - Odio";
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, email: value }));
    btnRef.current.removeAttribute("disabled");
  }

  const validate = (values) => {
    console.log(values);
    if (!values.email) {
      errors.email = "Enter your login id (email).";
    }
    return errors;
  };

  function handleFocus() {
    formError.email = "";
  }

  function handleSubmit(e) {
    e?.preventDefault();
    setLoading(true);
    validate(inputs);
    setMessage("");
    setSuccessMessage("");
    setFormErrors(errors);
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
      // setLoading(false)
    }
    if (Object.keys(errors).length === 0) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      userService.forgotPassword(inputs).then((response) => {
        console.log("response==", response);
        if (response.data.status === 0) {
          console.log("success response", response);
          setFlag(false);
          setLoading(false);
          setSuccessMessage(response.data.message);
        }
      }).catch((err)=>{
        console.log(err,"cskbcksb")
        toaster.error("User Not Registered")
        setLoading(false);
        setInputs((inputs) => ({ ...inputs, email: "" }))
      });
    }
  }

  console.log(errors);

  return (
    <>
      <div className="wrapper forget-content">
        <div
          className="authentication-header header-forget-page"
          style={{ backgroundImage: `url(${background})` }}
        />

        <div className="section-authentication-signin d-flex justify-content-center my-5 my-lg-0">
          <div className="container-fluid">
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
              <div className="col mx-auto">
                <div className="card mb-5">
                  <div className="card-body">
                    <div className="p-4 rounded">
                      <div className="text-center mb-4 p-0">
                        <a className="navbar-brand" href="#">
                          <img src={odio_logo_blue} width={140} alt="" />
                        </a>
                      </div>
                      {flag ?
                        <div className="form-body">
                          <form className="row g-3">
                            <h4 className=" font-weight-bold">
                              Forgot Password?
                            </h4>
                            <p className="text-muted m-0">
                              Enter your registered email ID to reset the password
                            </p>
                            <div className>
                              <label className="form-label">Email</label>
                              <div className="input-group">
                                {" "}
                                <span className="input-group-text bg-transparent">
                                  <i className="lni lni-envelope" />
                                </span>
                                <input
                                  type="email"
                                  name="email"
                                  value={email}
                                  onFocus={handleFocus}
                                  onChange={handleChange}
                                  className="form-control form-control-lg"
                                  id="inputEmail"
                                  placeholder="example@user.com"
                                />
                              </div>
                            </div>
                            <p
                              style={{ color: "green", height: "18px" }}
                              className="my-1 lh-1 font-12"
                            >
                              <span style={{ color: "green" }} className="">
                                {successMessage}
                              </span>
                              <span style={{ color: "red" }} className="">
                                {message ? message : formError.email}
                              </span>
                            </p>

                            <div className="d-grid gap-2 mt-0">
                              <button
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-lg f-send-btn"
                                ref={btnRef}
                                disabled={!email}
                              >
                                {loading ? (
                                  <Loading variant="light" />
                                ) : (
                                  <>Submit</>
                                )}
                              </button>
                              <a href="/login" className="btn btn-light btn-lg" style={{color:'#0a58ca'}}>
                                <i className="bx bx-arrow-back me-1" />
                                {/* <Link to="./login">Back to Login</Link> */}
                                Back to Login
                              </a>
                            </div>
                          </form>
                        </div> : <ForgotPasswordEnterOTP email={email} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end row*/}
          </div>
        </div>

        <footer className="bg-white shadow-sm border-top p-2 text-center fixed-bottom">
          <p className="mb-0">
            Copyright © 2021 - VoiceIO Artificial Intelligence Pvt Ltd
          </p>
        </footer>
      </div>
    </>
  );
}
