import userService from "../../Services/user.service";
import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  updateUser,
  getUserList,
} from "../../../redux-container/settings/manage_users/action";
import Autosuggest from "react-autosuggest";
import { ListGroup } from "react-bootstrap";
import Autosuggestions from "../../Commons/Autosuggestions";
//import { Select, MenuItem } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CountrySelect from "../../Commons/CountrySelect";
import { propsToClassKey } from "@mui/styles";

function EditUser(props) {
  console.log(props,'lplp')
  let employee_id, emailId, user_id;
  user_id = props.selectedRowData.id;
  employee_id = props.selectedRowData.employeeId;
  emailId = props.selectedRowData.emailId;
  
  const [roles, setRoles] = useState([])
  const [suggestions, setSuggestions] = useState([]);
  const [inputs, setInputs] = useState({
    userFirstName: props.selectedRowData.firstName
      ? props.selectedRowData.firstName
      : "",
    userLastName: props.selectedRowData.lastName
      ? props.selectedRowData.lastName
      : "",
    userId: user_id ? user_id : "",
    employeeId: employee_id?employee_id:"",
    email: props.selectedRowData.email ? props.selectedRowData.email : "",
    userLoginAllow: props.selectedRowData.isUserLogin ? props.selectedRowData.isUserLogin : "",
    secondaryEmailId: "",
    secondaryEmail: "",
    mobileNumberId:
    props.selectedRowData.userPhoneNumbers?
      props.selectedRowData.userPhoneNumbers.length > 0
        ? props.selectedRowData.userPhoneNumbers[0].type === "MOBILE"
          ? props.selectedRowData.userPhoneNumbers[0].id
          : ""
        : "":'',
    mobileNumber:
    props.selectedRowData.userPhoneNumbers?
      props.selectedRowData.userPhoneNumbers.length > 0
        ? props.selectedRowData.userPhoneNumbers[0].type === "MOBILE"
          ? props.selectedRowData.userPhoneNumbers[0].mobileNumber
          : ""
        : "":props.selectedRowData.mobile? props.selectedRowData.mobile: '',
    mobileNumberCountryCode:
    props.selectedRowData.userPhoneNumbers?
      props.selectedRowData.userPhoneNumbers.length > 0
        ? props.selectedRowData.userPhoneNumbers[0]
          ? props.selectedRowData.userPhoneNumbers[0].countryCode
          : ""
        : "":'',
    phoneNumberId:
    props.selectedRowData.userPhoneNumbers?
      props.selectedRowData.userPhoneNumbers.length > 0
        ? props.selectedRowData.userPhoneNumbers[1]
          ? props.selectedRowData.userPhoneNumbers[1].type === "LANDLINE"
            ? props.selectedRowData.userPhoneNumbers[1].id
            : ""
          : ""
        : "":'',
    phoneNumber:
    props.selectedRowData.userPhoneNumbers?
      props.selectedRowData.userPhoneNumbers.length > 0
        ? props.selectedRowData.userPhoneNumbers[1]
          ? props.selectedRowData.userPhoneNumbers[1].type === "LANDLINE"
            ? props.selectedRowData.userPhoneNumbers[1].mobileNumber
            : ""
          : ""
        : "":'',
    phoneNumberCountryCode:
    props.selectedRowData.userPhoneNumbers?
      props.selectedRowData.userPhoneNumbers.length > 0
        ? props.selectedRowData.userPhoneNumbers[1]
          ? props.selectedRowData.userPhoneNumbers[1].countryCode
          : ""
        : "":'',
    phoneNumberStateCode:
    props.selectedRowData.userPhoneNumbers?
      props.selectedRowData.userPhoneNumbers.length > 0
        ? props.selectedRowData.userPhoneNumbers[1]
          ? props.selectedRowData.userPhoneNumbers[1].stateCode
          : ""
        : "":'',
    phoneNumberExtension:
    props.selectedRowData.userPhoneNumbers?
      props.selectedRowData.userPhoneNumbers.length > 0
        ? props.selectedRowData.userPhoneNumbers[1]
          ? props.selectedRowData.userPhoneNumbers[1].extension
          : ""
        : "":'',
    designation: props.selectedRowData.designation
      ? props.selectedRowData.designation
      : "",
    roleId: props.selectedRowData.roleId
      ? props.selectedRowData.roleId
      : "",
    role: props.selectedRowData.role ? props.selectedRowData.role : "",
    caller: props.selectedRowData.caller ? props.selectedRowData.caller : false,
    status: 1,
    coe:props?.selectedRowData?.clientCoes?props?.selectedRowData?.clientCoes[0]?.name:"",
    newCoeId:props?.selectedRowData?.clientCoes?props?.selectedRowData?.clientCoes[0]?.id:"",
    managerId: props.selectedRowData.managerId
      ? [props.selectedRowData.managerId]
      : [],
    manager: props.selectedRowData.manager ? props.selectedRowData.manager : "",
    clientExternalId: JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId,
    //externalId:1234569
  });
  console.log(inputs,'lplp###')
  const [errors, setErrors] = useState({});
  const [value, setValue] = useState(inputs.manager);

  const handleChange = (event) => {
    console.log(event, 'llll+')
    if (event.target) {
      let { name, value, checked } = event.target;
      if (event.target && event.target.name != "caller" && event.target.name != "live" && event.target.name != "userLoginAllow") {
      if (name === "userFirstName" || name === "userLastName") {
        value = value.replace(/[^A-Za-z]/gi, "");
        value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        value = value.replace(/\b\w/g, (match) => match.toUpperCase());
      }
      if (
        name === "mobileNumber" ||
        name === "phoneNumber" ||
        name === "phoneNumberExtension" ||
        name === "phoneNumberStateCode"
      ) {
        value = value.replace(/[^0-9]/g, "");
        errors.mobileNumber = "";
      }
      if(name==='employeeId'){
        value = value.replace(/[^A-Za-z0-9]/gi, "");
      }
      if(name==='email'){
        if(! /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)){
          setErrors({email:"Email is invalid(only .,_,@ are allowed)"})
        }
        else{
          setErrors({})
        }
      }
      if(name==='designation'){
        value = value.replace(/[^A-Za-z0-9]/gi, "");
      }
    }
    
    if (event.target.name === "caller" || event.target.name == "userLoginAllow"){
        setInputs((inputs) => ({ ...inputs, [name]: checked }));
    }

      else setInputs((inputs) => ({ ...inputs, [name]: value }));
    } else {
      
      setInputs((inputs) => ({
        ...inputs,
        mobileNumberCountryCode: event.phone,
      }));

    }
    console.log(inputs,'lplp###')
  };

  const handleCountryCodeChange = (event) => {
    setInputs((inputs) => ({ ...inputs, phoneNumberCountryCode: event.phone }));
  };

  const handleMobileCountryCodeChange = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      mobileNumberCountryCode: event.phone,
    }));
  };

  const handleCoeChange = (e)=>{
    console.log(e.target.value)
    let selectedCoe = props?.coeOptions.filter(item=>{
      return item.coeId==e.target.value
    })
    console.log(selectedCoe)
    setInputs((inputs) => ({ ...inputs, newCoeId:e.target.value, coe:selectedCoe[0]?.coeName, clientExternalId: JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId }));
  }

  const formError = {};
  const validate = (values) => {
    if (!values.userFirstName) {
      formError.userFirstName = "Enter First Name";
    }
    // if (!values.userLastName) {
    //   formError.userLastName = "Enter last Name";
    // }
    if (values.mobileNumber) {
      console.log("working");
      console.log(values.mobileNumber);
      if (!values.mobileNumberCountryCode)
        formError.mobileNumberCountryCode = "Please Give Country Code";
      else {
        if (values.mobileNumber.length < 10 || values.mobileNumber.length > 10)
          formError.mobileNumber = "Please enter 10 digits";
        if (
          !/^[6-9]\d{9}$/gi.test(values.mobileNumber) &&
          values.mobileNumber.length === 10 &&
          values.mobileNumber[0] !== 0
        ) {
          formError.mobileNumber = "Please enter correct mobile number";
        }
        if (values.mobileNumber[0] == 0) {
          formError.mobileNumber = "Mobile Number cannot start with 0";
        }
      }
    } else {
      formError.mobileNumber = "Please enter mobile number";
    }
    if (!values.email) {
      formError.email = "Enter email Id";
    } else {
      if (
        !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
          values.email
        )
      ) {
        formError.email = "Email is invalid(only .,_,@ are allowed)";
      }
    }
    if (values.phoneNumber) {
      if (!values.phoneNumberCountryCode) {
        formError.phoneNumberCountryCode = "Please Give Country Code";
      }
      if (
        values.phoneNumberCountryCode === "+91" &&
        values.phoneNumber.length > 1 &&
        values.phoneNumber.length < 10
      ) {
        formError.phoneNumber = "Please enter 10 digits";
      }
    }

    return formError;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    validate(inputs);
    console.log(inputs, 'lplp')
    setErrors(formError);
    console.log("abcd", inputs);
    console.log(formError);
    if (Object.keys(formError).length == 0) {
      userService.updateUser(inputs).then((res) => {
        console.log(res);
        props.setEditUserVisibility(false);
        props.getUserList();
      });
    }
  };


  const inputProps = {
    //   placeholder: 'Select Manager',
    //   onChange:  (_, { newValue, method }) => {
    //     setValue(newValue);
    //     console.log(newValue);
    placeholder: "Select Manager",
    value,
    onChange: (_, { newValue, method }) => {
      setValue(newValue);
      console.log(newValue);
    },
  };

  const getRoleOptions = ()=>{
    userService.getRoleOptions().then(res=>{
      if(res)
        setRoles(res.data.data)
    })
  }

  useEffect(()=>{
    getRoleOptions()
  },[])

  const getSuggestions = (value) => {
    if (value.length < 3) setSuggestions([]);
    else {
      var data = {
        val: value,
        status: "ACTIVE",
        coeId: null,
        id:user_id
      };
      let promise = userService.getManagerPermissions(data);
      promise.then((res) => {
        console.log(res);
        //var newList = res.filter((item) => item.id != inputs.userId);
        setSuggestions(res.data.data);
        //console.log("user id ", newList)
      });
    }
  };

  const getSuggestionValue = (suggestion) => {
    setInputs((inputs) => ({ ...inputs, managerId: [suggestion.id] }));
    return `${suggestion.firstName} (${suggestion.id})`;
  };

  const renderSuggestion = (suggestion) => (
    <ListGroup>
      <ListGroup.Item key={suggestion.id}>
        {`${suggestion.firstName} (${suggestion.id})`}
      </ListGroup.Item>
    </ListGroup>
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    getSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  console.log(props.selectedRowData)

  return (
    <>
      <div className="emp-edit-box mt-3">
        <div className=" d-flex align-items-center">
          <div>
            <i className="bx bxs-user me-1 font-22 edit-profile-icon" />
          </div>
          <h5 className="mb-0 edit-profile-header">Update User</h5>
        </div>
        <hr />
        <form className="row g-3" onSubmit={handleFormSubmit}>
          <div className="col-md-6">
            <label htmlFor="inputEMPFirstName" className="form-label">
              First Name*
            </label>
            <div className="input-group">
              {" "}
              <span className="input-group-text bg-transparent">
                <i className="bx bxs-user" />
              </span>
              <input
                type="text"
                className="form-control"
                id="inputFirstName"
                placeholder="First Name"
                name="userFirstName"
                value={inputs.userFirstName}
                onChange={handleChange}
                maxLength="32"
              />
            </div>
            {/* <span>{errors.userFirstName && <p style={{color:'red'}}>{errors.userFirstName}</p> }</span>  */}
          </div>
          <div className="col-md-6">
            <label htmlFor="inputEMPLastName" className="form-label">
              Last Name
            </label>
            <div className="input-group">
              {" "}
              <span className="input-group-text bg-transparent">
                <i className="bx bxs-user" />
              </span>
              <input
                type="text"
                className="form-control"
                id="inputLastName"
                placeholder="Last Name"
                name="userLastName"
                value={inputs.userLastName}
                onChange={handleChange}
                maxLength="32"
              />
            </div>
            <span>{errors.userLastName && <p style={{color:'red'}}>{errors.userLastName}</p>}</span> 
          </div>
          <div className="col-md-6">
            <label htmlFor="inputEMPcode" className="form-label">
              Employee Id*
            </label>
            <div className="input-group">
              {" "}
              <span className="input-group-text bg-transparent">
                <i className="fadeIn animated bx bx-hash" />
              </span>
              <input
                type="text"
                name="employeeId"
                className="form-control"
                id="inputEMPcode"
                value={inputs.employeeId}
                onChange={handleChange}
                maxLength="32"
              />
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="inputEMPEmail" className="form-label">
              Email*
            </label>
            <div className="input-group">
              {" "}
              <span className="input-group-text bg-transparent">
                <i className="lni lni-envelope" />
              </span>
              <input
                type="email"
                className="form-control"
                id="inputEmail"
                placeholder="example@user.com"
                name="email"
                value={inputs.email}
                onChange={handleChange}
                defaultValue={emailId}
                maxLength="64"
              />
            </div>
            <span>
              {" "}
              {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
            </span>
          </div>
          <div className="col-md-6">
            <label htmlFor="inputRole" className="form-label">
              Role*
            </label>
            <div className="input-group">
              {" "}
              <span className="input-group-text bg-transparent">
                <i className="bx bxs-user" />
              </span>
              <div className="form-select mui-selectedbox">
                <Select
                  id="inputRole"
                  className="form-select"
                  value={inputs.roleId}
                  onChange={handleChange}
                  name="roleId"
                >
                  {/* <MenuItem value="" disabled>
                    {" "}
                    Role
                  </MenuItem>
                  <MenuItem value="1">ADMIN</MenuItem>
                  <MenuItem value="2">AGENT</MenuItem> */}
                  {roles.map(item=>(
                    <MenuItem value={item.id}>{item.roleName}</MenuItem>
                   ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="inputDesignation" className="form-label">
              Designation
            </label>
            <div className="input-group">
              {" "}
              <span className="input-group-text bg-transparent">
                <i className="bx bxs-user" />
              </span>
              <input
                type="text"
                className="form-control"
                id="inputDesignation"
                placeholder="Designation"
                name="designation"
                value={inputs.designation}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="inputEMPphone" className="form-label">
              Phone
            </label>
            <div className="row g-3">
              <div className="col-md-6">
                <CountrySelect
                  value={inputs.phoneNumberCountryCode}
                  name="phoneNumberCountryCode"
                  handleCountryCodeChange={handleCountryCodeChange}
                />
                <p class="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
                  {" "}
                  {errors.phoneNumberCountryCode && (
                    <span style={{ color: "red" }}>
                      {errors.phoneNumberCountryCode}
                    </span>
                  )}
                </p>
              </div>
              <div className="col-md-6">
                <input
                  type="num"
                  className="form-control"
                  id="stateCode"
                  placeholder="STD Code"
                  name="phoneNumberStateCode"
                  value={inputs.phoneNumberStateCode}
                  onChange={handleChange}
                  maxLength="6"
                />
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  {" "}
                  <span className="input-group-text bg-transparent">
                    <i className="lni lni-phone-set" />
                  </span>
                  <input
                    type="num"
                    className="form-control"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={inputs.phoneNumber}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <input
                  type="extNum"
                  className="form-control"
                  name="phoneNumberExtension"
                  id="ext-link"
                  placeholder="Ext"
                  value={inputs.phoneNumberExtension}
                  maxLength="6"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="inputEMPmob" className="form-label">
              Mobile*
            </label>
            <div className="row g-3">
              <div className="col-md-6">
                <CountrySelect
                  value={inputs.mobileNumberCountryCode}
                  name="mobileNumberCountryCode"
                  handleMobileCountryCodeChange={handleMobileCountryCodeChange}
                  //   handleChange={handleChange}
                />
                <p class="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
                  {" "}
                  {errors.mobileNumberCountryCode && (
                    <span style={{ color: "red" }}>
                      {errors.mobileNumberCountryCode}
                    </span>
                  )}
                </p>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <span className="input-group-text bg-transparent">
                    <i className="lni lni-mobile" />
                  </span>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Mobile Number"
                    name="mobileNumber"
                    value={inputs.mobileNumber}
                    onChange={handleChange}
                    maxLength={15}
                  />
                </div>
                <span>
                  {errors.mobileNumber && (
                    <p style={{ color: "red" }}>{errors.mobileNumber}</p>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6" id="inputManager">
            <div className="row g-3">
              <div className="col-md-6">
                <label htmlFor="inputManager" className="form-label">
                  Manager
                </label>
                <div className="input-group">
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    //value={{firstName: `${inputs.manager}`, id: `${inputs.managerId}`}}
                    defaultValue="bhn"
                    inputProps={inputProps}
                  />
                  {/* <Autosuggestions inputProps={inputProps} component='EditUser' setInputs={setInputs} id={inputs.userId}/> */}
                </div>
              </div>
              {props.selectedRowData.role=='AGENT'?
              <div className="col-md-6">
                <label htmlFor="inputCoe" className="form-label">
                  COE
                </label>
                <div className="input-group">
                  {" "}
                  {/* <span className="input-group-text bg-transparent">
                    <i className="bx bxs-user" />
                  </span> */}
                  <div className="form-select mui-selectedbox">
                    <Select
                      id="inputRole"
                      className="form-select"
                      value={inputs.newCoeId}
                      onChange={handleCoeChange}
                      name="coeId"
                      placeholder="Select COE"
                    >
                      {/* <MenuItem value="" disabled>
                        {" "}
                        Role
                      </MenuItem>
                      <MenuItem value="1">ADMIN</MenuItem>
                      <MenuItem value="2">AGENT</MenuItem> */}
                      {props?.coeOptions?.map(item=>(
                        <MenuItem value={item.coeId}>{item.coeName}</MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>:""}
            </div>
          </div>
          <div class="col-md-6">
          <div className="row">
            {/* <div class="col-md-6">
              <label for="Manager" class="form-label">
                Caller
              </label>
              <div class="form-check form-switch font-30">
                {inputs.caller ? (
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="callerSwitch"
                    name="caller"
                    checked
                    onChange={handleChange}
                  />
                ) : (
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="callerSwitch"
                    name="caller"
                    onChange={handleChange}
                  />
                )}
              </div>
            </div> */}
            <div class="col-md-3">
              <label for="Manager" class="form-label">
                Call Upload 
              </label>
              <div class="form-check form-switch font-30">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="callUpload"
                  name="callUpload"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-5">
              <label for="Manager" class="form-label">
                Login Allowed
              </label>
              <div class="form-check form-switch font-30">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="liveSwitch"
                  name="userLoginAllow"
                  checked={inputs.userLoginAllow}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          </div>
          <div className="col-12 text-sm-right cancelUpadte-btn">
            <button
              type="button"
              className="btn px-5 cancel-btn lh-base"
              id="updateEMPedit-cancel"
              onClick={() => props.setEditUserVisibility(false)}
            >
              <i className="bx bx-arrow-back me-1" />
              Cancel
            </button>
            <button
              type="submit"
              className="btn px-5 update-btn lh-base"
              id="update-EMPedit-Team"
              // onClick={props.handleFormSubmit}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (data) => {
      dispatch(updateUser(data));
    },
    getUserList: () => {
      dispatch(getUserList());
    },
  };
};

export default connect(null, mapDispatchToProps)(EditUser);
