import React, { useEffect, useState,useRef} from "react";
import { Link } from "react-router-dom";
import Profile from "./Profile";
import { Dropdown } from "react-bootstrap";
import { USER_DETAIL } from "../../Constants/constant";
import { connect} from "react-redux";
import NotificationList from "./NotificationList";
import SearchBar from "./SearchBar";
import { db } from "../../utils/firebase";
import { getDatabase, onValue, ref } from "firebase/database";
import { collection, addDoc, getDocs, onSnapshot } from "firebase/firestore";
import './commons.css'
import useOutsideClick from "./useOutsideClick";
// import listenForOutsideClicks from "./listen-for-outside-click";

 function Header(props) {

	const [clientName, setClientName] = useState('')
	const [userRole, setUserRole] = useState('')
	const [name, setName] = useState('')
	const [shortName, setShortName] = useState('')
	const [notifyShow, setNotifyShow] = useState(false)
    const [isOnline, setIsOnline] = useState(navigator.onLine)
	const [unreadNotif,setUnreadNotif] = useState('0')
	const [show,setShow] = useState(false)

	useEffect(() => {
		var p = localStorage.getItem(USER_DETAIL)
		let data = JSON.parse(p) ;
		setClientName(data.clientName)
		setUserRole(data.userRole)
		setName(data.userFirstName)
		setShortName(data.userFirstName[0]+(data.userLastName?data.userLastName[0]:""))
	},[])
	
	useEffect(()=>{
		// const query = ref(db, "notifications");
		// return onValue(query, (snapshot)=>{ 
		// 	console.log(snapshot)
		// 	let data = snapshot._node.value_
		// 	console.log(data)
		// 	const notifData = JSON.parse(data) 
		// 	//console.log(notifData)
		// 	if (snapshot.exists()) {
		// 		console.log(Object.entries(notifData))
		// 		let count = 0
		// 		Object.entries(notifData)?.map((notif) => {
		// 			console.log(notif)
		// 			if(notif[0]==JSON.parse(localStorage.getItem("USER_DETAIL"))?.userExternalId){
		// 				notif[1].map(item=>{
		// 					if(item?.notificationReadStatus?.readStatus==false){
		// 						count =count + 1
		// 					}	
		// 				})
		// 			}
		// 			// return count
		// 		});
		// 		console.log(count)
		// 		setUnreadNotif(count)
		// 		// console.log(notifCount)
		// 	}
		// })



		const unsubscribe = onSnapshot(collection(db, 'notifications'), (snapshot) => {
			const updatedData = snapshot.docs.map((doc) => ({
			  id: doc.id,
			  ...doc.data()
			}));
			let count = 0
			updatedData.map((item,id)=>{
				if(item.id==JSON.parse(localStorage.getItem("USER_DETAIL"))?.userExternalId){
					Object.entries(item).map(([key,value])=>{
						if(value.readStatus==false){
							count=count+1
						}
					})
				}
			})
			console.log(count,"NOTIFCOUNT")
			setUnreadNotif(count)
		  });
		  return () => {
			unsubscribe();
		};
		
	},[unreadNotif])

	console.log("UNREADNOTIF",unreadNotif)

	const dropdownRef = useRef(null);

	const closeDropdown = () => {
		setNotifyShow(false);
	  };
	  
	  useOutsideClick(dropdownRef, closeDropdown);
	  
	const handleNotification= ()=>{ 
		// setNotifyShow(!notifyShow)
		// let disp = document.getElementById('notification-List')?.style.display
		// if(document.getElementById('notification-List'))
		// 	document.getElementById('notification-List').style.display = disp == 'block'? 'none':'block';
		setNotifyShow((prevState) => !prevState);
	}

	const handleSearch = ()=>{
		setShow(true)
	}
	const closeButton = ()=>setShow(false)
	
	const closeNotifyShow = ()=>{
		setNotifyShow(false)
	}

	return (
		<header className={!isOnline?'top-error':''}>
			{!isOnline? <p className="top-network-error">You seems to be gone offline. Please check your internet. <a onClick={() => window.location.reload(false)} className='link'>Click here</a> to reload.</p> : null}
			<div className="topbar">
				<div className="d-flex align-items-center bg-dark-blue shadow-none border-light-2 border-bottom">
					<nav className="navbar navbar-expand">
						<div className="mobile-toggle-menu text-white me-3"><i className="bx bx-menu" />
						</div>
						<div className="top-menu-left d-none d-lg-block">
							<div className="d-flex align-items-center">
								<div className="logo-avatat-clint me-2">
									<img src={props.clientLogo && props.clientLogo != ''? `https://app.odioiq.com/odio/api/client/image?keyName=${props.clientLogo}`: require('../../assets/images/clint_logo/clintLogoDefault.png')} className="rounded-circle" width="35" />
								</div>
								<h3 className="dashboard-headings text-white">{props.clientName? props.clientName: 'Name'}</h3>
							</div>
						</div>
						<div className="search-bar flex-grow-1">
							<div className="position-relative search-bar-box">
								<form>
									<input type="text" className="form-control search-control" autoFocus placeholder="Type to search..." /> <span className="position-absolute top-50 search-show translate-middle-y"><i className="bx bx-search" /></span>
									<span className="position-absolute top-50 search-close translate-middle-y"><i className="bx bx-x" /></span>
								</form>
							</div>
						</div>
						<div className="top-menu ms-auto">
							<ul className="navbar-nav align-items-center">
								<li className="nav-item mobile-search-icon">
									<a className="nav-link text-white" onClick={handleSearch} style={{cursor:"pointer"}}>	<i className="bx bx-search" />
									</a>
								</li>
								<li className="nav-item dropdown dropdown-large" ref={dropdownRef}>
									<a onClick={handleNotification} className={`nav-link dropdown-toggle dropdown-toggle-nocaret position-relative text-white ${notifyShow?'show':''}`} href="#" role="button" data-bs-toggle="dropdown" aria-expanded={notifyShow}> 
										<span className="alert-count">{unreadNotif}</span>
										<i className="bx bx-bell" />
									</a>
									{notifyShow?<NotificationList notifyShow={notifyShow} closeNotifyShow={closeNotifyShow}/>:""}
								</li>
							</ul>
						</div>
						<Dropdown className="user-box dropdown border-light-2">
							<Dropdown.Toggle variant="" className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret"
								  href='#' role="button" data-bs-toggle="dropdown" aria-expanded="false">
								<Dropdown.Menu style={{marginTop: '60px'}}>
									<Profile />
								</Dropdown.Menu>
								<p className="user-header-avatat">{shortName}</p>
								<div className="user-info ps-3 text-start">
									<p className="user-name mb-0 text-white">{props.userFirstName}</p>
									<p className="designattion mb-0 text-start">{userRole}</p>
								</div>
							</Dropdown.Toggle>
						</Dropdown>
						{show?<SearchBar show={show} closeButton={closeButton}/>:""}
					</nav>
				</div>
			</div>
			
		</header>
	)

}

const mapStateToProps = (state) =>{
	console.log(state)
	return{
		clientName: state.login.clientName,
		clientLogo: state.login.clientLogo,
		userFirstName: state.login.userFirstName,
	}
}


export default connect(mapStateToProps) (Header);

