import React, { useState,useEffect } from "react";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Row, Col, Card, Tabs, Tab, Button } from "react-bootstrap";
import ComparativeAnalysisGraph from "./ComparativeAnalysisGraph";
import MultiSelect from  'react-multiple-select-dropdown-lite'
import FormControl from '@mui/material/FormControl';
import  'react-multiple-select-dropdown-lite/dist/index.css'
import "../Insight.css"
import insightService from "../../Services/insight.service";
import dashboardService from "../../Services/dashboard.service";
import profileService from "../../Services/profile.service";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import Loading from "../../Commons/Loading";
import { apiCall } from "../../Services/Interceptor";
import clientService from "../../Services/client.service";

export default function ComparativeAnalysis(){

    let localData = JSON.parse(localStorage.getItem("AGENT"))
    let defaultAnalysis = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.comparativeAnalysis
    const [customInput, SetCustomInput] = useState(localData&&localData.selectedRange==9?true:localData&&localData.selectedRange==''?true:false);
    const [date, setDate] = useState(localData&&localData.selectedRange?localData.selectedRange:localData&&localData.selectedRange==''?9:5);
    const [dates,setDates] = useState([])
    const [opendp, setOpendp] = useState(false);
    const [opendp1, setOpendp1] = useState(false);
    const crrDate = new Date()
    const [startDate, setStartDate] = useState(localData?.fromD?new Date(`${localData.fromD.split("-")[1]}-${localData.fromD.split("-")[0]}-${localData.fromD.split("-")[2]}`):new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    const [endDate, setEndDate] = useState(localData?.toD?new Date(`${localData?.toD.split("-")[1]}-${localData?.toD.split("-")[0]}-${localData?.toD.split("-")[2]}`):new Date())
    const [userList, setUserList] = useState([])
    const [selectedAgent, setSelectedAgent] = useState(localData?.userNames?localData?.userNames:[{label:"Select All",value:"Select All"}])
    const [filteredData, setFilteredData] = useState([])
    const [defaultFilteredData, setDefaultFilteredData] = useState([])
    const [data,setData] = useState([])
    const [loader, setLoader] = useState(false)
    const [analysis, setAnalysis] = useState(localData?.analysis?localData.analysis:defaultAnalysis[0].value);
    const [def, setDef] = useState(false)
    const [event, setEvent] = useState(analysis)
    const [isExcelPdfButton, setIsExcelPdfButton] = useState(false)
    const [dateChange, setDateChange] = useState(false)

    useEffect(() => {
        document.title = "Comparative Analysis  - Odio"
      }, [])

    let optionsComparitive = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.comparativeAnalysis

    const getDateAcronym = ()=>{
        dashboardService.getDateAcronym().then(res=>{
        let dateList = res?res.data?res.data.data.filter((e)=>{
            return e.type =="CURRENT" || e.type =="GLOBAL"
        }):'':''
        setDates(dateList);
        let dd = dateList[4].fromDate.split("-")[2]
        let mm = dateList[4].fromDate.split("-")[1]
        let yyyy = dateList[4].fromDate.split("-")[0]
        let dateF = `${dd}-${mm}-${yyyy}`
        let dd_1 = dateList[4].toDate.split("-")[2]
        let mm_1= dateList[4].toDate.split("-")[1]
        let yyyy_1= dateList[4].toDate.split("-")[0]
        let dateT = `${dd_1}-${mm_1}-${yyyy_1}`
        setDefaultFilteredData(defaultFilteredData=>({...defaultFilteredData,
            fromDate:dateT,
            toDate:dateF}))
        })
    }
    const getUserList = async()=>{
        const res = await apiCall.get(`${'/odio/api/user/get-user-team'}?fromD=${localData?.fromD?localData.fromD:null}&toD=${localData?.toD?localData.toD:null}&coeIds=${localData?.selectedCoeRange?localData.selectedCoeRange:''}`); 
          if(res.data.status === 0){
            setUserList(res?.data?.data?.team)
            let userIds = res?.data?.data?.team.map(item=>{
              return item.id
            })
            setDefaultFilteredData(defaultFilteredData=>({...defaultFilteredData,id:userIds}))
          }else if(res.data.status === 1){
            setLoader(false);
          }
        
    }

    const handleAgentChange = (event, values)=>{
        setDateChange(true)
        setSelectedAgent(values)
        let arr = []
        if(values.length>1){
          arr = values.map(item=>{return item.value})
        }
        else if(values.length==0){
          arr = defaultFilteredData.id
        }
        else if(values[0].label=='Select All'){
          arr = defaultFilteredData.id
        }
        else{
          arr.push(values[0].value)
        }
        let numberArray = arr.map(Number);
        let selectedAgents = []
        selectedAgents = userList.filter(item=>{
          arr.includes(item.id)
        })
       
        setFilteredData({...filteredData,id:arr,userNames:values})
    }

    const handleChange = (event) =>{
        setDateChange(true)
        setDate(event.target.value)
        if(event.target.value ==9){
            SetCustomInput(true)
            setFilteredData({...filteredData,dateName:"Custom Date"})
        }
        else{
            SetCustomInput(false)
            let SelectedDate = dates.filter(function (el) {
                return el.id == event.target.value;
            })
            let dd = SelectedDate[0].fromDate.split("-")[2]
            let mm = SelectedDate[0].fromDate.split("-")[1]
            let yyyy = SelectedDate[0].fromDate.split("-")[0]
        
            let dd_1 = SelectedDate[0].toDate.split("-")[2]
            let mm_1= SelectedDate[0].toDate.split("-")[1]
            let yyyy_1= SelectedDate[0].toDate.split("-")[0]
        
            let dateF = `${dd}-${mm}-${yyyy}`
            let dateT = `${dd_1}-${mm_1}-${yyyy_1}`
    
            setFilteredData({...filteredData,fromDate: dateT,toDate:dateF,dateName:SelectedDate[0].name})
        }
    }

    const handleStartDate = (e)=>{
        setStartDate(e)
        let date = `${(new Date(e)).getDate()}-${(new Date(e)).getMonth()+1}-${(new Date(e)).getFullYear()}`;
        setFilteredData({...filteredData,fromDate: date})
    }
    
    const handleEndDate = (e)=>{
        setEndDate(e)
        let date = `${(new Date(e)).getDate()}-${(new Date(e)).getMonth()+1}-${(new Date(e)).getFullYear()}`;
        setFilteredData({...filteredData,toDate: date})
    }

    const handleAnalysis = (event)=>{
        setAnalysis(event.target.value)
        setDef(false)
    }

    const onSubmitHandler = ()=>{
        if(dateChange == true){
            setLoader(true)
        }
        setEvent(analysis)
        localStorage.setItem("AGENT",JSON.stringify({
            "pageSelected":localData?.pageSelected,
            "analysis":analysis?analysis:localData?.analysis,
            "productivity":localData?.productivity,
            "fromD":filteredData?.fromDate?filteredData.fromDate:localData?.fromD,
            "toD":filteredData?.toDate?filteredData?.toDate:localData?.toD,
            "agentDetailCOE":localData?.agentDetailCOE,
            "selectedRange":date?date:localData?.selectedRange,
            "selectedDate":filteredData?.dateName?filteredData?.dateName:localData?.selectedDate,
            "userIds":filteredData?.id?filteredData?.id:localData?.userIds,
            "userNames":filteredData?.userNames?filteredData?.userNames:localData?.userNames,
            "empId":localData?.employeeId,
            "empName":localData?.user,
            "role":localData?.role,
            "designation":localData?.designation,
            "type":localData?.type,
            "teamCOE":localData?.teamCOE,
            "callsFrom":localData?.callsFrom,
            "callsTo":localData?.callsTo,
            "aht":localData?.aht,
            "callScore":localData?.callScore,
            "pitchScore":localData?.pitchScore,
            "customerName":localData?.customerName,
            "customerMobile":localData?.customerMobile,
            "fileName":localData?.fileName,
            "sellerROS":localData?.sellerROS,
            "sellerName":localData?.sellerName,
            "selectedSeller":localData?.selectedSeller,
            "cScore":localData?.cScore,
            "callHealth":localData?.callHealth,
            "callDuration":localData?.callDuration,
            "words":localData?.words,
            "moment":localData?.moment,
            "momentId":localData?.momentId,
            "pitch":localData?.pitch,
            "prob":localData?.prob,
            "opportunity":localData?.opp,
            "urgency":localData?.urgency,
            "coe":localData?.coe,
            "selectedCoeRange":localData?.selectedCoeRange,
            "selectedCOE":localData?.selectedCOE,
            "momentBucket":localData?.momentBucket,
            "momentBucketId":localData?.momentBucketId,
            "frequency":localData?.frequency,
            "reportType":localData?.reportType
        }))
        if(userList.length == 0){
            getUserList()
        }
        if(dateChange == true){
            getComparativeData()
        }
    }

    const onResetHandler = ()=>{
       setLoader(true)
       const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
       localStorage.setItem("AGENT",JSON.stringify({"pageSelected":localData?.pageSelected,"analysis":"Professional Manner","productivity":"Call Score","fromD":`${firstDay.getDate()}-${firstDay.getMonth()+1}-${firstDay.getFullYear()}`,"toD":`${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`,"agentDetailCOE":localData?.agentDetailCOE,"selectedRange":5,"selectedDate":"This Month","userIds":defaultFilteredData.id,"coe":localData?.coe,"selectedCoeRange":localData?.selectedCoeRange,"selectedCOE":localData?.selectedCOE,"momentBucket":localData?.momentBucket,"momentBucketId":localData?.momentBucketId}))
       setStartDate(firstDay)
       setEndDate(new Date())
    //    getDateAcronym()
    //    getUserList()
       setSelectedAgent([{label:"Select All",value:"Select All"}])
       setEvent(defaultAnalysis[0].value)
       setAnalysis(defaultAnalysis[0].value)
       setFilteredData([])
       SetCustomInput(false)
       setDate(5)
       getComparativeData()
    }

    const getComparativeData = ()=>{
        setLoader(true)
        let localData = JSON.parse(localStorage.getItem("AGENT"))
        let values= {
            "fromD":localData?.fromD?localData.fromD:defaultFilteredData.fromDate,
            "toD":localData?.toD?localData.toD:defaultFilteredData.toDate,
            "userId":localData?.userIds?localData.userIds:defaultFilteredData.id,
            "coeIds":localData?.selectedCoeRange
        }
        insightService.getComparative(values).then(res=>{
            if(res){
                setData(res.data.data)
                setLoader(false)
                setIsExcelPdfButton(true)
            }
        })
        setDateChange(false)
    }
console.log("Comparative>>>",data)
    let optionsEmployee = ()=>{
        if(userList&&userList.length>0){
          return userList.map((e,i)=>
          (
            {"label":`${e.firstName} ${e.lastName}`,"value":`${e.id}`,"id":e.id}
          ))
        }
    }
    let userOptions  = optionsEmployee()
    userOptions&&userOptions.unshift({label:"Select All",value:"Select All"})

    let selectedValues = userOptions&&JSON.parse(localStorage.getItem("AGENT"))?.userIds?.length==Object.keys(userOptions).length-1?[{label:"Select All",value:"Select All"}]: JSON.parse(localStorage.getItem("AGENT"))?.userIds?.map(item=>{return userOptions?.filter(d=>{return d.value==item})[0]})?.filter(item =>item)

    useEffect(()=>{
        getDateAcronym()
        getUserList()
    },[])

    useEffect(()=>{
        setLoader(true)
        if(defaultFilteredData?.id?.length>0){
            localStorage.setItem("AGENT",JSON.stringify({
                "pageSelected":localData?.pageSelected,
                "analysis":localData?.analysis?localData.analysis:analysis,
                "productivity":localData?.productivity,
                "fromD":localData&&localData.fromD?localData.fromD:defaultFilteredData.fromDate,
                "toD":localData&&localData.toD?localData.toD:defaultFilteredData.toDate,
                "agentDetailCOE":localData?.agentDetailCOE,
                "selectedRange":date?date:localData?.selectedRange,
                "selectedDate":filteredData?.dateName?filteredData?.dateName:localData?.selectedDate,
                "userIds":localData?.userIds?localData?.userIds:defaultFilteredData.id,
                "userNames":localData?.userNames?localData.userNames:[{label:"Select All",value:"Select All"}],
                "empId":localData?.employeeId,
                "empName":localData?.user,
                "role":localData?.role,
                "designation":localData?.designation,
                "teamCOE":localData?.teamCOE,
                "type":localData?.type,
                "callsFrom":localData?.callsFrom,
                "callsTo":localData?.callsTo,
                "aht":localData?.aht,
                "callScore":localData?.callScore,
                "pitchScore":localData?.pitchScore,
                "customerName":localData?.customerName,
                "customerMobile":localData?.customerMobile,
                "fileName":localData?.fileName,
                "sellerROS":localData?.sellerROS,
                "sellerName":localData?.sellerName,
                "selectedSeller":localData?.selectedSeller,
                "cScore":localData?.cScore,
                "callHealth":localData?.callHealth,
                "callDuration":localData?.callDuration,
                "words":localData?.words,
                "moment":localData?.moment,
                "momentId":localData?.momentId,
                "pitch":localData?.pitch,
                "prob":localData?.prob,
                "opportunity":localData?.opp,
                "urgency":localData?.urgency,
                "coe":localData?.coe,
                "selectedCoeRange":localData?.selectedCoeRange,
                "selectedCOE":localData?.selectedCOE,
                "momentBucket":localData?.momentBucket,
                "momentBucketId":localData?.momentBucketId,
                "frequency":localData?.frequency,
                "reportType":localData?.reportType
            }))
            getComparativeData()
        }
    },[defaultFilteredData])

    const createExcel = () => {
        clientService.getExcelFile('COMPARATIVE_ANALYSIS');
    }


    return(
        <>
        <div className="page-wrapper">
            <div className="page-content">
            {loader?
                <div className="loader-container">
                    <Loading/>
                </div>:
                <Row>
                    <Col lg={12} className="mb-2">
                        <h2 className="dashboard-headings d-inline">Comparative Analysis</h2>
                    </Col>
                    <Col lg={12}>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <Card.Body>
                                        <div class="mb-3">
                                            <label class="form-label ">Employee</label>
                                            <Autocomplete
                                                multiple
                                                id="tags-outlined"
                                                filterSelectedOptions
                                                isOptionEqualToValue={(option,value)=>option.label===value.label}
                                                onChange={handleAgentChange}
                                                getOptionDisabled = {option=>{
                                                    if(selectedAgent?.some(opt=>opt.label==='Select All')){
                                                      return true
                                                    }else if(selectedAgent && selectedAgent.length > 0 && option.label === 'Select All'){
                                                        return true
                                                      }
                                                }}
                                                options={userOptions||[]}
                                                renderOption={(props, option) => {
                                                    return (
                                                      <li {...props} key={option.id}>
                                                        {option.label}
                                                      </li>
                                                    );
                                                  }}
                                                getOptionLabel={(option) => option?.label} 
                                                defaultValue={selectedValues}
                                                renderInput={(params) => (
                                                    <TextField
                                                    {...params}
                                                    placeholder="Employees"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="ActiveLog-Date d-flex flex-xl-fill">
                                            <div className="d-flex align-items-baseline">
                                                <label className="form-label me-1">Date</label>
                                                
                                                <Select value={date} id="inputRole" className="br025" name="saledateSelected" onChange={handleChange} sx={{width:200}}>
                                                    {dates?dates.map(item=>{return(<MenuItem  key={item.id} value={item.id}>{item.name}</MenuItem>)}):''}
                                                </Select>
                            
                                                {customInput?<div className="call-fillter-date dasbard-comp-setting ms-1">
                                                    <div className="input-box form-group">
                                                        <label className="col-form-label mx-1">From:</label>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            inputFormat="DD-MM-YYYY"
                                                            value={startDate}
                                                            name="startDate"
                                                            onChange={handleStartDate}
                                                            disableOpenPicker
                                                            open={opendp}
                                                            onOpen={() => setOpendp(true)}
                                                            onClose={() => setOpendp(false)}
                                                            renderInput={(params) => (
                                                            <TextField {...params} onClick={()=> setOpendp(true)} />
                                                            )}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                    <div className="input-box form-group">
                                                        <label className="col-form-label mx-1">To:</label>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            inputFormat="DD-MM-YYYY"
                                                            value={endDate}
                                                            name="endDate"
                                                            onChange={handleEndDate}
                                                            disableOpenPicker
                                                            open={opendp1}
                                                            onOpen={() => setOpendp1(true)}
                                                            onClose={() => setOpendp1(false)}
                                                            renderInput={(params) => (
                                                            <TextField {...params} onClick={()=> setOpendp1(true)} />
                                                            )}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>: ''}
                                            </div>
                                            <div className="d-flex align-items-baseline justify-content-end ms-2">
                                                <label className="form-label me-1">Analysis</label>
                                                
                                                <Select value={analysis} id="inputRole" className="date-select br025" name="saledateSelected" onChange={handleAnalysis}>
                                                    {optionsComparitive&&optionsComparitive.map(item=>{
                                                        return <MenuItem value={item.value}>{item.displayName}</MenuItem>
                                                    })}
                                                </Select>
                                            </div>
                                            <button type="submit" className="btn px-4 lh-base update-btn insight-button" onClick={onSubmitHandler}>Apply</button>
                                            <button type="submit" className="btn px-4 lh-base update-btn" onClick={onResetHandler}>Reset</button>
                                        </div>
                                        <div className='d-flex mt-4' style={{marginLeft:'1rem'}}>
                                            { isExcelPdfButton && (
                                              <>
                                                {' '}
                                                <Button
                                                  variant='outline-secondary fw-normal excel font-14'
                                                  onClick={createExcel}
                                                >
                                                  Excel
                                                </Button>
                                                {' '}
                                              </>
                                            )}
                                        </div>
                                        <div className="CompAnalysis-section">
                                            <ComparativeAnalysisGraph loader={loader} data={data} analysis={analysis} def={def} event={event}/>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
            </div>
        </div>
        </>
    )
}