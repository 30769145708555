import React from "react"
import "../../Profile/profile.css"
import Loading from "../Loading"
export default function MemberDetail(props){
    return(
    <>
        {props.loader ? (
            <div className="loader-container">
                <Loading variant="dark" />
            </div>
            ) :
            <div>
                <div className="account-content" id="myprofiledetails">
                    <div className="d-flex flex-column align-items-center text-center">
                        <div className="profile-letter" style={{textTransform:"uppercase"}}>
                            
                            <h3>
                                {(props.agentData.firstName).charAt(0)}{(props.agentData.lastName).charAt(0)}
                            </h3>
                        </div>
                        <div className="mt-3">
                            <p className="text-secondary mb-1 account-email-id">
                                {props.agentData.email}
                                </p>
                        </div>
                    </div>
                    <div className="row w-100 m-0">
                        <div className="col-6 ps-0">
                            <ul className="list-group list-group-flush account-list my-2">
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap" key='123'>
                                    <h6 className="mb-0 "><i className="lni lni-mobile"></i>Mobile Number</h6>
                                    <span className="text-secondary">
                                    {
                                        props.agentData.userPhoneNumbers.map((num)=>(  
                                        num.map(numList=>(
                                            
                                                numList.type== "MOBILE" ?  numList.mobileNumber:null 
                                            
                                        )) 
                                        )) 
                                    }
                                    </span> 
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap" key='124'>
                                    <h6 className="mb-0"><i className="lni lni-phone-set"></i>Phone Number</h6>
                                    <span className="text-secondary">
                                    {
                                        props.agentData.userPhoneNumbers.map((pNum)=>(  
                                            pNum.map(pNumList=>(
                                                
                                                pNumList.type== "LANDLINE" ?  pNumList.mobileNumber:null 
                                                
                                            )) 
                                        )) 
                                    }
                                    </span> 
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap" key='3'>
                                    <h6 className="mb-0"><i className="lni lni-envelope"></i>Secondary Email </h6>
                                    <span className="text-secondary"></span>    
                                    {
                                        props.agentData.secEmail.map((emailList)=>(
                                            emailList.map(secList=>(
                                                <span className="text-secondary"> {secList.email} </span>
                                            ))
                                        
                                        ))
                                    }
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 pe-0">
                            <ul className="list-group list-group-flush account-list my-2">
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap" key='4'>
                                    <h6 className="mb-0 "><i className="fadeIn animated bx bx-user-plus"></i>Manager</h6>
                                    <span className="text-secondary"> 
                                        <a type="button" 
                                        // onClick={getManagerData}
                                        >{props.agentData.manager?props.agentData.manager:"NA"}</a>
                                    </span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap" key='6'>
                                    <h6 className="mb-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-key key-role"><path
                                            d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                                        </svg>
                                        Role
                                    </h6>
                                    <span className="text-secondary">
                                        {props.agentData.role?props.agentData.role:"NA"}
                                    </span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap" key='4'>
                                    <h6 className="mb-0 "><i className="text-primary-blue me-1 lni lni-network"></i>Team</h6>
                                    <span className="text-secondary"> 
                                        {/* <a type="button" ></a> */}
                                        {/* // onClick={getManagerData} */}
                                        
                                        {props.agentData.manager?props.agentData.manager:"NA"}
                                        {/* </a> */}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 pe-0">
                            <ul className="list-group list-group-flush account-list my-2 w-50 mx-auto">
                                {
                                    props.agentData.coe.map((coeList)=>(
                                    coeList.map(coesList=>(
                                        <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap" key={coesList.name}>
                                        <h6 className="mb-0"><i className="lni lni-users"></i>COE </h6>
                                            <span className="text-secondary">{coesList.name}</span>
                                            </li>
                                        ))
                                    
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
    )
}