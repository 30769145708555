import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import ShowToast from "../../Components/helper/ShowToast";
import ResetPassword from './ResetPassword';
import background from '../../assets/images/login-images/sign-in.png';
import odio_logo_blue from '../../assets/images/odio_logo_blue.png';
import './login.css'
import Loading from '../Commons/Loading';
import { connect } from 'react-redux'; 
import { signIn} from '../../redux-container/login/action';

function Login(props) {
    const [inputs, setInputs] = useState({
        email: '',
        password: '',
    });
    const [formError, setFormErrors] = useState({});

    const [passwordShown, setPasswordShown] = useState(false);
    const { email, password } = inputs;
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [pshow, setpshow] = useState(false)
    const history = useHistory();
    const [isAuth, setIsAuth] = useState(false)
    let btnRef = useRef();

    const errors = {};
    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
        btnRef.current.removeAttribute("disabled");
    }
    
    useEffect(() =>{
        setLoading(props.loader);
    },[props.loader])

    
    const validate = (values) => {

        if (!values.email) {
            errors.email = "Enter your login id (email)."
        }
        if (!values.password) {
            errors.password = "Enter your password."
        }
        return errors;
    }
    function handleFocusEmail(){
        formError.email="";
    }
    function handleFocusPass(){
        formError.password="";
    }
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
        setpshow(!pshow);
    };

   function handleSubmit(e) {
        e.preventDefault();
        validate(inputs)
        setMessage('')
        setFormErrors(errors)
        if(btnRef.current){
            btnRef.current.setAttribute("disabled", "disabled");
        }
        
        if (Object.keys(errors).length == 0) {
        props.signIn(inputs);
        }
       }

    return (
        <>
            <div className="wrapper signin-content">
                <div className="authentication-header header-sign" style={{ backgroundImage: `url(${background})` }} />
                <div className="section-authentication-signin d-flex justify-content-center my-5 my-lg-0">
                    <div className="container-fluid">
                        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                            <div className="col mx-auto">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="p-4 rounded">
                                            <div class="text-center mb-4 p-0">
                                                <img src={odio_logo_blue} width="149" alt="Odio Logo" />
                                            </div>
                                            <div className="form-body">

                                                <form className="row g-3" onSubmit={handleSubmit}>

                                                    <div className="col-12">
                                                        <label htmlFor="inputEmailAddress" className="form-label">Login Id</label>
                                                        <div className="input-group"> <span className="input-group-text bg-transparent"><i className="lni lni-envelope" /></span>
                                                            <input type="email" name="email" value={email} onFocus={handleFocusEmail} onChange={handleChange} className="form-control form-control-lg" id="inputEmail" placeholder="user@yourdomain.com" />
                                                        </div>
                                                        <p style={{ color: "red", height: "18px" }} className='my-1 mb-0 lh-1 font-12'>{formError.email}</p>
                                                    </div>
                                                    
                                                    <div className="col-12 mt-0">
                                                        <label htmlFor="inputChoosePassword" className="form-label">
                                                            Password</label>
                                                        <div className="input-group" id="show_hide_password">
                                                            <span className="input-group-text bg-transparent"><i className="bx bxs-lock" /></span>
                                                            <input type={passwordShown ? "text" : "password"} name="password" value={password} onFocus={handleFocusPass} onChange={handleChange} className="form-control form-control-lg border-end-0" id="inputChoosePassword"  placeholder="Password" />
                                                            <a onClick={togglePassword} tabindex="-1" href="javascript:;" className="input-group-text bg-transparent">{pshow ? <i className="bx bx-show"></i> : <i className="bx bx-hide"></i> }</a>

                                                        </div>
                                                        <p style={{ color: "red", height:"18px" }} className='my-1 mb-0 lh-1 font-12'>{(message) ? message : formError.password}</p>
                                                    </div>
                                                    <div className="col-md-12 text-end sf-btn mt-0">
                                                        <Link to="/forgot_password">Forgot Password ?</Link>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="d-grid">
                                                            <button type="submit" className="btn btn-lg sign-btn" ref={btnRef}>{loading ? <Loading variant="light"/> : <><i className="bx bxs-lock-open" /> Sign in</>}</button>
                                                            
                                                        </div>
                                                    </div>
                                                </form>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*end row*/}
                    </div>
                </div>
                <footer className="bg-white shadow-sm border-top p-2 text-center fixed-bottom">
                    <p className="mb-0">Copyright © 2024 - VoiceIO Artificial Intelligence Pvt Ltd</p>
                </footer>

            </div>

        </>);

}

const mapStateToProps = (state) =>{
    return{
        authenticate : state.login.loggedIn,
        loader: state.login.loader
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        signIn: (data) => {dispatch(signIn(data))},
    }
}

export default connect(mapStateToProps,mapDispatchToProps) (Login);