import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from "react-router-dom";
import Loading from '../Commons/Loading'
import PaginationAlter from '../Commons/Table/PaginationAlter'
import NodataFound from '../Commons/NoDataFound'
import phone from "../../assets/images/callList-images/phone.svg"
import calender from "../../assets/images/callList-images/calendar.svg"
import time from "../../assets/images/callList-images/timer.svg"
import seller from "../../assets/images/callList-images/seller.svg"
import user from "../../assets/images/callList-images/user.svg"
import { Card } from 'react-bootstrap'
import { apiCall } from '../Services/Interceptor';
import LiveAssistSidebar from './LiveAssistSidebar';
import './liveAssist.css';
import LiveCallTranscript from './LiveCallTranscript';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Badge, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ theme}) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700' ,
    color: '#44b700',
    boxShadow: `0 0 0 2px #ecf0f3`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const LiveAssist = () => {
    const liveAssistSidebarRef = useRef(null)
    const [callLists, setCallLists] = useState([])
    const [loader, setLoader] = useState(false)
    const [availablePages, setAvailablePages] = useState("")
    const [currentPage, setCurrentPage] = useState("")
    const [filterData,setFilterData] = useState([])
    const [defaultData, setDefaultData] = useState([])
    const [callData, setcallData] = useState('')
    const [detailLoader, setDetailLoader] = useState(false)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const history = useHistory()
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [socket, setSocket] = useState(null);
    

    const storedData = JSON.parse(localStorage.getItem("AGENT"))
    
    let role = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole
    const callBack = (e)=>{
      setCurrentPage(e)
    }
  
    const defaultConvFilter = (val)=>{
      setDefaultData(val)
    }

    const loaderFalse = ()=>{
      setLoader(false)
    }
  
    const loaderTrue = ()=>{
      setLoader(true)
    }
  
    console.log(history.location.state)
  
  function callList(){
    // filterData?.loader?props.loaderTrue():props.loaderTrue()
    loaderTrue()
    let val ={  
    "page":parseInt(localStorage.getItem("selectedLiveAssistPage"))?parseInt(localStorage.getItem("selectedLiveAssistPage")):1,
    "pageSize":5,  
    "callType":"SALES",
    "clientExternalId":JSON.parse(localStorage.getItem("USER_DETAIL"))?.externalId,
    "fromD":storedData&&storedData.fromD?storedData.fromD:defaultData.from, 
    "toD":storedData&&storedData.toD?storedData.toD:defaultData.to,
    "sort":"",
    "coeExternalIds":storedData?.coe,
    "coeName":storedData?.selectedCOE,
    "coeIds": storedData?.selectedCoeRange,
    }
  
    if(role === 'AGENT_ADMIN')
      val.coeExternalId = storedData?.coe[0]
    
      apiCall.post('/odio/api/call/live_assist',val).then(response => {
      
      console.log("hihhhhhhhhhhhhhhh",response)
      setCallLists(response? response.data.data.response: []) 
      setAvailablePages(response?.data.data.availablePages)
      loaderFalse()
    }).catch(error => {
      console.log("Error:", error);
      setCallLists([]);
      loaderFalse();
    });
      // let data = response.data.response.map(item=>{return
        
      //     item.callId:item.audioRecordingUrl}
        
      // })
  }
  
  useEffect(()=>{
    callList()
  },[filterData,currentPage])
  
  
  // useEffect(()=>{
  //   callList()
  // },[currentPage])
  
  // useEffect(()=>{
  //   props.selectedData(filterData)
  //   props.defaultData(defaultData)
  //   props?.callListData(callLists)
  // },[filterData,defaultData,callLists])
  
  const conversion = (duration)=>{
  
    console.log(duration)
    let hh = duration?.split(":")[0]
    let mm=duration?.split(":")[1]
    let ss = duration?.split(":")[2]
    console.log(ss)
    let text = ""
    if(hh>0){
      text = `${hh} Hrs. ${mm} Min. ${ss} Sec.`
    }
    else if(mm>0){
      text = text + `${mm} Min. ${ss} Sec.`
    }
    else if(ss>0){
      text  = text + `${ss} Sec.`
    }
    // let text  = minutes + sec
    console.log(text)
    return text
  }

  const toggleSidebar = ()=>{
    if (liveAssistSidebarRef.current) {
      liveAssistSidebarRef.current.toggleSideBar();
    }
  }
   
  
  const clickHandler = (data)=>{
    toggleSidebar()
    setcallData(data)
  }

  const openPopup = () => {
    setIsPopupOpen(true);
    if (!socket) {
      const newSocket = new WebSocket('wss://livesttsocket.odioiq.com/serve');
      newSocket.onopen = () => {
        newSocket.send('{"event": "media"}');
      };
      newSocket.onmessage = (s) => {
        const data = JSON.parse(s.data);
        if (data.text !== "") {
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: data.text, intent: data.intent }
          ]);
        }
        newSocket.send('{"event": "media"}');
      };
      setSocket(newSocket);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    if (socket) {
      socket.close();
      setSocket(null);
    }
    setMessages([]);
  }
  
    
  return (
    <>
        <div className="page-wrapper pg-wrap-live">
            <div className="page-content pg-content-live" 
            style={{backgroundColor: '#ecf0f3'}}
              >
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
            <div className="col-12 mb-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-inline">
                    <h2 className="dashboard-headings d-inline">
                      Live Assist{" "}
                    </h2> 
                    {storedData?<span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`CALL DATE: FROM(${storedData&&storedData.fromD}) TO(${storedData&&storedData.toD})`}<a href="javascript:;"></a></span>:""}
                    {storedData?typeof(storedData.selectedCOE)!='string'?storedData?.selectedCOE?.map(item=>{return (<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`COE: ${item}`}<a href="javascript:;"></a></span>)}):<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`COE: ${storedData?.selectedCOE}`}<a href="javascript:;"></a></span>:""}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-4 salesCallCard" style={{marginTop:'10px'}}> 
            <>
                {loader ? (
                    <div className="loader-container">
                        <Loading variant="dark" />
                    </div>
                ) : (
                    <>
                    {/* <Accordion className='custom-accordion-live' style={{ alignContent:'center'}}> 
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="custom-accordion-summary-live"
                        style={{height:'60px', fontSize:'18px',backgroundColor:'#ecf0f3'}}
                      >
                        Live Call
                      </AccordionSummary>
                      <AccordionDetails className="custom-accordion-details-live" style={{}}> */}
                      <Card className='live-card' style={{ marginBottom: "1rem" }}>
                              <Card.Body className="salesCallCard">
                                <div className="user">
                                  <div className="caller">
                                    <div className="name">
                                    <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                                      <Avatar sx={{height:'28px',width:'28px'}}/>
                                    </StyledBadge>
                                      {/* <span>
                                        <img src={user} alt="" />
                                      </span> */}
                                      <span className="text">{callLists[0]?.customerName}</span>
                                    </div>
                                    <div className="rank" style={{ cursor: "pointer" }}>{`#${callLists[0]?.phone}`}</div>
                                  </div>
                                  <div className="callCount">
                                  </div>
                                  <div className="score">
                                    <div class="lds-ripple"><div></div><div></div></div>
                                  </div>
                                </div>
                                <hr />
                                <div className="seller">
                                  <div className="name">
                                    <span className="icon">
                                      <img src={seller} alt="" />
                                    </span>
                                    {`${callLists[0]?.sellerName} (${callLists[0]?.employeeId})`}
                                  </div>
                                  <div className="number">
                                    <span className="icon">
                                      <img src={phone} alt="" />
                                    </span>
                                    {callLists[0]?.phone}
                                  </div>
                                  <div className="date">
                                    <span className="icon">
                                      <img src={calender} alt="" />
                                    </span>
                                    {callLists[0]?.date}
                                  </div>
                                  <div className="time">
                                    <span className="icon">
                                      <img src={time} alt="" />
                                    </span>
                                    {conversion(callLists[0]?.talkDuration)}
                                  </div>
                                  <div>
                                    <button className="groupCall" onClick={openPopup}>
                                      Nudges
                                    </button>
                                    <button className="groupCall" onClick={() => { clickHandler(callLists[0]) }}>
                                      View
                                    </button>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                      {/* </AccordionDetails>
                    </Accordion> */}
                    <Accordion className='custom-accordion' style={{ alignContent:'center'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="custom-accordion-summary"
                        style={{height:'60px', fontSize:'18px',backgroundColor:'#ecf0f3'}}
                      >
                        Non Live Calls
                      </AccordionSummary>
                      <AccordionDetails className="custom-accordion-details">
                      {callLists != '' && callLists.length > 0 ? callLists.map((item, id) => {
                        return (
                          <>
                            <Card className='live-card' style={{ marginBottom: "1rem" }}>
                              <Card.Body className="salesCallCard">
                                <div className="user">
                                  <div className="caller">
                                    <div className="name">
                                      <span>
                                        <img src={user} alt="" />
                                      </span>
                                      <span className="text">{item?.customerName}</span>
                                    </div>
                                    <div className="rank" style={{ cursor: "pointer" }}>{`#${item.phone}`}</div>
                                  </div>
                                  <div className="callCount">
                                  </div>
                                  <div className="score">
                                  </div>
                                </div>
                                <hr />
                                <div className="seller">
                                  <div className="name">
                                    <span className="icon">
                                      <img src={seller} alt="" />
                                    </span>
                                    {`${item.sellerName} (${item.employeeId})`}
                                  </div>
                                  <div className="number">
                                    <span className="icon">
                                      <img src={phone} alt="" />
                                    </span>
                                    {item.phone}
                                  </div>
                                  <div className="date">
                                    <span className="icon">
                                      <img src={calender} alt="" />
                                    </span>
                                    {item.date}
                                  </div>
                                  <div className="time">
                                    <span className="icon">
                                      <img src={time} alt="" />
                                    </span>
                                    {conversion(item?.talkDuration)}
                                  </div>
                                  <div>
                                    <button className="groupCall" onClick={() => { clickHandler(item) }}>
                                      View
                                    </button>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </>
                        )
                      }) : <Typography>No Data to Show</Typography>
                      }
                      {callLists.length>0 && availablePages>0?
                        <PaginationAlter
                          callBack={callBack}
                          availablePages = {availablePages}
                          loaderTrue = {loaderTrue}
                          loaderFalse = {loaderFalse}
                        />:""
                      }
                      </AccordionDetails>
                    </Accordion>
                    </>
                  )}
                  <LiveCallTranscript
                    isPopupOpen={isPopupOpen}
                    closePopup={closePopup}
                    messages={messages}
                  />
                  <LiveAssistSidebar 
                    toggleSidebar={toggleSidebar}
                    ref={liveAssistSidebarRef}
                    callData= {callData}
                  />
            </>
            </div>
          </div>
            </div>
        </div>
    </>
  )
}

export default LiveAssist