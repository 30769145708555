import Filter from "../Filter/Filter";
import ReactTable from "../Commons/Table/ReactTable";
import { SETTINGS_COMPONENT_TYPE } from "../../Constants/Enum";
import Loading from "../Commons/Loading";
import { getTeam, shortTeam } from "../../redux-container/team/action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as React from 'react'
import { useHistory } from "react-router-dom";
import NodataFound from "../Commons/NoDataFound";

function TeamColumns(props) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const employeeId = urlParams.get('employeeId')
  const employeeName = urlParams.get('employeeName')
  const columns = urlParams.get('column')
  const agentCallScore = urlParams.get('agentCallScore')?.split("-").map(Number)
  const aht = urlParams.get('aht')?.split("-").map(Number)

  console.log(employeeId,employeeName,agentCallScore,aht,"teamparams")
  //const clintType = "SALE"
  const [userID, setUserID] = useState(JSON.parse(localStorage.getItem('USER_DETAIL'))?.userId)
  const history = useHistory()
  let firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  let crrDate = new Date()
  
  const resetUserId = () => {
    setUserID(JSON.parse(localStorage.getItem('USER_DETAIL'))?.userId);
  }
  let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.team?.columns

  const colorCode = (d)=>{
    // if(d<=100&&d>90){
    //   return(
    //     <span
    //       className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-success"
    //       } `}
    //     >
    //       {" "}
    //       {`${d}%`}{" "}
    //     </span>
    //   )
    // }
    // else if(d<=90&&d>80){
    //   return(
    //     <span
    //       className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-warning"
    //       } `}
    //     >
    //       {" "}
    //       {`${d}%`}{" "}
    //     </span>
    //   )
    // }
    // else if(d<=80&&d>=0){
    //   return(
    //     <span
    //       className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-danger"
    //       } `}
    //     >
    //       {" "}
    //       {`${d}%`}{" "}
    //     </span>
    //   )
    // }
    if(d?.row?.original?.callScoreRating == "Green"){
      return (
        <span
          className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-success"
          } `}
        >
          {" "}
          {`${d.value}%`}{" "}
        </span>
      )
    }
    else if(d?.row?.original?.callScoreRating == "Red"){
      return (
        <span
          className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-danger"
          } `}
        >
          {" "}
          {`${d.value}%`}{" "}
        </span>
      )
    }
    else if(d?.row?.original?.callScoreRating == "Amber"){
        return (
          <span
            className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-warning"
            } `}
          >
            {" "}
            {`${d.value}%`}{" "}
          </span>
        )
    }
    else{
      return (
        <span
          className={`rounded-pill btn fw-normal font-13 ${"btn-outline-secondary"
          } `}
        >
          {" "}
          {`${d.value}%`}{" "}
        </span>
      )
    }
  }

  const saleColumn = [
    {
      Header: "Employee Id", 
      accessor: "employeeId",
    },
    {
      Header: "Employee",
      accessor: (d)=> `${d.firstName} ${d.lastName}`,
      Cell: (d) => (
        <div style={{textTransform:"capitalize",cursor:"pointer"}} className="teamHover" onClick={()=>clickHandler(d.cell.row.original.id, d.cell.row.original.externalId, d.cell.row.original.mobileNumber,d.cell.row.original)}>
          {`${d.cell.row.original.firstName} ${d.cell.row.original.lastName}`}
        </div>
      ),
    },

    {
      Header: "Calls",
      accessor: "groupcalls_calls",
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Designation",
      accessor: (d) => `${d.designation ? d.designation : "-"}`,
    },
    {
      Header: "Caller",
      accessor: (d) => (
        <p className="text-center mb-0">
          {d.caller && d.caller == 'Yes' ? (
            <i className="lni lni-checkmark"></i>
          ) : (
            "-"
          )}
        </p>
      ),
      disableSortBy: true,
    },
    {
      Header: "AHT (sec)",
      accessor: (d)=>`${d.aht?(d.aht):0}`,
      Cell: (d)=>(`${d.cell.row.original.aht?(d.cell.row.original.aht):0}`)
    },
    {
      Header: "Rating",
      accessor: (d) => (
        <p className="text-center mb-0">
          {d.pitchScore ? (
            <span
              class={`badge rounded-pill btn fw-normal font-13 ${
                d.callScoreRating=='Red' ? "text-danger" : d.callScoreRating=='Amber'?"text-warning": d.callScoreRating=='Green'?"text-success" : "text-info"
              } `}
            >
              {" "}
              <i className="lni lni-star-filled"></i>{" "}
            </span>
          ) : (
            "-"
          )}
        </p>
      ),
    },
  ];
  const supportColumn = [
    {
      Header: "Employee Id",
      accessor: "employeeId",
    },
    {
      Header: "Employee",
      accessor: (d)=> `${d.firstName} ${d.lastName}`,
      Cell: (d) => (
        <div style={{textTransform:"capitalize",cursor:"pointer"}} className="teamHover" onClick={()=>clickHandler(d.cell.row.original.id,d.cell.row.original.externalId, d.cell.row.original.mobileNumber,d.cell.row.original)}>
          {`${d.cell.row.original.firstName} ${d.cell.row.original.lastName}`}
        </div>
      ),
    },

    {
      Header: "Calls",
      accessor: "groupcalls_calls",
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Designation",
      accessor: (d) => `${d.designation ? d.designation : "-"}`,
    },
    {
      Header: "Caller",
      accessor: (d) => (
        <p className="text-center mb-0">
          {d.caller && d.caller == 'Yes' ? (
            <i className="lni lni-checkmark"></i>
          ) : (
            "-"
          )}
        </p>
      ),
      disableSortBy: true,
    },
    {
      Header: "AHT (sec)",
      accessor: (d)=>`${d.aht?(d.aht/d.callCount).toFixed(2):0}`,
      Cell: (d)=>(`${d.cell.row.original.aht?(d.cell.row.original.aht).toFixed(2):0}`),
    },
    {
      Header: "Rating",
      accessor: (d) => (
        <p className="text-center mb-0">
          {d.pitchScore ? (
            <span
              class={`badge rounded-pill btn fw-normal font-13 ${
                d.callScoreRating=='Red' ? "text-danger" : d.callScoreRating=='Amber'?"text-warning": d.callScoreRating=='Green'?"text-success" : "text-info"
              } `}
            >
              {" "}
              <i className="lni lni-star-filled"></i>{" "}
            </span>
          ) : (
            "-"
          )}
        </p>
      ),
    },
  ];
  const bothColumn = [
    {
      Header: "Employee Id",
      accessor: "employeeId",
    },
    {
      Header: "Employee",
      accessor: (d)=> `${d.firstName} ${d.lastName}`,
      Cell: (d) => (
        <div style={{textTransform:"capitalize",cursor:"pointer"}} className="teamHover" onClick={()=>clickHandler(d.cell.row.original.id,d.cell.row.original.externalId, d.cell.row.original.mobileNumber,d.cell.row.original)}>
          {`${d.cell.row.original.firstName} ${d.cell.row.original.lastName}`}
        </div>
      ),
    },

    {
      Header: "Calls",
      accessor: "callCount",
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Designation",
      accessor: (d) => `${d.designation ? d.designation : "-"}`,
    },
    {
      Header: "Caller",
      accessor: (d) => (
        <p className="text-center mb-0">
          {d.caller && d.caller == 'Yes' ? (
            <i className="lni lni-checkmark"></i>
          ) : (
            "-"
          )}
        </p>
      ),
      disableSortBy: true,
    },
    {
      Header: "AHT (sec)",
      accessor: (d)=>`${d.aht?(d.aht):0}`,
      Cell: (d)=>(`${d.cell.row.original.aht?(d.cell.row.original.aht):0}`),
    },
    {
      Header: "Rating",
      accessor: (d) => (
        <p className="text-center mb-0">
          {d.pitchScore ? (
            <span
              class={`badge rounded-pill btn fw-normal font-13 ${
                d.callScoreRating=='Red' ? "text-danger" : d.callScoreRating=='Amber'?"text-warning": d.callScoreRating=='Green'?"text-success" : "text-info"
              } `}
            >
              {" "}
              <i className="lni lni-star-filled"></i>{" "}
            </span>
          ) : (
            "-"
          )}
        </p>
      ),
    },
  ];

  if(userDetail&&userDetail.includes("Call Score")){
    saleColumn.splice(7,0,{
      Header: "Call Score",
      accessor: (de)=>`${de.callScore}`,
      Cell: (d) => (
        <div className="text-center mb-0">
          {d.value ?(
            colorCode(d)
          ) : (
            colorCode(0)
          )}
        </div>
        )
    })
    supportColumn.splice(7,0,{
      Header: "Call Score",
      accessor: (de)=>`${de.callScore}`,
      Cell: (d) => (
        <div className="text-center mb-0">
          {d.value ?(
            colorCode(d)
          ) : (
            colorCode(0)
          )}
        </div>
        )
    })
    bothColumn.splice(7,0,{
      Header: "Call Score",
      accessor: (de)=>`${de.callScore}`,
      Cell: (d) => (
        <div className="text-center mb-0">
          {d.value ?(
            colorCode(d)
          ) : (
            colorCode(0)
          )}
        </div>
        )
    })
  }
  else if(userDetail&&userDetail.includes("Pitch Score")){
    saleColumn.splice(7,0,{
      Header: "Pitch Score",
      accessor: (de)=>`${de.callScore}`,
      Cell: (d) => (
        <div className="text-center mb-0">
          {d.value ?(
            colorCode(d)
          ) : (
            colorCode(0)
          )}
        </div>
        )
    })
    supportColumn.splice(7,0,{
      Header: "Pitch Score",
      accessor: (de)=>`${de.callScore}`,
      Cell: (d) => (
        <div className="text-center mb-0">
          {d.value ?(
            colorCode(d)
          ) : (
            colorCode(0)
          )}
        </div>
        )
    })
    bothColumn.splice(7,0,{
      Header: "Pitch Score",
      accessor: (de)=>`${de.callScore}`,
      Cell: (d) => (
        <div className="text-center mb-0">
          {d.value ?(
            colorCode(d)
          ) : (
            colorCode(0)
          )}
        </div>
        )
    })
  }
  else if(userDetail&&userDetail.includes("Call Score")&&userDetail.includes("Pitch Score")){
    saleColumn.splice(7,0,{
      Header: "Call Score",
      accessor: (de)=>`${de.callScore}`,
      Cell: (d) => (
        <div className="text-center mb-0">
          {d.value ?(
            colorCode(d)
          ) : (
            colorCode(0)
          )}
        </div>
        )
    },
    {
      Header: "Pitch Score",
      accessor: (de)=>`${de.callScore}`,
      Cell: (d) => (
        <div className="text-center mb-0">
          {d.value ?(
            colorCode(d)
          ) : (
            colorCode(0)
          )}
        </div>
        )
    })
    supportColumn.splice(7,0,{
      Header: "Call Score",
      accessor: (de)=>`${de.callScore}`,
      Cell: (d) => (
        <div className="text-center mb-0">
          {d.value ?(
            colorCode(d)
          ) : (
            colorCode(0)
          )}
        </div>
        )
    },
    {
      Header: "Pitch Score",
      accessor: (de)=>`${de.callScore}`,
      Cell: (d) => (
        <div className="text-center mb-0">
          {d.value ?(
            colorCode(d)
          ) : (
            colorCode(0)
          )}
        </div>
        )
    })
    bothColumn.splice(7,0,{
      Header: "Call Score",
      accessor: (de)=>`${de.callScore}`,
      Cell: (d) => (
        <div className="text-center mb-0">
          {d.value ?(
            colorCode(d)
          ) : (
            colorCode(0)
          )}
        </div>
        )
    },
    {
      Header: "Pitch Score",
      accessor: (de)=>`${de.callScore}`,
      Cell: (d) => (
        <div className="text-center mb-0">
          {d.value ?(
            colorCode(d)
          ) : (
            colorCode(0)
          )}
        </div>
        )
    })
  }

  const dispatch = useDispatch();
  const team = useSelector((state) => state.manage_team); 

  const clickHandler = (id,externalId,mobile,d)=>{
    if((d.role).toLowerCase() !== "agent"){
      setUserID(d.id)
      // history.push('/team')
    }else{
      history.push(`/team_member_detail?id=${id}&agentExternalId=${externalId}&phone=${mobile}`)
    }
    // if(fromD&&toD){
    //   history.push(`/team_member_detail?id=${id}&fromD=${fromD}&toD=${toD}`)
    // }
    // else{
    //   history.push(`/team_member_detail?id=${id}&fromD=${team.selectedData!=''||team.selectedData?team.selectedData.team_fromD:firstDay}&toD=${team.selectedData!=''||team.selectedData?team.selectedData.team_toD:crrDate}`)
    // }
    // history.push(`/team_member_detail?id=${id}&agentExternalId=${externalId}&phone=${mobile}`)
  }

  const [isDisable, setDisable] = useState(false)

  useEffect(() => {
    let firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let data = {"fromD" : `${firstDay.getDate()}-${firstDay.getMonth()+1}-${firstDay.getFullYear()}`, "toD": `${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`} 

    const fromD = JSON.parse(localStorage.getItem("AGENT"))?.fromD
    const toD = JSON.parse(localStorage.getItem("AGENT"))?.toD

    const storedData = JSON.parse(localStorage.getItem("AGENT"))

    if(!fromD && !toD ){
      dispatch(getTeam({"userId": userID, "fromD":storedData&&storedData.fromD?storedData.fromD:`${firstDay.getDate()}-${firstDay.getMonth()+1}-${firstDay.getFullYear()}`,"toD":storedData&&storedData.toD?storedData.toD:`${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`}))
      localStorage.setItem("AGENT",JSON.stringify({
      "pageSelected":storedData?.pageSelected,
      "analysis":storedData?.analysis,
      "productivity":storedData?.productivity,
      "fromD":storedData&&storedData.fromD?storedData.fromD:`${firstDay.getDate()}-${firstDay.getMonth()+1}-${firstDay.getFullYear()}`,
      "toD":storedData&&storedData.toD?storedData.toD:`${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`,
      "agentDetailCOE":storedData?.agentDetailCOE,
      "empId":storedData&&storedData.empId,
      "empName":storedData&&storedData.empName,
      "role":storedData&&storedData.role,
      "designation":storedData&&storedData.designation,
      "type":storedData&&storedData.type,
      "teamCOE":storedData&&storedData.teamCOE,
      "callsFrom":storedData&&storedData.callsFrom,
      "callsTo":storedData&&storedData.callsTo,
      "aht":storedData&&storedData.aht,
      "callScore":storedData&&storedData.callScore,
      "pitchScore":storedData&&storedData.pitchScore,
      "customerName":storedData?.customerName,
      "customerMobile":storedData?.customerMobile,
      "fileName":storedData?.fileName,
      "sellerROS":storedData?.sellerROS,
      "sellerName":storedData?.sellerName,
      "selectedSeller":storedData?.selectedSeller,
      "cScore":storedData?.cScore,
      "callHealth":storedData?.callHealth,
      "callDuration":storedData?.callDuration,
      "words":storedData?.words,
      "moment":storedData?.moment,
      "momentId":storedData?.momentId,
      "pitch":storedData?.pitch,
      "prob":storedData?.prob,
      "opportunity":storedData?.opp,
      "urgency":storedData?.urgency,
      "coe":storedData?.coe,
      "selectedCOE":storedData?.selectedCOE,
      "selectedCoeRange":storedData?.selectedCoeRange,
      "userIds":storedData?.userIds,
      "userNames":storedData?.userNames,
      "selectedDate":storedData?.selectedDate,
      "selectedRange":storedData?.selectedRange?storedData?.selectedRange:"",
      "momentBucket":storedData?.momentBucket,
      "momentBucketId":storedData?.momentBucketId,
      "frequency":storedData?.frequency,
      "reportType":storedData?.reportType
      }))
    }
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const columns = urlParams.get('column')
    const order = urlParams.get('order')
    if(columns){
      //"order":"ASCE",
      //"order":"DESC"
     dispatch(shortTeam({"column":columns, "order":order}));
    }
  }, [team]);

  useEffect(()=>{
    props.teamData(team.selectedData)
    props.selectedData(team.selectedData)
    props?.teamExcelData(team?.initialTeam)
  },[team.selectedData,team?.initialTeam])

  return (
    <>
      {team.loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
        ) : team.initialTeam != null &&team.initialTeam.length>0?(
          <>
            <div className="view-team-card-details">
              <div className="table-responsive dataTable-style react-table">
                <ReactTable
                  columns={props.accountType=="SALE"?saleColumn:props.accountType=="SUPPORT"?supportColumn:bothColumn}
                  componentType={SETTINGS_COMPONENT_TYPE.MANAGE_TEAM}
                  data={team.initialTeam}
                  isExcelPdfButton={true}
                  isDisable={team.Team.length<=0?true:false}
                  handlePageIndex={(e)=>{console.log()}}
                />
              </div>
            </div>
          </>
        ):
        <NodataFound/>
      }
      <Filter
        componentType="FILTER TEAM_TAB"
        data={team.initialTeam}
        roles={props.roles}
        coes={props?.coes}
        redirectedData = {{employeeId,employeeName,agentCallScore,aht}}
        userId={userID}
        columns={columns}
        resetUserId={resetUserId}
      />
    </>
  );
}
const mapStateToProps = (state) => {
    return {
      accountType: state.login.accountType,
     };
  };
 const mapDispatchToProps = (dispatch) => {
    return {
      shortTeam: (data) => {
          dispatch(shortTeam(data));
      },
    };
  }; 
  export default connect(mapStateToProps,mapDispatchToProps)(TeamColumns);
