import React, { useState, useEffect } from "react";
import AddNewEmpolyee from "./AddNewEmployee";
import ManageUsersColumns from "./ManageUsersColumns";
import Loading from "../../Commons/Loading";
import { connect } from "react-redux";
import EditUser from "./EditUser";
import coeService from "../../Services/coe.service"
import {USER_DETAIL} from "../../../Constants/constant"

function ManageUsers(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [editUserVisibility, setEditUserVisibility] = useState(false);
  const [userData, setUserData] = useState([]);
  const [coeOptions, setCoeOptions] = useState([])

  useEffect(() => {
    document.title = "Manage Users  - Odio"
  }, [])

  console.log(props?.loader)

  useEffect(()=>{
    let userRole = JSON.parse(localStorage.getItem(USER_DETAIL))?.userRole
    if(userRole!='AGENT'){
      coeService.getCOEList().then(res =>{
        console.log('coe list--', res);
        setCoeOptions(res?.data?.data)
        // this.setState({coeOptions:res?res.data.data:''})
      })
    }
    
  },[])

  return (
    <>
      {props.loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : isOpen ? (
        <AddNewEmpolyee setIsOpen={setIsOpen} />
      ) : editUserVisibility ? (
        <EditUser
          setEditUserVisibility={setEditUserVisibility}
          selectedRowData={userData} 
          coeOptions = {coeOptions}
        />
      ) : (
        <div className="manage-moment-main-table mt-3">
          <div className="d-flex top-fixed-box">
            <div className=" mb-2 text-sm-left myTeamLHeader">
              <h5 className="account-name account-id">Manage Users</h5>
            </div>
            <div className="nav-item add-coe-btn-top" role="presentation">
              <a
                type="button"
                className="nav-link btn account-edit-btn edit-account w-100 text-nowrap"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Add User
              </a>
            </div>
          </div>

          <div className="view-team-card-details">
            <ManageUsersColumns
              setEditUserVisibility={setEditUserVisibility}
              setUserData={setUserData}
              setCOETab= {props.setCOETab}
              coeOptions={coeOptions}
            />
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.manage_users.loader,
    filteredUserData: state.manage_users.filteredUserData,
  };
};

export default connect(mapStateToProps)(ManageUsers);
