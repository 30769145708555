import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDataState } from "../../Dashboard/DashboardSales/SalesDashboard/WordCloudApiState";

export default function PaginationAlter(props) {
  const [items, setItems] = useState([])
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedWordPage, setSelectedWordPage] = useState(1);
  const [selectedChatPage, setSelectedChatPage] = useState(1);
  const [selectedLiveAssistPage, setSelectedLiveAssistPage] = useState(1);
  const [notifPage, setNotifPage] = useState(1)
  const history = useHistory()
  const location = useLocation()
  useEffect(() => { props?.forPageWhenFilter && setSelectedPage(1) }, [props?.forPageWhenFilter])
  let storedData = JSON.parse(localStorage.getItem("AGENT"))
  let wordData = useDataState()

  const handlePageChange = (e) => {
    console.log(e.selected + 1)
    const selectedPageNumber = e.selected + 1;
    props.callBack(e.selected + 1)
    // localStorage.setItem("AGENT",JSON.stringify({
    // "pageSelected":e.selected+1,
    // "analysis":storedData?.analysis,
    // "productivity":storedData?.productivity,
    // "fromD":storedData?.fromD,
    // "toD":storedData.toD,
    // "agentDetailCOE":storedData?.agentDetailCOE,
    // "userIds":storedData?.userIds,
    // "userNames":storedData?.userNames,
    // "empId":storedData?.employeeId,
    // "empName":storedData?.user,
    // "role":storedData?.role,
    // "designation":storedData?.designation,
    // "type":storedData?.type,
    // "teamCOE":storedData?.teamCOE,
    // "callsFrom":storedData?.callsFrom,
    // "callsTo":storedData?.callsTo,
    // "aht":storedData?.aht,
    // "callScore":storedData?.callScore,
    // "pitchScore":storedData?.pitchScore,
    // "customerName":storedData?.customerName,
    // "customerMobile":storedData?.customerMobile,
    // "fileName":storedData?.fileName,
    // "sellerROS":storedData?.sellerROS,
    // "sellerName":storedData?.sellerName,
    // "selectedSeller":storedData?.selectedSeller,
    // "cScore":storedData?.cScore,
    // "callHealth":storedData?.callHealth,
    // "callDuration":storedData?.callDuration,
    // "words":storedData?.words,
    // "moment":storedData?.moment,
    // "momentId":storedData?.momentId,
    // // "coeName":this.state.coe,
    // "pitch":storedData?.pitch,
    // "prob":storedData?.prob,
    // "opportunity":storedData?.opp,
    // "urgency":storedData?.urgency,
    // "coe":storedData?.coe,
    // "selectedCOE":storedData?.selectedCOE,
    // "selectedCoeRange":storedData?.selectedCoeRange,
    // "selectedDate":storedData?.selectedDate,
    // "selectedRange":storedData?.selectedRange,
    // "momentBucket":storedData?.momentBucket,
    // "momentBucketId":storedData?.momentBucketId,
    // "frequency":storedData?.frequency,
    // "reportType":storedData?.reportType}))
    wordData.groupIds.length > 0 ? setSelectedWordPage(selectedPageNumber) : setSelectedPage(selectedPageNumber);

    wordData.groupIds.length > 0 ? localStorage.setItem('selectedWordPage', e.selected + 1) : localStorage.setItem('selectedPage', e.selected + 1);
  }

  const handleChatPageChange = (e) => {
    const selectedPageNumber = e.selected + 1;
    props.callBack(e.selected + 1)
    setSelectedChatPage(selectedPageNumber);
    localStorage.setItem('selectedChatPage', e.selected + 1)
  }

  const handleNotifPageChange = (e) => {
    const selectedPageNumber = e.selected + 1;
    props.callBack(e.selected + 1)
    setNotifPage(selectedPageNumber)
    localStorage.setItem('notifSelectedPage', e.selected + 1)
  }

  const handleLiveAssistPageChange = (e) => {
    const selectedPageNumber =  e.selected + 1;
    props.callBack(e.selected +1)
    setSelectedLiveAssistPage(selectedPageNumber)
    localStorage.setItem("selectedLiveAssistPage", e.selected + 1)
  }

  useEffect(() => {
    if ((JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.conversationCallDetail?.keywords === 'true' ? true : localStorage.getItem('selectedPage')) && (location.pathname == '/sales_conversations' || location.pathname == '/voice')) {
      const selectedPageNumber = wordData.groupIds.length > 0 ? parseInt(localStorage.getItem('selectedWordPage')) : parseInt(localStorage.getItem('selectedPage'));
      let pageNo = props.customPageNumber || (selectedPageNumber <= props.availablePages ? selectedPageNumber : 1);
      setSelectedPage(pageNo);
      props.callBack(pageNo);
    }
    else if (localStorage.getItem('selectedChatPage') && location.pathname == '/chat') {
      const selectedPageNumber = parseInt(localStorage.getItem('selectedChatPage'));
      setSelectedChatPage(selectedPageNumber);
      props.callBack(selectedPageNumber);
    }
    else if (localStorage.getItem('notifSelectedPage') && location.pathname == '/notifications') {
      const selectedPageNumber = parseInt(localStorage.getItem('notifSelectedPage'));
      setNotifPage(selectedPageNumber);
      props.callBack(selectedPageNumber);
    }
    else if( localStorage.getItem("selectedLiveAssistPage") && location.pathname == '/live_assist'){
      const selectedPageNumber = parseInt(localStorage.getItem('selectedLiveAssistPage'));
      setSelectedLiveAssistPage(selectedPageNumber);
      props.callBack(selectedPageNumber);
    }
  }, []);

  console.log(history.location)
  console.log(window.location)

  // useEffect(() => {
  //   return () => {
  //     localStorage.removeItem('selectedPage');
  //   };
  // }, [history?.location?.pathname]);

  // console.log(notifPage)

  return (
    <ReactPaginate
      previousLabel={"Prev"}
      nextLabel={"Next"}
      breakLabel={"..."}
      pageCount={props.availablePages}
      // selected={storedData?.pageSelected}
      forcePage={location.pathname == '/notifications' ? (notifPage - 1) : location.pathname == '/chat' ? (selectedChatPage - 1) : location.pathname == '/live_assist' ? (selectedLiveAssistPage - 1) : (selectedPage - 1)}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3} //on clicking dotspagepagepage
      onPageChange={location.pathname == '/notifications' ? handleNotifPageChange : location.pathname == '/chat' ? handleChatPageChange : location.pathname == '/live_assist' ? handleLiveAssistPageChange : handlePageChange}
      containerClassName={"pagination"}
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      previousClassName={'page-item'}
      previousLinkClassName={"page-link"}
      nextClassName={"page-item"}
      nextLinkClassName={"page-link"}
      breakLinkClassName={"page-link"}
      activeClassName={"active"}
    >
    </ReactPaginate>
  )
}