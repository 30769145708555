import React from "react";
import { useState } from 'react';
import "./manageMoments.css";
import ReactTooltip from 'react-tooltip';
import momentService from "../../Services/moment.service";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import WantToDeleteModal from "../../Commons/Modal/WantToDeleteModal";
import toaster from "../../Toast/toaster";

function Moments(props) {
    const [isDelete, setIsDelete] = useState(false)
    const [isHide, setIsHide] = useState(true)
    const [isHideRemark, setIsHideRemark] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [isEditN, setIsEditN] = useState(true)
    const [isHideAppliesTo, setIsHideAppliesTo] = useState(true);
    const [isHideWeightage, setIsHideWeightage] = useState(true);
    const [exactVal, setExactValue] = useState('');
    const [val, setValue] = useState('');
    const [applicableOn, setApplicableOn] = useState(props.moments ? props.moments.momentApplicableOn:'ALL')
    const [weightage, setWeightage] = useState(props.moments ? props.moments.momentWeightage:'1')
    const [weightageMention, setWeightageMention] = useState('1')
    const [weightageMentionExact, setWeightageMentionExact] = useState('1')
    const [momentName, setMomentName] = useState(props.moments ? props.moments.momentName:'Moment Name');
    const [updatedMomentName, setUpdatedMomentName] = useState(props.moments ? props.moments.momentName:'Moment Name');
    const [updatedPositiveRemarks, setUpdatedPosRemarks] = useState(props.moments?props.moments.positiveRemarks:"POSITIVE")
    const [updatedNegativeRemarks, setUpdatedNegRemarks] = useState(props.moments?props.moments.negativeRemarks:"NEGATIVE")
    const onEdit = () => setIsHide(false);
    const onEditAppliesTo = ()=> setIsHideAppliesTo(false);
    const onEditWeightage = ()=> setIsHideWeightage(false);
    const onEditClose = () => {
        console.log('Edit Close')
        setIsHide(true)
        console.log(isHide);
        setIsHideAppliesTo(true)
        setIsHideWeightage(true)
    };


    const handleChange = (e) => {
        setUpdatedMomentName(e.target.value)
        //setIsHide(true)
    }

    const handleRemarks =(e)=>{
        const alphanumericRegex = /^[a-zA-Z\s]+$/;
        if(e.target.name==='positive'){
            if (alphanumericRegex.test(e.target.value) || e.target.value === '') {
                setUpdatedPosRemarks(e.target.value)
            }
        }
        if(e.target.name==='negative'){
            if (alphanumericRegex.test(e.target.value) || e.target.value === '') {
                setUpdatedNegRemarks(e.target.value)
            }
        }
    }


    const addMention = (e) => {
        const alphanumericRegex = /^[a-zA-Z\s]+$/;
        if(e.target.name === 'semantic'){
            if (alphanumericRegex.test(e.target.value) || e.target.value === '') {
                setValue(e.target.value)
            }
        }
        else{
            if (alphanumericRegex.test(e.target.value) || e.target.value === '') {
                setExactValue(e.target.value)
                console.log(e.target.value)
            }
        }
    }


    
    const addWeightage = (e)=>{
        if(e.target.name==='semanticWeightage'){
            setWeightageMention(e.target.value)
        }
        if(e.target.name==='exactWeightage'){
            setWeightageMentionExact(e.target.value)
        }
    }



    const handleKeyDown = (event) => {
        console.log(event);
        let data={
                mentionMatchType: event.toUpperCase(),
                mentionName: event === 'semantic'? val : exactVal,
                momentId: props.moments.momentId,
                mentionWeightage: event === 'semantic'? weightageMention : weightageMentionExact
            }
        
        if(data.mentionName){
            momentService.create_mentions(data).then(res =>{
                if(res.data.status === 0)
                {
                toaster.success(res?.data?.message)
                momentService.getMomentData(props.bucketId).then(res => {
                    console.log('moment--', res);
                    props.setMomentData(res);
                    setValue('');
                    setExactValue('')
                })
                }
            })
        }
      }

    const handleRemarksChange = (event)=>{
        // let someRemarks = ''
        // if(event.target.name === 'positive')
        // {
        //    if(updatedPositiveRemarks.includes(','))
        //     someRemarks = updatedPositiveRemarks.split(',')
        //    else if(updatedPositiveRemarks.includes(' ')) 
        //     someRemarks = updatedPositiveRemarks.split(',')
        //    else
        //     someRemarks = updatedPositiveRemarks
        // }
        // console.log(someRemarks)

        const buttonName = event.currentTarget.dataset.name;

        console.log(event,"-----1"); 
        if((event.key==='Enter' && event.target.name==='positive') || (event.type === 'click' && buttonName === 'positive')){
            console.log(event,"------2"); 
        let dataPos={
                positiveRemarks:updatedPositiveRemarks,
                momentId:props.moments.momentId
            }
        
            momentService.updateMoment(dataPos).then(res =>{
                console.log('updated', res);
                if(res.data.status === 0)
                {
                    momentService.getMomentData(props.bucketId).then(res => {
                        console.log('moment--', res);
                        props.setMomentData(res);
                        setIsEdit(true)
                        setUpdatedPosRemarks('')
                    })
                }
            })
        }
        else if((event.key==='Enter' && event.target.name==='negative')|| (event.type === 'click' && buttonName === 'negative')){
            let dataNeg={
                negativeRemarks:updatedNegativeRemarks,
                momentId:props.moments.momentId
            }
            momentService.updateMoment(dataNeg).then(res =>{
                console.log('updated', res);
                if(res.data.status === 0)
                {
                    momentService.getMomentData(props.bucketId).then(res => {
                        console.log('moment--', res);
                        props.setMomentData(res);  
                        setIsEditN(true)
                        setUpdatedNegRemarks('')
                    })
                }
            });
        }
    }
      
    const onUpdateMoment = (e, id) => {
        let data={};
        console.log(e , 'gyu1');
        if(e.target.name  === 'momentApplicableOn')
        {
            console.log(e , 'gyu2');
            data= {
                momentApplicableOn: applicableOn,
                momentId: props. moments.momentId,
            }
        }
        else if(e.target.name  === 'momentWeightage')
        {
            console.log(props.moments)
            console.log(e , 'gyu3');
            data= {
                momentId: props.moments.momentId,
                momentWeightage: e.target.value
            }
            console.log(data)
            setIsHideWeightage(true)
        }
        
        else{
            console.log(e , 'gyu4');
        data= {
            fatal:true,
            momentName: updatedMomentName? updatedMomentName: momentName,
            momentId: props.moments.momentId,
        }
        }
             
        momentService.updateMoment(data).then(res =>{
                console.log('updated', res);
                if(res.data.status === 0)
                {
                    setMomentName(updatedMomentName)
                    momentService.getMomentData(props.bucketId).then(res => {
                        console.log('moment--', res);
                        props.setMomentData(res);
                    })
                }
        });
           
       
        setIsHide(true)
    };

    const deleteMentionId =  (id) =>{
        console.log(id)
        momentService.delete_mentions(id).then(res =>{
           
            if(res.data.status === 0)
            {
                momentService.getMomentData(props.bucketId).then(res => {
                    console.log('moment--', res);
                    props.setMomentData(res);
                })
            }
        })
    }

    const deleteMoment = () =>{
        setIsDelete(false)
        momentService.deleteMoment(props.moments.momentId)
        .then(res =>{
            if(res.data.status === 0)
            {
                momentService.getMomentData(props.bucketId).then(res => {
                    console.log('moment--', res);
                    props.setMomentData(res);
                })
            }
        })
    }
    const deleteMoments = () =>{
        setIsDelete(true)
    }

    const handleSelect = (e, id) =>{
        console.log(id, "mmid", props.moments)
        if(e.target.name === 'momentApplicableOn'){
            setApplicableOn(e.target.value)
            onUpdateMoment(e);
        }
        if(e.target.name === 'momentWeightage'){
            console.log(e.target.value)
            console.log(momentName)
            setWeightage(e.target.value)
            onUpdateMoment(e, id);

        }
    }

    const openCloseM = ()=>{
        setIsOpen(!isOpen)
    }
    const onEditRemarks =()=>{
        setUpdatedPosRemarks(props.moments.remark.positiveRemarks)
        setIsEdit(false)
    }
    const onEditRemarksN =()=>{
        setUpdatedNegRemarks(props.moments.remark.negativeRemarks)
        setIsEditN(false)
    }
    console.log(props?.moments?.remark?.length)
    console.log(props.moments);

    const onEditCloseRemarkP =()=>{
        setIsEdit(true)
        setUpdatedPosRemarks('')
    }
    const onEditCloseRemarkN =()=>{
        setIsEditN(true)
        setUpdatedNegRemarks('')
    }
    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <div>
                    {isHide ? <>
                        <span className="editable-nameInput-val">{momentName}</span>
                        <button className="btn p-0 edit-moment-member" onClick={onEdit}>
                            <i className="lni lni-pencil text-primary-blue" data-tip="Edit"></i>
                        </button>
                        </>:<>
                        <input type="text" className="input-field-bt-border  font-20 w-auto h-auto" name='momentName' value={updatedMomentName} onChange={handleChange}/>
                        <button className="btn p-0 save-moment-member" onClick={onUpdateMoment}>
                            <i className="lni lni-checkmark text-primary-blue" data-tip="Save"></i>
                        </button>
                        <button className="btn p-0 trash-moment-member" onClick={onEditClose}>
                            <i className="lni lni-cross-circle text-primary-blue" data-tip="Cancel"></i>
                        </button>
                        </>}
                    </div>
                    <div className="d-flex">
                        <div>
                            <label for="Applies-To" className="me-1 d-block">Applies To </label>
                            {isHideAppliesTo ? <>
                                <span className="editable-nameInput-val select-box-value-view">{applicableOn}  </span>
                                <button className="btn p-0 edit-moment-member ms-1" onClick={onEditAppliesTo}>
                                    <i className="lni lni-pencil text-primary-blue" data-tip="Edit"></i>
                                </button>
                            </>:<>
                                <Select  id="Applies-To" name='momentApplicableOn' value={applicableOn} onChange={handleSelect} sx={{ width: 120 }}>
                                    <MenuItem  value="ALL" key='101'>ALL</MenuItem>
                                    <MenuItem  value="USER" key='102'>USER</MenuItem>
                                    <MenuItem  value="CUSTOMER" key='103'>CUSTOMER</MenuItem>
                                </Select>
                                {/* <button className="btn p-0 save-moment-member ms-1" onClick={onUpdateMoment}>
                                    <i className="lni lni-checkmark text-primary-blue" data-tip="Save"></i>
                                </button> */}
                                <button className="btn p-0 trash-moment-member ms-1" onClick={onEditClose}>
                                    <i className="lni lni-cross-circle text-primary-blue" data-tip="Cancel"></i>
                                </button>
                            </>}
                        </div>

                        {/* <div>
                            <label for="Applies-To">Applies To</label>
                            <div>
                                <Select  id="Applies-To" name='momentApplicableOn' value={applicableOn} onChange={handleSelect} sx={{ width: 120 }}>
                                    <MenuItem  value="ALL" key='101'>ALL</MenuItem>
                                    <MenuItem  value="USER" key='102'>USER</MenuItem>
                                    <MenuItem  value="CUSTOMER" key='103'>CUSTOMER</MenuItem>
                                </Select>
                            </div>
                        </div> */}
                        <div className="mx-1">
                            <div>
                                <label for="Applies-To" className="me-1 d-block">Weightage </label>
                                {isHideWeightage ? <>
                                    <span className="editable-nameInput-val select-box-value-view">{weightage}  </span>
                                    <button className="btn p-0 edit-moment-member ms-1" onClick={onEditWeightage}>
                                        <i className="lni lni-pencil text-primary-blue" data-tip="Edit"></i>
                                    </button>
                                </>:<>
                                    <Select  id="Weightage" name='momentWeightage' aria-label=" example" value={weightage} onChange={(e)=>handleSelect(e, props.moments.momentId)} sx={{ width: 100 }}>
                                        <MenuItem value='1' key='201'>1</MenuItem>
                                        <MenuItem value='2' key='202'>2</MenuItem>
                                        <MenuItem value='3' key='203'>3</MenuItem>
                                        <MenuItem value='4' key='204'>4</MenuItem>
                                        <MenuItem value='5' key='205'>5</MenuItem>
                                        <MenuItem value='6' key='206'>6</MenuItem>
                                        <MenuItem value='7' key='207'>7</MenuItem>
                                        <MenuItem value='8' key='208'>8</MenuItem>
                                        <MenuItem value='9' key='209'>9</MenuItem>
                                        <MenuItem value='10' key='210'>10</MenuItem>
                                    </Select>
                                    {/* <button className="btn p-0 save-moment-member ms-1" onClick={onUpdateMoment}>
                                        <i className="lni lni-checkmark text-primary-blue" data-tip="Save"></i>
                                    </button> */}
                                    <button className="btn p-0 trash-moment-member ms-1" onClick={onEditClose}>
                                        <i className="lni lni-cross-circle text-primary-blue" data-tip="Cancel"></i>
                                    </button>
                                </>}
                            </div>
                            {/* <label for="weightage">Weightage</label>
                            <div>
                            <Select  id="Weightage" name='momentWeightage' aria-label=" example" value={weightage} onChange={handleSelect} sx={{ width: 50 }}>
                                <MenuItem value='1' key='201'>1</MenuItem>
                                <MenuItem value='2' key='202'>2</MenuItem>
                                <MenuItem value='3' key='203'>3</MenuItem>
                                <MenuItem value='4' key='204'>4</MenuItem>
                            </Select>
                            </div> */}
                        </div>
                        <div className="del-btn d-flex align-items-center ms-2">
                            <button type="button" className="btn del-moment-group p-0 mx-1" onClick={deleteMoments}> 
                                <i className="lni lni-archive text-primary-blue me-0" data-tip="Delete Moment" ></i>
                            </button>
                            <button type="button" className="btn del-moment-group ms-1 p-0" onClick={openCloseM}>
                                {isOpen?<i className="lni lni-minus font-14"></i>:<i className="lni lni-plus font-14"></i>}
                            </button>
                        </div>
                    </div>
                </div>
                {isOpen?<>
                <div className="semantically-similar-match mt-4">
                    <div className="heading">
                        <h6>Semantically Similar Matches</h6>
                    </div>
                    {/* <div className="card mb-2">
                        <div className="card-body">
                            <div>
                                <span className="badge rounded-pill btn btn-outline-blue fw-normal moment-matches-text font-12 m-1" key={m.mentionId}>
                                    {m.mentionName}
                               </span>
                               <span>{'Weightage :' m.momentWeightage } </span>
                            </div>
                            <div>
                            <button className="btn p-0" onClick={() => deleteMentionId(m.mentionId)}><i className="lni lni-close text-dark me-0"  ></i></button>
                            </div>
                        </div>
                    </div> */}
                    <div>
                        {
                        props.moments.mentions.length > 0 ?
                        props.moments.mentions.map(m =>(
                            m.mentionMatchType != 'EXACT'?<>
                               {/* <span className="badge rounded-pill btn btn-outline-blue fw-normal moment-matches-text font-12 m-1" key={m.mentionId}>
                                {m.mentionName}
                                <button className="btn p-0" onClick={() => deleteMentionId(m.mentionId)}><i className="lni lni-close text-dark me-0"  ></i></button>
                               </span> */}
                               <div className="card-mention" key={m.mentionId}>
                                    <div className="d-flex">
                                        <div className="text-center">
                                            <p className="fw-normal moment-matches-text font-14 m-1" >
                                                {m.mentionName}
                                            </p>
                                            <p className=" moment-matches-text font-12 m-1">Weightage: {m.mentionWeightage?m.mentionWeightage:'NA' } </p>
                                        </div>
                                        <button className="btn p-0 badge" onClick={() => deleteMentionId(m.mentionId)}><i className="lni lni-close text-dark me-0 font-14"  ></i></button>
                                    
                                    </div>
                                </div>
                                </>
                            : ''
                        ))
                        :
                        <div> </div>
                        }
                        <div className="d-flex input-of-keyword mt-4 ">
                            <input className="input-field input-field-bt-border w-50" type="text" id="input-similar-match"  name='semantic' value={val} placeholder="Add Phrase/Keyword" onChange={addMention} />
                            <div style={{marginLeft:"18px"}}>
                                <label for="weightage">Weightage</label>
                                <div>
                                <Select  id="Weightage" name='semanticWeightage' aria-label=" example" value={weightageMention} onChange={addWeightage} sx={{ width: 100 }} >
                                    <MenuItem value='0.1' key='201'>0.1</MenuItem>
                                    <MenuItem value='0.2' key='202'>0.2</MenuItem>
                                    <MenuItem value='0.3' key='203'>0.3</MenuItem>
                                    <MenuItem value='0.4' key='204'>0.4</MenuItem>
                                    <MenuItem value='0.5' key='205'>0.5</MenuItem>
                                    <MenuItem value='0.6' key='206'>0.6</MenuItem>
                                    <MenuItem value='0.7' key='207'>0.7</MenuItem>
                                    <MenuItem value='0.8' key='208'>0.8</MenuItem>
                                    <MenuItem value='0.9' key='209'>0.9</MenuItem>
                                    <MenuItem value='1.0' key='210'>1.0</MenuItem>
                                </Select>
                                </div>
                            </div>
                            <button className="btn save-mention-moment ms-1" onClick={()=> handleKeyDown('semantic')} name='semantic'>
                                <i className="lni lni-checkmark text-primary-blue" data-tip="Save"></i>
                            </button>               
                        </div>
                        
                    </div>
                </div>
                <div className="exact-similar-match mt-4">
                    <div className="heading">
                        <h6>Exact Matches</h6>
                    </div>
                    <div>
                    {
                        props.moments.mentions.length > 0 ?
                        props.moments.mentions.map(m =>(
                            m.mentionMatchType === 'EXACT'?<>
                               {/* <span className="badge rounded-pill btn btn-outline-blue fw-normal moment-matches-text font-12 m-1" key={m.mentionId}>
                                {m.mentionName}
                                <button className="btn p-0"  onClick={() => deleteMentionId(m.mentionId)}><i className="lni lni-close text-dark me-0" ></i></button>
                            </span> */}
                                <div className="card-mention" key={m.mentionId}>
                                    <div className="d-flex">
                                        <div className="text-center">
                                            <p className="fw-normal moment-matches-text font-14 m-1" >
                                                {m.mentionName}
                                            </p>
                                            <p className=" moment-matches-text font-12 m-1">Weightage: {m.mentionWeightage?m.mentionWeightage:'NA' } </p>
                                        </div>
                                        <button className="btn p-0"  onClick={() => deleteMentionId(m.mentionId)}><i className="lni lni-close text-dark me-0" ></i></button>
                                    
                                    </div>
                                </div>
                            </>
                            : ''
                        ))
                        :
                        <div>  </div>
                        }
                        <div className="d-flex input-of-keyword mt-4 ">
                            <input className="input-field input-field-bt-border w-50" type="text" id="input-similar-match"  name='exact' value={exactVal} placeholder="Add Phrase/Keyword" onChange={addMention} />
                            <div style={{marginLeft:"18px"}}>
                                <label for="weightage">Weightage</label>
                                <div>
                                <Select  id="Weightage" name='exactWeightage' aria-label=" example" value={weightageMentionExact} onChange={addWeightage} sx={{ width: 100 }} >
                                    <MenuItem value='0.1' key='201'>0.1</MenuItem>
                                    <MenuItem value='0.2' key='202'>0.2</MenuItem>
                                    <MenuItem value='0.3' key='203'>0.3</MenuItem>
                                    <MenuItem value='0.4' key='204'>0.4</MenuItem>
                                    <MenuItem value='0.5' key='205'>0.5</MenuItem>
                                    <MenuItem value='0.6' key='206'>0.6</MenuItem>
                                    <MenuItem value='0.7' key='207'>0.7</MenuItem>
                                    <MenuItem value='0.8' key='208'>0.8</MenuItem>
                                    <MenuItem value='0.9' key='209'>0.9</MenuItem>
                                    <MenuItem value='1.0' key='210'>1.0</MenuItem>
                                </Select>
                                </div>
                            </div>
                            <button className="btn save-mention-moment ms-1" onClick={()=> handleKeyDown('exact')} name='semantic'>
                                <i className="lni lni-checkmark text-primary-blue" data-tip="Save"></i>
                            </button>                   
                        </div>
                    </div>
                </div>
                <div className="exact-similar-match mt-4">
                    <div className="heading">
                        <h6>Positive Remarks</h6>
                    </div>
                    <div>
                    {
                        props?.moments?.remark ?<>
                            {isEdit?<div className="card-mention" >
                                <div className="d-flex">
                                    <div className="text-center">
                                        <p className="fw-normal moment-matches-text font-14 m-1" >
                                            {props.moments.remark.positiveRemarks}
                                        </p>
                                    </div>
                                    <button class="btn p-0 gp-edit-moment-member" onClick={onEditRemarks}><i class="lni lni-pencil text-primary-blue" data-tip="Edit" ></i></button>
                                    {/* <button className="btn p-0"  onClick={() => setIsEdit(false)}><i className="lni lni-close text-dark me-0" ></i></button> */}
                                </div>
                            </div>
                            :
                                <div className="input-of-keyword mt-4">
                                    <input className="input-field input-field-bt-border w-50" type="text" id="input-exact-match" name='positive' value={updatedPositiveRemarks} placeholder="Add Remarks" onChange={handleRemarks} onKeyDown={handleRemarksChange}/>
                                    <button className="btn save-mention-moment ms-0" style={{padding: '0px', margin: '3px 0px 5px 5px'}} data-name="positive" onClick={handleRemarksChange}>
                                   <i className="lni lni-checkmark text-primary-blue" data-tip="Save"></i>
                                   </button>
                                   <button className="btn save-mention-moment ms-0"style={{padding: '0px', margin: '3px 0px 5px 5px'}} onClick={onEditCloseRemarkP}>
                                   <i className="lni lni-cross-circle text-primary-blue" data-tip="Cancel"></i>
                                   </button>

                                </div>
                            }
                        </>
                        : <div>  </div>
                        }
                        
                    </div>
                </div>
                <div className="exact-similar-match mt-4">
                    <div className="heading">
                        <h6>Negative Remarks</h6>
                    </div>
                    <div>
                    {
                        props?.moments?.remark ?<>
                        {/* // props?.moments?.remark.map(m =>(
                            // m.mentionMatchType === 'POSITIVE'?
                            <span className="badge rounded-pill btn btn-outline-blue fw-normal moment-matches-text font-12 m-1" key={props.moments.remark.remarkId}>
                                {props.moments.remark.negativeRemarks}
                                 <button className="btn p-0"  ><i className="lni lni-close text-dark me-0" ></i></button> 
                            </span>
                            // : ''
                        // )) */}
                            {isEditN?<div className="card-mention" >
                                    <div className="d-flex">
                                        <div className="text-center">
                                            <p className="fw-normal moment-matches-text font-14 m-1" >
                                                {props.moments.remark.negativeRemarks}
                                            </p>
                                        </div>
                                        <button class="btn p-0 gp-edit-moment-member" onClick={onEditRemarksN}><i class="lni lni-pencil text-primary-blue" data-tip="Edit" ></i></button>
                                        {/* <button className="btn p-0"  onClick={() => setIsEdit(false)}><i className="lni lni-close text-dark me-0" ></i></button> */}
                                    </div>
                                </div>
                                :
                                <div className="input-of-keyword mt-4">
                                    <input className="input-field input-field-bt-border w-50" type="text" id="input-exact-match" name='negative' value={updatedNegativeRemarks} placeholder="Add Remarks" onChange={handleRemarks} onKeyDown={handleRemarksChange}/>
                                    <button className="btn save-mention-moment ms-0" style={{padding: '0px', margin: '3px 0px 5px 5px'}} data-name="negative" onClick={handleRemarksChange}>
                                   <i className="lni lni-checkmark text-primary-blue" data-tip="Save"></i>
                                   </button>
                                   <button className="btn save-mention-moment ms-0"style={{padding: '0px', margin: '3px 0px 5px 5px'}} onClick={onEditCloseRemarkN}>
                                   <i className="lni lni-cross-circle text-primary-blue" data-tip="Cancel"></i>
                                   </button>
                                </div>
                            }
                        </>
                        :
                        <div>  </div>
                        }
                    </div>
                </div>
                </>:''}
            </div>
            <ReactTooltip place="bottom" type="dark" effect="solid"/>
            <WantToDeleteModal show={isDelete} close={()=> setIsDelete(false)}  isConform={deleteMoment} />
        </div>
    )
}
export default Moments;