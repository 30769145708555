import { Form } from "react-bootstrap"
import { useState,useEffect } from "react"
import { USER_DETAIL } from "../../Constants/constant"
import dashboardService from "../Services/dashboard.service"
import { usePreviousValue } from "../Notifications/customHook/usePreviousValue";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function AgentFilter(props){

    let userRole =JSON.parse(localStorage.getItem(USER_DETAIL)).userRole
    let dateFormat = JSON.parse(localStorage.getItem("TIME_FORMAT"))
    const [opendp, setOpendp] = useState(false);
    const [opendp1, setOpendp1] = useState(false);
    const[selectedCOE, setSelectedCOE] = useState()
    const[coeList, setCoeList] = useState()

    const storedData = JSON.parse(localStorage.getItem("AGENT"))
    const[customeDate, setCustomeDate] = useState(storedData?.selectedRange==9?true:storedData?.selectedRange==''?true:false)
    const[dateRange, setDateRange] = useState(storedData?.selectedRange?storedData?.selectedRange:9)
    const[dateList, setDateList] = useState()

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // const fromD = urlParams.get('from')
    // const toD = urlParams.get('to')
 

    const crrDate = new Date()
    
    const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const[startDate, setStartDate] = useState(storedData&&storedData.fromD?new Date(`${storedData.fromD.split("-")[1]}-${storedData.fromD.split("-")[0]}-${storedData.fromD.split("-")[2]}`):`${firstDay.getDate()}-${firstDay.getMonth()+1}-${firstDay.getFullYear()}`)
    const[endDate, setEndDate] = useState(storedData&&storedData.toD?new Date(`${storedData.toD.split("-")[1]}-${storedData.toD.split("-")[0]}-${storedData.toD.split("-")[2]}`):`${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`)

    const[inputs, setInputs] = useState({})
    const[filteredData, setFilteredData] = useState({})
    const [defaultData, setDefaultData] = useState({})

    const prevValOfFilteredData = usePreviousValue(filteredData)

   
    
    useEffect(()=>{
      if(prevValOfFilteredData!==filteredData){
        props.agentsFilter(filteredData)
     
      }
    },[filteredData])


    useEffect(()=>{
      if(props.reset){
        setSelectedCOE("Select All")
        setCustomeDate(false)
        setDateRange(5)
        setFilteredData({})
      }
      props.resetFormState()
    },[props.reset])

    function getDateList () {
        dashboardService.getDateAcronym().then(res => {
          let dateList = res?res.data?res.data.data.filter((e)=>{
            return e.type =="CURRENT" || e.type =="GLOBAL"
          }):'':''
          setDateList(dateList);
          let dd = dateList[4].fromDate.split("-")[2]
          let mm = dateList[4].fromDate.split("-")[1]
          let yyyy = dateList[4].fromDate.split("-")[0]
          let dateF = `${dd}-${mm}-${yyyy}`
          let dd_1 = dateList[4].toDate.split("-")[2]
          let mm_1= dateList[4].toDate.split("-")[1]
          let yyyy_1= dateList[4].toDate.split("-")[0]
          let dateT = `${dd_1}-${mm_1}-${yyyy_1}`

          let coeIds = props?.agentData?.coe[0].map((item)=>{
            return item.id
          })
          
          props.defaultData({fromDate:dateT,toDate:dateF,externalId:coeIds})
        })
    }
    
    function getUserCoeList() {
        dashboardService.getUserCOEList().then(res => {
            console.log('getuserCoeList--', res);
            setCoeList(res?res.data.data?res.data.data:'':'');
            let userCoe = res?res.data.data?res.data.data.map((item)=>{
              return item.externalId
            }):'':''
            setFilteredData(filteredData=>({...filteredData,externalId:userCoe}))
        })
    }


    useEffect(()=>{ 
      // if(props.agentData?.coe.length>0){
        getDateList()
      // }
        
        //  
        // else{
        //   getMomentBuckets()
        // }
      },[props.reset==true])

   
    const handleSelectCoe = (e)=>{
      let coeNum = parseInt(e.target.value)
      setSelectedCOE(e.target.value)
      setInputs(({ inputs }) => ({
        data: { ...inputs, coeId: e.target.value},
      }));
      let coes =props.agentData.coe[0].map(function (el) {
        return el.id 
      })
      let coeId = []
      for(let i=0;i<coes.length;i++){
        if(coes[i]===coeNum){
          coeId.push(coes[i])
        }
      }
      
      setFilteredData({...filteredData,externalId: coeId})
    }

    const handleSelectDate =(e)=> {
      setDefaultData({})
        setDateRange(e.target.value)
        if (e.target.value == 9) {
          setCustomeDate(true);
        }
        else{
          setCustomeDate(false);
          let dates = dateList.filter(function (el) {
            return el.id == e.target.value;
          })
          let dd = dates[0].fromDate.split("-")[2]
          let mm = dates[0].fromDate.split("-")[1]
          let yyyy = dates[0].fromDate.split("-")[0]

          let dd_1 = dates[0].toDate.split("-")[2]
          let mm_1= dates[0].toDate.split("-")[1]
          let yyyy_1= dates[0].toDate.split("-")[0]

          let dateF = `${dd}-${mm}-${yyyy}`
          let dateT = `${dd_1}-${mm_1}-${yyyy_1}`

          setInputs(({ inputs }) => ({
            data: { ...inputs, fromDate: dateT,toDate:dateF },
          }));
          setFilteredData({...filteredData,fromDate: dateT,toDate:dateF, selectedDate: dateList[e.target.value - 1].name, selectedRange: e.target.value })
          // localStorage.setItem("AGENT_FILTER",{"fromD":dateT,"toD":dateF})
        }
    }

    const handleStartDate = (e)=>{
        setStartDate(e)
        let date = `${(new Date(e)).getDate()}-${(new Date(e)).getMonth()+1}-${(new Date(e)).getFullYear()}`;
        setInputs(({ inputs }) => ({
            data: { ...inputs, fromDate: date },
        }));
        setFilteredData({...filteredData,fromDate: date})
    }

    const handleEndDate = (e)=>{
        console.log(e)
        setEndDate(e)
        let date = `${(new Date(e)).getDate()}-${(new Date(e)).getMonth()+1}-${(new Date(e)).getFullYear()}`;
        console.log(date)
        setInputs(({ inputs }) => ({
            data: { ...inputs, toDate: date },
        }));
        setFilteredData({...filteredData,toDate: date})
    }

    return(
        <div className="dashboard-filter-group">
            <div className="lh-1 mb-3">
              <p className="mb-0 call-fillter-heading">COE</p>
              <hr className="filter-Hr"></hr>

              <Form.Select as="select" custom
              style={{textTransform:"capitalize"}}
              name="role" aria-label="Default select example"
              onChange={handleSelectCoe}
              className="form-control-sm py-1"
              placeholder="Select COE"
              value={selectedCOE}
              >
                <option value=''>Select All</option>
                {props.agentData.coe[0]?props.agentData.coe[0].map((coe) => {
                    return <option value={coe.id} key={coe.id}>{coe.name}</option>;
                }):''}
              </Form.Select>
            </div>
            <div className="lh-1 mb-3">
                <div className="filter-Date-div">
                <div className="filter-Date-selected">
                    <p className="mb-0 call-fillter-heading">Select Date</p>
                    <hr className="filter-Hr"></hr>
                    <Form.Select
                        as="select"
                        custom
                        name="selectDate"
                        aria-label="Default select example"
                        className="form-control-sm py-1"
                        value={dateRange}
                        onChange={handleSelectDate}
                        >
                        {dateList? dateList.map((d) => { return(<option value={d.id} key={d.id}> {d.name} </option>)  }):''}
                        
                    </Form.Select>
                </div>
                {customeDate?<div className="call-fillter-date dasbard-comp-setting mt-2 mx-1 align-items-center">
                    <p className="mb-0 call-fillter-heading">From:</p>
                    <hr className="filter-Hr"></hr>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        openTo="day"
                        views={['year', 'month', 'day']}
                        inputFormat={dateFormat}
                        value={startDate}
                        name="startDate"
                        onChange={handleStartDate}
                        disableOpenPicker
                        open={opendp}
                        onOpen={() => setOpendp(true)}
                        onClose={() => setOpendp(false)}
                        renderInput={(params) => (
                            <TextField {...params} onClick={(e) => setOpendp(true)} />
                        )}
                        />
                    </LocalizationProvider>
            
                    <p className="mb-0 call-fillter-heading">To:</p>
                    <hr className="filter-Hr"></hr>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        openTo="day"
                        views={['year', 'month', 'day']}
                        inputFormat={dateFormat}
                        value={endDate}
                        name="endDate"
                        onChange={handleEndDate}
                        disableOpenPicker
                        open={opendp1}
                        onOpen={() => setOpendp1(true)}
                        onClose={() => setOpendp1(false)}
                        renderInput={(params) => (
                            <TextField {...params} onClick={(e) => setOpendp1(true)} />
                        )}
                        />
                    </LocalizationProvider>
                </div>:''}
                </div>
            </div>
        </div>
    )
}