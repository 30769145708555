import React, { useState } from "react";
import { useEffect } from "react";
import Select from 'react-select'
import { Modal, Button, Card } from "react-bootstrap";
import GenerateReport from "./GenerateReport";
import ManageReportColumn from "./ManageReportColumn";
import { apiCall } from "../Services/Interceptor";

export default function Reports(){
    const [isOpen, setIsOpen] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState("")
    const [convFilters, setConvFilters] = useState([])

    function getFilters (){
        apiCall.get('/odio/api/call/conversation/filter/search')
        .then((response)=>{
            setConvFilters(response?.data?.data)
        })
        .catch((error)=> console.log(error))
    }

    useEffect(() => {
        document.title = "Reports  - Odio"
        getFilters()
      }, [])

    function handleShow(value) {
        setIsOpen(true)
    }
    const storedData = JSON.parse(localStorage.getItem("AGENT"))
    console.log(JSON.parse(localStorage?.getItem("AGENT")))

    const selectedData = (e)=>{
        console.log(e)
        setSelectedFilter(e)
    }

    return( 
        <>
       {/* { isOpen ? <GenerateReport name={"Report"} setIsOpen={setIsOpen} /> : */}
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                        <div className="col-12 mb-2" >
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-inline">
                                        <h2 className="account-name leftNav-report d-inline">Reports</h2>
                                        {JSON.parse(localStorage.getItem("AGENT"))?.selectedCOE!=''&&JSON.parse(localStorage.getItem("AGENT"))?.selectedCOE?.length>1?JSON.parse(localStorage.getItem("AGENT"))?.selectedCOE?.map(item=>{
                                            return (
                                            <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`COE:${item}`}<a href="javascript:;"></a></span>
                                            )})
                                            :
                                            <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`COE:${JSON.parse(localStorage.getItem("AGENT"))?.selectedCOE?JSON.parse(localStorage.getItem("AGENT"))?.selectedCOE[0]:""}`}<a href="javascript:;"></a></span>
										}
                                        {/* {typeof(storedData.selectedCOE)!='string'?selectedFilter?.selectedCOE?.map(item=>{return (<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`COE: ${item}`}<a href="javascript:;"></a></span>)}):<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`COE: ${selectedFilter?.selectedCOE}`}<a href="javascript:;"></a></span>} */}
                                        {JSON.parse(localStorage.getItem("AGENT"))?.reportType?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`REPORT TYPE: ${JSON.parse(localStorage.getItem("AGENT"))?.reportType}`}<a href="javascript:;"></a></span>:<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{'REPORT TYPE: COE Detail Report'}<a href="javascript:;"></a></span>}
                                        {JSON.parse(localStorage.getItem("AGENT"))?.frequency?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`FREQUENCY: ${JSON.parse(localStorage.getItem("AGENT"))?.frequency}`}<a href="javascript:;"></a></span>:<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{'FREQUENCY: Monthly'}<a href="javascript:;"></a></span>}
                                        {JSON.parse(localStorage.getItem("AGENT"))?.fromD&&JSON.parse(localStorage.getItem("AGENT"))?.toD?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{JSON.parse(localStorage.getItem("AGENT"))?.selectedDate=="Custom Date"?`From:(${JSON.parse(localStorage.getItem("AGENT"))?.fromD}) To:(${JSON.parse(localStorage.getItem("AGENT"))?.toD})`: `DATE: ${JSON.parse(localStorage.getItem("AGENT"))?.selectedDate}`}<a href="javascript:;"></a></span>:""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <Card>
                                <Card.Body>
                                    {/* <div className="d-flex top-fixed-box">
                                        <div className=" mb-3 text-sm-left reportsHeader">
                                            <h2 className="account-name account-id">Reports</h2>
                                            {storedData?typeof(storedData.selectedCOE)!='string'?storedData?.selectedCOE?.map(item=>{return (<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`COE: ${item}`}<a href="javascript:;"></a></span>)}):<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`COE: ${storedData?.selectedCOE}`}<a href="javascript:;"></a></span>:""}
                                            {storedData?storedData.reportType?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`REPORT TYPE: ${storedData?.reportType}`}<a href="javascript:;"></a></span>:<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{'REPORT TYPE: COE Detail Report'}<a href="javascript:;"></a></span>:""}
                                            {storedData?storedData.frequency?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{`FREQUENCY: ${storedData?.frequency}`}<a href="javascript:;"></a></span>:<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{'FREQUENCY: Monthly'}<a href="javascript:;"></a></span>:""}
                                            {storedData?storedData.fromD&&storedData.toD?<span className="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">{storedData?.selectedDate=="Custom Date"?`From:(${storedData?.fromD}) To:(${storedData?.toD})`: `DATE: ${storedData?.selectedDate}`}<a href="javascript:;"></a></span>:"":""}
                                        </div>
                                    </div> */}
                                    <div className="table-responsive report">
                                        <ManageReportColumn selectedData={selectedData} convFilters={convFilters}/>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
{/* } */}

       
        </>
    )
}