import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loading from "../Commons/Loading";
import "../Profile/profile.css";
import toast from "../Toast/toaster";
import { history } from "../../redux-container/store";
import { apiCall } from "../Services/Interceptor";
import { Popover, OverlayTrigger } from "react-bootstrap";
import userService from "../Services/user.service";

export default function ForgotPasswordEnterOTP({ email }) {
  const [loading, setLoading] = useState(false);
  const [CPError, setCPError] = useState();
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [timer, setTimer] = useState(120);

  const CHANGE_PASSWORD = "/odio/api/user/validate-code";
  const [data, setData] = useState({
    confirmPassword: "",
    newPassword: "",
    code: "",

    errors: {
      newPassword: "",
      confirmPassword: "",
    },
  });
  // const [values, setValues] = React.useState({
  //   password: "",
  //   showPassword: false,
  // });
  const [newValues, setNewValues] = React.useState({
    newPassword: "",
    showNewPassword: false,
  });
  const [confValues, setConfValues] = React.useState({
    confPassword: "",
    showConfPassword: false,
  });

  const handleClickShowNewPassword = () => {
    setNewValues({ ...newValues, showNewPassword: !newValues.showNewPassword });
  };
  const handleClickShowConfPassword = () => {
    setConfValues({
      ...confValues,
      showConfPassword: !confValues.showConfPassword,
    });
  };
  const [errors, setErrorsValidate] = useState({});

  const validate = (name, value,prevData) => {
    let formError = "";
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{10,}$/;

    if (name == "newPassword") {
      if (!value) {
        formError = "Enter your new password.";
      } else if (!regex.test(value)) {
        formError = "Password does not match the format";
      }
      // else if (value && value.length < 10) {
      //   formError = "Password must be more than 10 characters."
      // }
    }

    if (name == "confirmPassword") {
      if (!value) {
        formError = "Renter your new password.";
      } else if (value == data.password) {
        formError = "Current Password and old Password should be different";
      } else if (value != prevData.newPassword) {
        formError = "New Password & Confirm Password should be same.";
      }
    }
    return formError;
  };

  const changePassword = async (data) => {
    return await apiCall
      .put(CHANGE_PASSWORD, data)
      .then((response) => {
        console.log("response===1>", response);
        if (response) {
          if (response?.data?.status === 0) {
            response?.data?.message && toast?.success(response.data.message);
            history.push("/login");
          }else if(response?.data?.status === 1){
            toast.error(response.data.message)
          }
        }
      })
      // .catch((error) => {
      //   console.log("response===e>", error?.response);
      //   if (error?.response?.data?.status) {
      //     if (error?.response?.data?.status === 400) {
      //       setCPError(error.response.data.message);
      //       toast.error(error.response.data.message);
      //       return error.response;
      //     } else if (error.response.data.status === 403) {
      //       toast.error(error.response.data.message);
      //       history.push("/login");
      //     } else if (error.response.data.status === 404) {
      //       toast.error(error.response.data.message);
      //     } else if (error.response.data.status === 500) {
      //       toast.error(error.response.data.message);
      //     } else {
      //       console.log("Oops somethings went wrong, Network Error.");
      //     }
      //   }
      // });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    //  validate(data);
    //  setErrors(formError);
    if (
      data?.errors?.confirmPassword == "" &&
      data?.errors?.newPassword == ""
    ) {
      changePassword({
        confirmPassword: data?.confirmPassword,
        newPassword: data?.newPassword,
        email,
        code: data?.code
      }).then((response) => {
        setLoading(false);
        if (response) {
          setData((data) => ({
            ...data,
            errors: {newPassword: "", confirmPassword: "" },
          }));
        } else {
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  };

  console.log(data);

  const handleChange = (event) => {
    const { name, value } = event.target;
      // setData(prevData => ({ ...prevData, [name]: value ,errors:{...prevData.errors,[name]: validate(name,value)}}));
      
      setData((prevData) => ({ ...prevData, [name]: value }));

      setData((prevData) => {
        let errors = { ...prevData.errors };

        if (name === 'newPassword') {
          errors.newPassword = validate('newPassword', prevData.newPassword,prevData);
          errors.confirmPassword = validate('confirmPassword', prevData.confirmPassword,prevData);
        } 
        // else if (name === 'confirmPassword') {
        //   errors.newPassword = validate('newPassword', prevData.newPassword);
        //   errors.confirmPassword = validate('confirmPassword', value);
        // }
        else {
          errors[name] = validate(name, value, prevData);
        }
        return { ...prevData, errors };
      })
  };

  // function handleFocusOldPass() {
  //   errors.password = "";
  // }
  function handleFocusNewPass() {
    errors.newPassword = "";
  }
  function handleFocusCNewPass() {
    errors.confirmPassword = "";
  }

  const passwordFormat = `Your password must be atleast 10 characters long containing atleast one uppercase letter,
    one lowercase letter, one special character and one digit`;

  const popoverr = () => (
    <Popover id="popover-trigger-hover-focus" title="right">
      <h3
        className="password-popover-header"
        style={{ fontSize: "14px", textAlign: "center", marginTop: "1px" }}
      >
        {passwordFormat}
        {/* {passwordFormat.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))} */}
      </h3>
    </Popover>
  );

  const handleResend = () => {
    userService.forgotPassword({ email }).then((response) => {
      console.log("response==", response);
      if (response.data.status === 0) {
        setIsResend(false);
        setTimer(120);
      }
    });
  }

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          setIsResend(true);
          countdown && clearInterval(countdown)
        }
        return prevTimer - 1
      });
    }, 1000);
    return () => countdown && clearInterval(countdown);
  }, [timer === 120]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <div className="form-body">
      {!isResend ? <form className="row g-3">
        <div className="col-md-12 password-content">
          <div className>
            <label className="form-label">Please enter the OTP sent to your Email</label>
            <div className="input-group" >
              <input
                name="code"
                className="form-control border-end"
                id="inputEmail"
                placeholder="Enter OTP"
                value={data.code}
                onChange={handleChange}
                type="number"
              />
            </div>
          </div>
          &nbsp;
          <div style={{ marginLeft: '60%' }}>OTP Expires In: {formatTime(timer)}</div>
          <div style={{ width: "108%" }}>
            <label className="form-label"> New Password</label>
            <div className="input-group">
              {" "}
              <span className="input-group-text bg-transparent">
                <i className="bx bxs-lock-open"></i>
              </span>
              <input
                className="form-control border-end-0"
                id="inputNewPassword"
                placeholder="New Password"
                name="newPassword"
                value={data.newPassword}
                onFocus={handleFocusNewPass}
                onChange={handleChange}
                type={
                  newValues.showNewPassword
                    ? "text"
                    : "password"
                }
              />
              <a
                onClick={handleClickShowNewPassword}
                tabindex="-1"
                href="javascript:;"
                className="input-group-text bg-transparent"
              >
                {newValues.showNewPassword ? (
                  <i className="bx bx-show"></i>
                ) : (
                  <i className="bx bx-hide"></i>
                )}
              </a>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="bottom"
                overlay={popoverr()}
                rootClose
              >
                <button
                 type="button"
                 className="mb-0 ms-3"
                 style={{
                    border: "none",
                    backgroundColor: "white",
                    marginTop: "1px",
                  }}
                >
                  <i className="fadeIn animated bx bx-info-circle text-primary-blue"></i>
                </button>
              </OverlayTrigger>
            </div>
            <p
              className="my-1 mb-0 lh-1 font-12"
              style={{ height: "18px" }}
            >
              {data?.errors?.newPassword && (
                <span style={{ color: "red" }}>
                  {data?.errors?.newPassword}
                </span>
              )}
            </p>
          </div>

          <div className="">
            <label className="form-label">
              {" "}
              Confirm Password
            </label>
            <div className="input-group">
              {" "}
              <span className="input-group-text bg-transparent">
                <i className="bx bxs-lock"></i>
              </span>
              <input
                className="form-control border-end-0"
                id="inputconfirmPassword"
                placeholder="Confirm Password"
                name="confirmPassword"
                value={data.confirmPassword}
                onFocus={handleFocusCNewPass}
                onChange={handleChange}
                type={
                  confValues.showConfPassword
                    ? "text"
                    : "password"
                }
              />
              <a
                onClick={handleClickShowConfPassword}
                tabindex="-1"
                href="javascript:;"
                className="input-group-text bg-transparent"
              >
                {confValues.showConfPassword ? (
                  <i className="bx bx-show"></i>
                ) : (
                  <i className="bx bx-hide"></i>
                )}
              </a>
            </div>
            <p
              className="my-1 mb-0 lh-1 font-12"
              style={{ height: "18px" }}
            >
              {data?.errors?.confirmPassword && (
                <span style={{ color: "red" }}>
                  {data?.errors?.confirmPassword}
                </span>
              )}
            </p>
          </div>
        </div>
        <div className="d-grid gap-2 mt-0">
          <button
            type="button"
            onClick={handleSubmit}
            className="btn btn-lg f-send-btn"
            disabled={data.code && data.newPassword.length>=10 && data.confirmPassword.length>=10 ? false:true }
          >
            <>Submit</>
          </button>
          <a href="/login" className="btn btn-light btn-lg">
            <i className="bx bx-arrow-back me-1" />
            <Link to="./login">Back to Login</Link>
          </a>
        </div>
      </form> :
        <div className="d-grid gap-2 mt-0">
          <button
            type="button"
            onClick={handleResend}
            className="btn btn-lg f-send-btn"
          >
            <>Resend OTP</>
          </button>
          <a href="/login" className="btn btn-light btn-lg">
            <i className="bx bx-arrow-back me-1" />
            <Link to="./login">Back to Login</Link>
          </a>
        </div>
      }
    </div>
  );
}
