import React, { Component } from 'react';
import { Card, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import "./Seller.css";
import dashboardService from "../../../Services/dashboard.service"
import Loading from "../../../Commons/Loading"
import { useHistory } from "react-router-dom";
import NodataFound from '../../../Commons/NoDataFound';


export default function SellerDistribution(props) {
  let grapData = props.data.data || [];
  let scroll = props.scroll;

  const history = useHistory()

  console.log(props?.widgetData)
  console.log(props.wgt_data.wgt_name);

  const seriesVal = [];
  const categoriesVal = [];
  let barColor = [];
  let rdata = JSON.parse(localStorage.getItem("ROUTING_PERMISSIONS"))

  const callDistributionColor = (val) => {
    if (val?.split('-')[0] == 0) {
      barColor.push("#e62e2e")
    }
    else if (val?.split('-')[0] == 90.01) {
      barColor.push("#29cc39")
    }
    else {
      barColor.push("#ffc107")
    }
  }
  // console.log("NNNNNNNNNNNNNNN", { grapData, props })
  grapData && grapData.map((data) => {
    seriesVal.push(data.result);
    categoriesVal.push(data.xvalue);
    props.wgt_data.wgt_code === 'CALLER_DISTRIBUTION' && callDistributionColor(data.xvalue)
    props.wgt_data.wgt_code === 'CALL_DURATION_DISTRIBUTION' && barColor.push("#0077B6")
  })

  console.log(barColor)
  console.log("DEFFILTERDATA", props.defaultFilteredData)
  // barColor = barColor != null?["#29cc39", "#ffc107", "#e62e2e"] : ["#0077B6"];
  const [type, setTpye] = useState("bar");

const sendTocalls = (data, index) => {
    console.log(data, index, data[index]);
    let storedData = JSON.parse(localStorage.getItem("AGENT"))
    //let graphdata = categoriesVal?categoriesVal[value] :''
    let key = props.wgt_data.wgt_code === 'CALL_DURATION_DISTRIBUTION' ? 'callDuration' : props.wgt_data.wgt_code === 'CALLER_DISTRIBUTION' ? 'callScore' : data[index]
    localStorage.setItem("AGENT", JSON.stringify(
      {
        "pageSelected": 1,
        "fromD": props.widgetData?.from == undefined ? props.defaultFilteredData?.from : props.widgetData?.from,
        "toD": props.widgetData?.to == undefined ? props.defaultFilteredData?.to : props.widgetData?.to,
        "selectedDate": props.widgetData?.selectedDate == undefined ? props.defaultFilteredData?.selectedDate : props.widgetData?.selectedDate,
        // "selectedRange": storedData?.selectedDate === "This Year" ? "7" : '5',
        "selectedRange": storedData?.selectedRange,
        "selectedCoeRange": storedData?.selectedCoeRange,
        "selectedCOE":storedData?.selectedCOE,
        "coe":storedData?.coe
      }))
    history.push(rdata && rdata.Conversations && Array.isArray(rdata.Conversations) && rdata.Conversations.length > 1 ? `/voice?${key}=${data[index]}&fromD=${props.widgetData?.from == undefined ? props.defaultFilteredData?.from : props.widgetData?.from}&toD=${props.widgetData?.to == undefined ? props.defaultFilteredData?.to : props.widgetData?.to}` : `/sales_conversations?${key}=${data[index]}&fromD=${props.widgetData?.from == undefined ? props.defaultFilteredData?.from : props.widgetData?.from}&toD=${props.widgetData?.to == undefined ? props.defaultFilteredData?.to : props.widgetData?.to}`)
  }

  const series = [
    {
      name: "No. of Calls",
      data: seriesVal ? seriesVal : '',
    },
  ];
  const options = {
    chart: {
      toolbar: {
        show: false,
        /* events: {
          xAxisLabelClick: ()=> console.log('clicked'),

        } */
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          console.log(chartContext, config);
          console.log(config.w.config.series[config.seriesIndex])
          console.log(config.w.config.series[config.seriesIndex].name)
          console.log(config.w.config.series[config.seriesIndex].data[config.dataPointIndex])
          console.log(config.dataPointIndex);
          sendTocalls(config.w.config.xaxis.categories, config.dataPointIndex)
        }
      }
    },
    colors: barColor,
    plotOptions: {
      bar: {
        columnWidth: "45px",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    legend: {
      show: false,
    },
    xaxis: {
      categories:
        props.wgt_data.wgt_name == "Call Duration Distribution" ? categoriesVal :
          props.wgt_data.wgt_name == "Caller Distribution" ? categoriesVal :
            props.wgt_data.wgt_name == "Call Quality" ? categoriesVal : "",
      // position:"bottom",
      labels: {
        style: {
          colors: "#000000",
          fontSize: "12px",
        },
      },
      events: {
        click: function (chart, w, e) {
          console.log(chart, w, e)
          console.log("dfghjkkkkkkkkk");
        },
      },
      group: {
        style: {
          fontSize: '10px',
          fontWeight: 700,
          align: 'center',
          textAlign: "right"
        },
        groups: [
          {
            title: props.wgt_data.wgt_name == "Caller Distribution" ? "Call Score(%)" :
              props.wgt_data.wgt_name == "Call Duration Distribution" ? "Call Duration(sec)" :
                "", cols: seriesVal ? seriesVal.length : ''
          }
        ]
      }
    },
   

    yaxis: {
      title: {
        text: props.wgt_data.wgt_name == "Caller Distribution" ? "No. of Calls" :
          props.wgt_data.wgt_name == "Call Duration Distribution" ? "No. of Calls" :
            props.wgt_data.wgt_name == "Call Quality" ? "Call Percentage(%)" : "",
        style: {
          color: "#666666",
          fontSize: "11px",
          textAlign: "right"
        }
      }
    },

    annotations: {
      points: seriesVal.map((value, index) => {
        if (value !== 0) {
          return {
            x: categoriesVal[index],
            y: value,
            marker: {
              size: 0
            },
            label: {
              borderColor: 'rgba(0, 119, 182, 0.85)',
              offsetY: -7,
              style: {
                color: '#fff',
                background: 'rgba(0, 119, 182, 0.85)',
              },
              text: value
            }
          };
        } else {
          return {
            marker:{
              size:0
            }};
        }
      })
    },

  };
  const [pageNo, setPageNo] = useState(1);

  const popoverr = () => (
    <Popover id="popover-trigger-click-root-close" title="right">
      <h3 className="popover-header" style={{ fontSize: "14px", textAlign: "center" }}>{props.wgt_data.wgt_des}</h3>
    </Popover>
  )

  console.log(grapData)

  return (
    <>
      <Col xs={props.width}>
        <Card className="radius-10 h93 w-100 call-duration-chart-box">
          <Card.Body>
            <div className="d-flex align-items-center">
              <h6 className="mb-0">{props.wgt_data.wgt_name}</h6>
              <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popoverr()} rootClose>
                <button className="mb-0 ms-3" style={{ border: "none", backgroundColor: "white", marginTop: "1px" }}>
                  <i className="fadeIn animated bx bx-info-circle text-primary-blue"></i>
                </button>
              </OverlayTrigger>
            </div>
            <div style={{ width: '100%', height: "300px" }} >
              {!scroll ? ((grapData || []).length > 0 ?
                <ReactApexChart
                  height='100%'
                  type={"bar"}
                  options={options}
                  series={series}
                /> : "") : <div className="loader-container dashboard-loder sellers-loader"><Loading /></div>}
            </div>
            {/* <div style={{ width: '100%', height: "300px" }} >
              {!scroll && (grapData || []).length > 0 ?
                <ReactApexChart
                  height='100%'
                  type={"bar"}
                  options={options}
                  series={series}
                /> : (grapData || []).length === 0 ? "" : <div className="loader-container dashboard-loder sellers-loader"><Loading /></div>}
            </div> */}
          </Card.Body>
        </Card>
      </Col>
      {/* {props.callBackScroll()} */}
    </>
  );
}
