import ReactApexChart from "react-apexcharts";
import { useState,useEffect } from "react"
import * as React from 'react'
import Loading from "../../Commons/Loading";
import NodataFound from "../../Commons/NoDataFound";
import Pagination from "../../Commons/Table/Pagination";
import { PropaneSharp } from "@mui/icons-material";

export default function ComparativeAnalysisGraph(props){
  const [data, setData] = useState([])

    const [type, setTpye] = useState('bar')
console.log(props.analysis,"COMPANALYSIS")
console.log(props.filteredData,"COMPANALYSISFILT")
console.log(props.def,"COMPANALYSISDEF")

let PageSize = 10;
let userDetail = JSON.parse(localStorage.getItem("USER_DETAIL"))?.clientConfig?.data?.comparativeAnalysis
console.log(props,"prop")

let filterData = props?.data?.length>0&&props?.data.filter(d=>{
  return d.audit == parseInt(props.event)
})

console.log(filterData,"filter")
console.log("WWWWWWWWWWWW",props.event)

let notNullData = props?.data?.length>0&&filterData?.filter(item=>{
  return item.v!=null
})
console.log(notNullData,"not")
let analysisData = props?.data?.length>0&&notNullData?.map(item=>{
  return item.v
})

let analysisName = props?.data?.length>0&&userDetail?.filter(item=>{
  return item.value==props.event
})
const names = props?.data?.length>0&&data.map(item=>{
  return  item.agentName

})
console.log(analysisName , 'nnn')
console.log("ANALYSISDATA",analysisData) 
console.log("PROPSDATA",data)
console.log("NamesAN",names)

const pageCount =
analysisData?.length > 0 ? analysisData.length / PageSize : 0;
console.log("PAGECOUNT",pageCount)
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPages, setCurrentPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [canNextPage, setCanNextPage] = useState(pageIndex < pageCount - 1);
const [canPreviousPage, setCanPreviousPage] = useState(pageIndex > 0);
console.log("PROPSDATA",props)



useEffect(() => {
  setData(notNullData?.length > 0 ? notNullData.slice(0, 10) : []);
  setPageIndex(0);
  setCurrentPages(pageCount);
  setCanNextPage(pageIndex < pageCount - 1);
  setCanPreviousPage(pageIndex > 0);
}, [props.data,props.event]);

  const nextPage = () => {
    const nextPageIndex = pageIndex + 1;
    if (nextPageIndex < pageCount) {
      const start = nextPageIndex * PageSize;
      const end = start + PageSize;
      setData(notNullData.slice(start, end));
      setPageIndex(nextPageIndex);
      setCurrentPage(currentPage + 1);
      setCanPreviousPage(true);
      setCanNextPage(nextPageIndex < pageCount - 1);
    }
  };
  
  const previousPage = () => {
    const previousPageIndex = pageIndex - 1;
    if (previousPageIndex >= 0) {
      const start = previousPageIndex * PageSize;
      const end = start + PageSize;
      setData(notNullData.slice(start, end));
      setPageIndex(previousPageIndex);
      setCurrentPage(currentPage - 1);
      setCanNextPage(true);
      setCanPreviousPage(previousPageIndex > 0);
    }
  };
  

  const gotoPage = (val) => {
    let a = val * PageSize;
    setPageIndex(val);
    setData(notNullData.slice(a, a + 10));
  };

  const changePageIndex = (val) => {
    let a = val * PageSize;
    setPageIndex(val);
    setData(notNullData.slice(a, a + 10));
  };

  // let storedData = JSON.parse(localStorage.getItem("AGENT"))?.productivity;
    console.log(props?.data);
   
    const series = [{
        name: analysisName[0]?.displayName,
        data: data.map(x=>x.v)
    }];
console.log("Comparative",series,notNullData,filterData)
    const options= {
        chart: {
          height: 300,
          type: 'line',
          dropShadow: {
            enabled: false,
            color: '#000',
            top: 18,
            left: 7,
            blur: 3,
            opacity: 0.1
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#0077B6'],
        
        markers: {
            size: 4,
            colors: ["#0077B6"],
            strokeColors: "#fff",
            strokeWidth: 2,
            hover: {
                size: 7,
            }
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: '',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        labels: names,
        xaxis:{
          labels:{
            // rotate: -40,
            // rotateAlways: true,
            style:{
              fontSize:'10px',
              fontWeight:'400'
            }
          }
        },
        xaxis: {
          // categories:props?.data.length>0&&notNullData?.map(item=>{return item?.agentName})
          categories:names,
          labels:{
            rotate: -40,
            rotateAlways: true,
          }
        },
        
        yaxis: {
          title: {
            text: analysisName[0]?.displayName,
          },
          min: 0,
          max: 100
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
    }

    console.log("SERIES",props.data)

    return(
      <>
      {
        props.loader?
        <div className="loader-container">
          <Loading variant="dark"/>
        </div>:
        series[0].data && series[0].data.length>0 ?
        (<div className="p-4">
          <ReactApexChart type={'line'} options={options} series={series} height="550"/>
          <Pagination
                            className="pagination-bar mb-1"
                            currentPage={currentPage}
                            totalCount={analysisData.length}
                            pageSize={PageSize}
                            pageIndex={pageIndex}
                            currentPages={currentPages}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            gotoPage={gotoPage}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            handlePageIndex={changePageIndex}
                          />
        </div>):
        <NodataFound/>
      }
      </>
    )
  }